import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-history',
  templateUrl: './no-history.component.html'
})
export class NoHistoryComponent {

  @Input() milestoneTitle: string = '';

}
