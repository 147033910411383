import { Component } from '@angular/core';
import { BaseLoaderComponent } from '../base-loader.component';

@Component({
  selector: 'app-to-do-skeleton',
  templateUrl: './to-do-skeleton.component.html'
})
export class ToDoSkeletonComponent extends BaseLoaderComponent {

  constructor() { super(); }

}
