<main>
  <article>
    <div class="sprk-o-CenteredColumn pdf background-color--sprk-gray-alternative">
      <pdf-viewer [src]="src"
                  [(page)]="pagesRead"
                  [render-text]="true"
                  [fit-to-page]="true"
                  [original-size]="false"
                  [zoom]="1"
                  (after-load-complete)="onPdfLoad($event)"></pdf-viewer>
    </div>
  </article>
</main>