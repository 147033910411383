import { Injectable } from '@angular/core';
import { MilestoneQueueActionMessage } from '@core/services/milestone/milestone-queue-action-message';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { Contact } from '@models/contact';
import { LoanData } from '@models/loan-data';
import { Milestone } from '@models/milestone';
import { IProcessor } from '@models/processor/processor.interface';
import { IQueueProcessor } from '@models/processor/queue-processor.interface';
import { Property } from '@models/property';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { find, isEqual } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';
import { MilestoneAction } from './milestone-action';
import { MilestoneHelperService } from './milestone-helper.service';
import { MilestoneService } from './milestone.service';

@Injectable()
export class MilestoneProcessorService implements IProcessor<AllowedMilestoneAction>, IQueueProcessor<number, MilestoneActions> {
  private readonly _queue: Map<number, MilestoneQueueActionMessage>;
  public isDefaultMessage: boolean;

  constructor(
    private readonly _orderViewService: OrderViewService,
    private readonly _milestoneService: MilestoneService,
    private readonly _toasterService: ToastrService,
    private readonly _customEventSvc: CustomEventsService,
    private readonly _milestoneHelperService: MilestoneHelperService) {
    this._queue = new Map<number, MilestoneQueueActionMessage>();
  }

  public get queueSize(): number {
    return this._queue.size;
  }

  public get queueMessages(): Map<number, MilestoneQueueActionMessage> {
    return this._queue;
  }

  public process(item: MilestoneAction): AllowedMilestoneAction | Observable<any> {
    this.sendMilestoneInfoToAdobeAnalytics(item);
    return this.refreshMilestones$(item.execute(), item.milestone);
  }

  public processQueue(): MilestoneActions {
    const submitFunctions = new Array<AllowedMilestoneAction>();

    if (!this._queue.size) return of([]);

    this._queue.forEach(actionMessage => {
      submitFunctions.push(this.executeMessageWithRetry(actionMessage.execute(), actionMessage.milestone));
    });

    return forkJoin(submitFunctions).pipe(
      map(_ => this._queue.clear()),
      catchError(error => {
        return of(error);
      }));
  }

  public enqueue(item: NonNullable<MilestoneQueueActionMessage>): void {
    this._queue.set(item.milestone.milestoneId, item);
  }

  public dequeue(key: number): boolean {
    return this._queue.delete(key);
  }

  public clear(): void {
    this._queue.clear();
  }

  private executeMessageWithRetry(observable$: NonNullable<Observable<any>>, milestone: Milestone): Observable<any> {
    return observable$.pipe(
      take(1),
      this.retryPipeline(),
      catchError(() => {
        this._toasterService.error('Unable to update', this._milestoneHelperService.getDocumentNameFromMilestoneTitle(milestone));
        return of(undefined);
      }));
  }

  private refreshMilestones$(observable$: NonNullable<Observable<any>>, milestone: Milestone): Observable<any> {
    return observable$.pipe(
      switchMap(() => this._orderViewService.orderId$),
      switchMap(orderId => this._milestoneService.getMilestones$(orderId)),
      map(milestones => {
        if (milestone.milestoneStatusId === MilestoneStatus.New && !this.hasMilestoneBeenUpdated(milestone, milestones)) {
          throw milestone;
        }

        this._toasterService.success('Successfully updated', this._milestoneHelperService.getDocumentNameFromMilestoneTitle(milestone));
        this._orderViewService.updateMilestones(milestones);
      }),
      this.retryPipeline(),
      catchError(error => {
        return of(error)
      })
    );
  }

  private retryPipeline() {
    return retryWhen(errors => errors.pipe(
      concatMap((e, i) =>
        iif(
          () => {
            return i === 2;
          },
          throwError(e),
          of(e).pipe(delay(200))
        )
      )));
  }

  private hasMilestoneBeenUpdated(targetMilestone: Milestone, updatedMilestones: Array<Milestone>): boolean {
    const milestone = find(updatedMilestones, { milestoneId: targetMilestone.milestoneId });

    return !isEqual(targetMilestone.milestoneStatusId, milestone.milestoneStatusId);
  }

  private sendMilestoneInfoToAdobeAnalytics(item: MilestoneAction): void {
    this._orderViewService.order$.subscribe(x => {
      this._customEventSvc.pushDataToDoMilestoneUpdateEvent({
        order_id: x.orderId,
        agency_id: x.agencyId,
        todo_name: this._milestoneHelperService.getDocumentNameFromMilestoneTitle(item.milestone),
        todo_status: item.milestone.milestoneStatusId,
        order_status: x.orderStatusId
      });
    })
  }
}

export type AllowedMilestoneAction = Observable<Contact[]> | Observable<LoanData> | Observable<Property> | Observable<Milestone>;
export type MilestoneActions = Observable<(Contact[] | LoanData | Property)[]>
