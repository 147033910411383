import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentMetaData } from '@models/document-meta-data';

@Component({
  selector: 'app-document-dropdown',
  templateUrl: './document-dropdown.component.html'
})
export class DocumentDropdownComponent implements OnInit {
  @Input() documents: Array<DocumentMetaData>;
  @Input() documentId: number;
  @Output() documentIdChange = new EventEmitter<number>();

  public selectedDocumentId: number;

  public ngOnInit() {
    this.selectedDocumentId = this.documentId;   
  }  
  
  public selectChangeHandler (id: number): void {    
    this.documentIdChange.emit(id);
  }
}
