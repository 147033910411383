<table class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray">
  <thead sprkTableHead>
    <tr>
      <th id="file-name"
          style="min-width: 25%;">File Name</th>
      <th class="text-center"
          id="upload-progress"
          style="width: 20%;">Upload Progress</th>
      <th id="action"></th>
      <th id="selection">Select what documents are contained in this file...</th>
      <th id="description" class="text-right">Notes</th>
    </tr>
  </thead>
  <tbody *ngFor="let fileUploadRequest of this._documentUploaderService.fileUploadRequests$ | async">
    <tr>
      <td>
        {{ fileUploadRequest?.file?.name }}
      </td>
      <td>
        <div *ngIf="!fileUploadRequest.isQueued && !fileUploadRequest.hasSuccessfulResponseCode">
          <div class="progress slide">
            <div class="progress__bar"
                [class.progress__bar--failed]="fileUploadRequest.hasUploadFailed"
                [style.width.%]="fileUploadRequest?.uploadPercentage">
              <div class="progress__percent">{{ fileUploadRequest?.uploadPercentage }}%</div>
            </div>
          </div>
          <p class="py-2 text-center">
            {{ fileUploadRequest?.bytesUploaded || 0 | filesize }}/{{ fileUploadRequest?.totalUploadSize || 0 | filesize }}
          </p>
        </div>

        <div class="progress-card__status-information progress-card__status-information--default text-center">
          <span *ngIf="fileUploadRequest.isQueued">Queued</span>
          <span *ngIf="fileUploadRequest.hasUploadFailed">Upload Failed</span>
          <span *ngIf="fileUploadRequest.hasSuccessfulResponseCode"
                class="slide--right stroke-sprk-purple">
            <fa-icon [icon]="faCheck" class="color--sprk-purple"></fa-icon>
          </span>
        </div>
      </td>
      <td class="text-center">
        <fa-icon *ngIf="fileUploadRequest.isQueued"
                 [icon]="faTrash"
                 class="drop-zone__icon pr-3 cursor-pointer"
                 (click)="onRemoveClick(fileUploadRequest)"></fa-icon>
      </td>
      <td>
        <div class="align-self-center d-flex align-items-start flex-column col px-0">
          <div class="d-flex align-items-center" sprkHeading variant="displayFive" id="loan-number">
            <angular2-multiselect [data]="milestones" [ngModel]="fileUploadRequest.milestones"
              [settings]="dropdownSettings" (ngModelChange)="onMultipleOptionsSelected(fileUploadRequest, $event)">
            </angular2-multiselect>
          </div>
        </div>
      </td>
      <td class="text-right">
        <label class="sprk-b-Link" role="button" data-toggle="tooltip" data-placement="right" title="Tooltip on right"
          placement="auto" (click)="fileUploadRequest.isCollapsed = !fileUploadRequest?.isCollapsed">
          + Add
        </label>
      </td>
    </tr>
    <tr id="RowX" *ngIf="!fileUploadRequest?.isCollapsed" colspan="5">
      <td colspan="5" class="background-color--off-white overflow-hidden align-self-center">
        <div class="sprk-c-Card sprk-o-Stack slide p-4 background-color--white w-100" style="max-width: none;">
          <sprk-table idString="table-2" additionalTableClasses="sprk-b-Table--secondary sprk-b-Table--white-head">
              <thead sprkTableHead>
                <tr>
                  <th id="th_description">Description (Optional)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <div class="sprk-b-InputContainer">
                    <input [(ngModel)]="fileUploadRequest.documentDescription"
                           class="sprk-b-TextInput sprk-u-Width-50"
                           id="text-input"
                           data-id="text-input"
                           maxlength="50"
                           type="text">
                    <div class="sprk-b-ErrorContainer" id="text-input--error-container">
                      <div class="sprk-b-ErrorText">
                        Number of Characters: {{+fileUploadRequest.documentDescription?.length}}/50
                      </div>
                    </div>
                  </div>
                </tr>
              </tbody>
            </sprk-table>
          </div>
        </td>
      </tr>
  </tbody>
</table>

<div class="d-flex justify-content-center align-items-center my-4">
  <button sprkButton
          (click)="onUploadClick()"
          [disabled]="this._documentUploaderService.isUploading || !this._documentUploaderService.canUploadFiles">
    Send Documents
  </button>
</div>