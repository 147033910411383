<div class="sprk-o-Stack">
    <header class="sprk-o-Stack__item sprk-c-Modal__header mb-3">
        <h2 class="sprk-c-Modal__heading sprk-b-TypeDisplayFour text-center" id="modalChoiceHeading">
            Confirm Address
        </h2>

        <button (click)="hideModel('cancel')" class="sprk-c-Modal__icon" data-sprk-modal-cancel="exampleChoiceModal" type="button"
            aria-label="Close Modal">
            <svg class="sprk-c-Icon" viewBox="0 0 64 64" aria-hidden="true" focusable="false">
                <use xlink:href="#close"></use>
            </svg>
        </button>
    </header>
    
    <div class="sprk-o-Stack__item sprk-c-Modal__body">
        <div *ngIf="!noAddressFound" class="row">
            <label class="col-12" style="font-size: x-large;">
                Would you like to select the recommended address?
            </label>
        </div>
        <div *ngIf="!noAddressFound" (click)="selectAddress('recommended')" class="card mt-3 mb-3 border-white shadow">
            <div class="card-body">
                <h2 class="sprk-c-Modal__heading sprk-b-TypeDisplayFour mb-3">
                    Recommended Address
                </h2>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{address?.addressLines[0]}}
                    </label>
                </div>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{address?.addressLines[1]}}
                    </label>
                </div>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{address?.locality}} {{address?.stateCode}} {{address?.postCode}}
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="noAddressFound" class="row">
            <label class="col-12" style="font-size: x-large;">
                No Verified Address found. Would you still like to use this Address?
            </label>
        </div>
        <div class="card mt-3 mb-3 border-white shadow" (click)="selectAddress('provided')">
            <div class="card-body">
                <h2 class="sprk-c-Modal__heading sprk-b-TypeDisplayFour mb-3">
                    Provided Address
                </h2>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{providedAddress?.addressLines[0]}}
                    </label>
                </div>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{providedAddress?.addressLines[1]}}
                    </label>
                </div>
                <div class="row">
                    <label class="col-12" style="font-size: large;">
                        {{providedAddress?.locality}} {{providedAddress?.stateCode}} {{providedAddress?.postCode}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <label *ngIf="!noAddressFound" class="col-12" style="font-size:large;">Select <strong>Confirm</strong> to use recommended Address, <strong>Ignore</strong> to use the provided address</label>
            <label *ngIf="noAddressFound" class="col-12" style="font-size:large;">Select <strong>Ignore</strong> to still use the provided address</label>
        </div>
    </div>

    <footer class="sprk-o-Stack__item mb-3 mt-3 ml-auto">
        <button *ngIf="!noAddressFound" (click)="hideModel('confirm')" class="sprk-c-Button sprk-u-mrm">
            Confirm
        </button>
        <button (click)="hideModel('ignore')" class="sprk-c-Button sprk-c-Button--secondary sprk-u-mrm">
            Ignore
        </button>
        <button (click)="hideModel('cancel')" class="sprk-c-Button sprk-c-Button--tertiary"
            data-sprk-modal-cancel="exampleChoiceModal">
            Cancel
        </button>
    </footer>
</div>