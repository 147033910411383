import { Component, Input } from '@angular/core';
import { SprkSkeletonBaseComponent } from '../sprk-skeleton-base.component';

@Component({
  selector: 'sprk-table-skeleton',
  templateUrl: './sprk-table-skeleton.component.html',
  styleUrls: ['./sprk-table-skeleton.component.scss']
})
export class SprkTableSkeletonComponent extends SprkSkeletonBaseComponent {

  @Input() columns: number = 1;
  @Input() rows: number = 1;

  constructor() { super(); }

  get Columns(): number[] {
    const columnArray = [];
    for (let i = 0; i < this.columns; i++) {
      columnArray.push(i);
    }
    return columnArray;
  }

  get Rows(): number[] {
    const rowArray = [];
    for (let i = 0; i < this.rows; i++) {
      rowArray.push(i);
    }
    return rowArray;
  }
}