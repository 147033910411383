<ng-container *ngIf="milestones">
  <ng-container *ngFor="let milestone of milestones; let i = index; first as isFirst;">
    <div  [ngClass]="{ 'my-4': !isFirst }"
          id="milestone-definition-{{milestone.milestoneDefinitionId}}">
      <app-data-milestone
        [milestone]="milestone"
        [canQueueMilestone]="true">
      </app-data-milestone>
    </div>
  </ng-container>
</ng-container>
