import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  private readonly _retryAmount = 1;
  private readonly _timeoutAmount = 3500;

  constructor(private readonly _httpClient: HttpClient) { }

  public sendHttpRequestWithRetryStrategy$<TValue>(method: string, url: string): Observable<TValue> {
    return this._httpClient
      .request<TValue>(method, url)
      .pipe(
        first(),
        retry(this._retryAmount)
      );
  }

  public createVersionHeader(version: number): any {
    return { 'api-version': version.toString() };
  }

  public sendHttpOptionRequestWithRetryStrategy$<TValue>(method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }): Observable<TValue> {
    return this._httpClient.request<TValue>(method, url, options)
      .pipe(
        first(),
        retry(this._retryAmount));
  }
}
