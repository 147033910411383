import { Component, OnInit } from '@angular/core';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html'
})
export class HelpPageComponent implements OnInit {
  public userguidesrc: string;
  public isRendered = false;

  constructor(private readonly _customEventService: CustomEventsService){ }

  ngOnInit() {
    this.userguidesrc = `${window.location.origin}/assets/files/UserGuide/ClosingPortalUserGuide.pdf`;
    this._customEventService.pushPageLoadCustomEvent({
      page_name: 'help-page'
    })
  }

  public onPdfLoad(pdf: PDFDocumentProxy): void {
    this.isRendered = true;
  }
}
