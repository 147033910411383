import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponentV2 } from '@core/components/footer/footer.v2.component';
import { HeaderComponentV2 } from '@core/components/header/header.v2.component';
import { CoreModule } from '@core/core.modules';
import { RocketDesignSystemModule } from '@rocketcentral/rocket-design-system-angular';
import { SharedModule } from '@shared/shared.module';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HeaderComponentV2,
    FooterComponentV2,
    BrowserModule,
    CommonModule,
    SparkAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    RocketDesignSystemModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
