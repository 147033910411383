<section>
  <header *ngIf="showLoanNumber">
    <div class="mb-4 text-center my-3">
      Loan# <sprk-skeleton-bone [theme]="settings.themes.default"></sprk-skeleton-bone>
    </div>
  </header>
  <div class="row pt-3 border-top">
    <div class="col pt-2 borrower-title">
      <label class="sprk-b-Label sprk-u-FontWeight--bold">Borrower(s):</label>
      <sprk-skeleton-bone [animation]="settings.animation"
                          [theme]="settings.themes.default"
                          count="3"></sprk-skeleton-bone>
    </div>
  </div>
  <ng-container>
    <div class="print-col-6">
      <div class="row pt-3 border-top">
        <div class="col pt-2">
          <label class="sprk-b-Label sprk-u-FontWeight--bold">Property Address:</label>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col">
          <sprk-skeleton-bone [animation]="settings.animation"
                              [theme]="settings.themes.default"
                              count="3"></sprk-skeleton-bone>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Property Type:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <ng-container>
      <div class="row">
        <div class="col pt-2">
          <label class="sprk-b-Label sprk-u-FontWeight--bold">Occupancy:</label>
        </div>
        <div class="col">
          <sprk-skeleton-bone [animation]="settings.animation"
                              [theme]="settings.themes.default"></sprk-skeleton-bone>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container>
    <div class="row pt-3 border-top">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Signing Information:</label>
      </div>
    </div>
    <div class="print-col-lg-6">
      <div class="row pt-2 pb-3 signing-date">
        <div class="col">
          <label class="sprk-b-Label sprk-u-FontWeight--bold">Date:</label>
        </div>
        <div class="col">
          <sprk-skeleton-bone [animation]="settings.animation"
                              [theme]="settings.themes.default"></sprk-skeleton-bone>
        </div>
      </div>
    </div>
    <ng-template #scheduledClosingDate>
      <div class="row pt-2 pb-3 print-col-lg-6">
        <div class="col signing-date">
          <label class="sprk-b-Label sprk-u-FontWeight--bold">Date:</label>
        </div>
        <div class="col">
          <sprk-skeleton-bone [animation]="settings.animation"
                              [theme]="settings.themes.default"></sprk-skeleton-bone>
        </div>
      </div>
    </ng-template>

    <div class="row print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Rate Lock Expiration Date:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>

    <div class="row pt-3 border-top print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Transaction Type:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Loan Product:</label>
      </div>
      <div class="col2">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row print-col-lg-6"
         *ngIf="false">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Funding Number:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row  print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Loan Type:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Loan Amount:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row print-col-lg-6">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Endorsements:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Rider Page Count:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Mortgage Page Count:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col pt-2">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">Legal Description Page Count:</label>
      </div>
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </div>
    </div>
  </ng-container>

  <div class="row pt-3 border-top print-col-lg-6">
    <div class="col pb-3">
      <label class="sprk-b-Label sprk-u-FontWeight--bold">Mortgagee Clause:</label>
      <p>
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"
                            count="3"></sprk-skeleton-bone>
      </p>
    </div>
  </div>

  <ng-container>
    <div class="row pt-3 border-top">
      <div class="col-12">
        <label class="sprk-b-Label sprk-u-FontWeight--bold">
          Contact
        </label>
      </div>
    </div>

    <div>
      <div class="row pb-3">
        <div class="col-12">
          <sprk-skeleton-bone [animation]="settings.animation"
                              [theme]="settings.themes.default"
                              count="4"></sprk-skeleton-bone>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.largeButton">
        </sprk-skeleton-bone>
      </div>
    </div>
  </ng-container>
</section>