import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-top',
  templateUrl: './to-top.component.html'
})
export class ToTopComponent implements OnInit, OnDestroy {
  @Input()
  public additionalClasses: string = '';

  public showButton: boolean = false;

  ngOnInit(): void {
    this.onWindowScrolled();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScrolled);
  }

  @HostListener('window:scroll', [])
  private onWindowScrolled(): void {
    this.showButton = (window.scrollY > 0);
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0 });
  }
}