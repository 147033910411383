import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentDownloadService } from '@core/services/documents/document-download.service';
import { DocumentsService } from '@core/services/documents/documents.service'; ``
import { DocMetaData } from '@models/doc-metadata';
import { DocumentMetaData } from '@models/document-meta-data';
import { DocumentStatuses } from '@models/document-statues';
import { Milestone } from '@models/milestone';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { ISkeletonSettings, SparkSkeletonSettings } from '@shared/modules/sprk-skeleton/models/skeleton-settings';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-document-history-v2',
  templateUrl: './document-history-v2.component.html'
})
export class DocumentHistoryV2Component extends OnDestroySubscriptionResolver implements OnInit {
  @Input() milestone: Milestone = new Milestone();

  downloadInProgress: boolean;
  documentHistory: Array<DocumentMetaData>;
  documentStatuses = DocumentStatuses;
  skeletonSettings: ISkeletonSettings = new SparkSkeletonSettings();
  historyLoaded: boolean = false;

  constructor(
    public readonly documentsService: DocumentsService,
    private readonly _orderViewService: OrderViewService,
    private readonly _documentDownloadService: DocumentDownloadService) {
    super();
  }

  ngOnInit(): void {
    this._orderViewService.orderId$
      .pipe(
        switchMap(orderId => {
          return this.documentsService.getDocumentsMetaData$(orderId, this.milestone.milestoneDefinitionId, this.milestone.referenceId)
        }),
        catchError((err: HttpResponse<Error>) => {
          this.historyLoaded = true;
          return [];
        })
      )
      .subscribe(history => {
        this.documentHistory = history;
        this._unsubscribe$$.next();
        this.historyLoaded = true;
      });
  }

  public downloadDocument(document: DocMetaData): void {
    this.downloadInProgress = true;
    this._orderViewService.orderId$
      .pipe(
        takeUntil(this._unsubscribe$$),
        switchMap(orderId => {
          return this._documentDownloadService.downloadDocumentV2$(orderId, document.documentId, document.documentName, this._unsubscribe$$, document.s3ObjectKey);
        }),
        takeUntil(this._unsubscribe$$),
        map(_ => {
          this.downloadInProgress = false;
          this._unsubscribe$$.next();
        }),
        catchError(_ => {
          this.downloadInProgress = false;
          return of(null);
        })
      ).subscribe();
  }
}
