import { EnvironmentTypes } from './environment-types.enum';

export class PortalEnvironment {
  public readonly catchApiUrl: string;
  constructor(environmentType: EnvironmentTypes) {
    if (environmentType === EnvironmentTypes.prod) {
      this.catchApiUrl = 'https://eks-catchuiserviceapi.closing.foc.zone/api';
    } 
    else {
      this.catchApiUrl = `https://eks-catchuiserviceapi.${environmentType}.closing-np.foc.zone/api`;
    }
  }
}
