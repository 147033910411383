import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@models/contact';

@Pipe({
  name: 'contactFullName'
})
export class ContactFullNamePipe implements PipeTransform {

  transform(value: Contact, ...args: unknown[]): unknown {
    return `${value.clientName} `;
  }
}