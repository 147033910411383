import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccount, IVisitor, PendoMetaData } from '../models/pendo-meta-data';

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  private _isInitialized: boolean = false;
  private _isEnabled$: Observable<boolean> = null;
  private _pendo: any = null;
  private _visitor: IVisitor = null;
  private _account: IAccount = null;
  private _validationMessages: string[] = [];
  private _apiKey: string = 'b9c73bba-0368-49f3-62d8-f8137e88cbfc';
  private _pendoScriptId: string = 'pendo-script';

  private get isValidRequest(): boolean {
    if (!this._pendo) this._validationMessages.push('Missing pendo reference script.');
    if (!this._visitor?.id) this._validationMessages.push('Missing visitor ID.');
    if (!this._account?.id) this._validationMessages.push('Missing account ID.');

    return this._validationMessages.length === 0;
  }

  public initialize(visitor: IVisitor, account: IAccount, enableObservable$: Observable<boolean>): void {
    this._visitor = visitor;
    this._account = account;
    this._isEnabled$ = enableObservable$;

    this._isEnabled$
      .pipe(
        map(enabled => {
          if (enabled && !this._isInitialized) {
            this.finishInitializing();
          }

          if (!enabled && this._isInitialized) {
            this.destroy();
          }
        })
      ).subscribe();
  }

  public changeAccount(account: IAccount): void {
    this._account = account;
    if (this._isInitialized) {
      if (!this.isValidRequest)
        this.throwError();

      const pendoMetaData: PendoMetaData = new PendoMetaData();
      pendoMetaData.account = account;
      pendoMetaData.visitor = this._visitor;
      this._pendo.identify(pendoMetaData);
    }
  }

  public clearSession(): void {
    if (this._isInitialized)
      this._pendo?.clearSession();
  }

  private addScript(): void {
    if (document.getElementById(this._pendoScriptId)) return;

    const scriptText = `(function (apiKey) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
          o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        })(v[w]);
        y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('${this._apiKey}');`;
    const script = document.createElement('script');
    script.id = this._pendoScriptId;
    script.innerText = scriptText;

    document.getElementsByTagName('head')[0].appendChild(script);
    this._pendo = window['pendo'];
  }

  private finishInitializing(): void {
    this.addScript();

    if (!this.isValidRequest)
      this.throwError();

    const pendoMetaData: PendoMetaData = new PendoMetaData();
    pendoMetaData.visitor = this._visitor;
    pendoMetaData.account = this._account;

    if (!this._isInitialized && this._pendo) {
      this._pendo.initialize(pendoMetaData);
      this._isInitialized = true;
    }
  }

  private throwError(): void {
    throw new Error(this._validationMessages.join(' '));
  }

  private destroy(): void {
    this._pendo = null;
    window['pendo'] = null;
    this._isInitialized = false;
    document.getElementsByTagName('head')[0].removeChild(document.getElementById('pendo-script'));
  }
}