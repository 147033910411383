<div class="d-flex align-items-center">
  <fa-icon
    class="mr-1"
    [icon]="faCircle"
    [ngClass]="{
      'color--sprk-green': milestoneStatusId === milestoneStatus.Complete,
      'color--sprk-yellow': milestoneStatusId == milestoneStatus.Pending,
      'color--sprk-red': milestoneStatusId == milestoneStatus.New
    }"
  ></fa-icon>
  <ng-container *ngIf="canShowTitle">
    <span class="pl-2">
      <ng-template [ngIf]="milestoneTitle" [ngIfElse]="fallback">
        {{ milestoneTitle }}
      </ng-template>
      <ng-template #fallback>
        {{ milestoneStatusMapper.get(milestoneStatusId) }}
      </ng-template>
    </span>
  </ng-container>
</div>
