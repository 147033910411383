import { Component, ViewEncapsulation } from '@angular/core';
import { RktMastheadModule } from '@rocketcentral/rocket-design-system-angular';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'catch-header',
  templateUrl: './header.v2.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
  styleUrls: ['./header.v2.component.scss'],
  imports: [CommonModule, RktMastheadModule],
  standalone: true,
})
export class HeaderComponentV2 extends HeaderComponent {
  isOpen = false;

  public readonly myAccount = {
    text: 'My Account',
    value: 'my-account'
  }

  public readonly signOut = {
    text: 'Sign Out',
    value: 'sign-out'
  }

  public readonly accountSelection = [this.myAccount, this.signOut];
}
