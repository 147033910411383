<ng-container *ngIf="ordersLoaded; else orderListSkeleton">
  <table mat-table [dataSource]="orders?.orderDetails" class="rkt-Table">
    <ng-container matColumnDef="subscribers">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Subscribers</th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        <catch-subscribe
          [order]="order"
          [userProfile]="userProfile"
          [subscriptions]="order.subscriptions"
          [showCount]="true"
        ></catch-subscribe>
      </td>
    </ng-container>

    <ng-container matColumnDef="loanNumber">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Loan Number</th>
      <td
        class="rkt-Table__cell"
        mat-cell
        *matCellDef="let order"
        [innerHtml]="order | highlightV2: order.highlight:orderLoanNumberPipe"
      ></td>
    </ng-container>

    <ng-container matColumnDef="borrowers">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Borrowers</th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        <div *ngFor="let contact of order.contacts | clientContactV2 | slice: 0:4">
          <span [innerHtml]="contact | highlightV2: order.highlight:contactNamePipe"></span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="propertyAddress">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Property Address</th>
      <td
        class="rkt-Table__cell"
        mat-cell
        *matCellDef="let order"
        [innerHtml]="order.propertyInfo | highlightV2: order.highlight:addressPipe"
      ></td>
    </ng-container>

    <ng-container matColumnDef="closingType">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Closing Type</th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        {{ order.closingType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="targetClosing">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">{{ closingColumnText }}</th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        <span *ngIf="order.loanData?.scheduledClosingDate == null; else scheduledClosingDate">{{
          order.loanData?.anticipatedClosingDate | date
        }}</span>
        <ng-template #scheduledClosingDate>{{
          order.loanData?.scheduledClosingDate | date
        }}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="orderStatus">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Loan Status</th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        {{ order.orderStatusId | orderStatusV2 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header"></th>
      <td class="rkt-Table__cell" mat-cell *matCellDef="let order">
        <div class="d-flex">
          <div class="col">
            <button
              class="rkt-Button rkt-Button--large mx-2 my-2"
              mat-flat-button
              color="primary"
              (click)="acceptClick.emit(order)"
            >
              Accept
            </button>
            <button
              class="rkt-Button rkt-Button--secondary rkt-Button--large"
              mat-stroked-button
              color="accent"
              (click)="declineClick.emit(order)"
            >
              Decline
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns" (click)="rowClick.emit(row)"></tr>
  </table>

  <mat-paginator
    class="rkt-Pagination d-flex justify-content-center"
    [length]="orders?.totalOrderRecords"
    color="accent"
    [pageSize]="10"
    [hidePageSize]="true"
    [pageIndex]="currentPage - 1"
    (page)="handlePageEvent($event)"
    aria-label="Select page number"
  ></mat-paginator>
</ng-container>

<ng-template #orderListSkeleton>
  <sprk-table-skeleton
    [rows]="5"
    [columns]="skeletonColumns"
    [appearance]="'line'"
    [theme]="skeletonSettings.themes.default"
  ></sprk-table-skeleton>
</ng-template>
