import { Injectable } from '@angular/core';
import { IPerson } from '@models/iperson';
import { ISorter } from '../isorter';

@Injectable({
  providedIn: 'root'
})
export class PersonSortService implements ISorter<IPerson> {
  sortAscending(input: IPerson[]): void {
    if (input) {
      input.sort((a, b) => {
        return (a.firstName + a.lastName).localeCompare((b.firstName + b.lastName));
      });
    }
  }
  sortDescending(input: IPerson[]): void {
    if (input) {
      input.sort((a, b) => {
        return (b.firstName + b.lastName).localeCompare((a.firstName + a.lastName));
      });
    }
  }
}