import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@core/services/Auth0/auth.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { StateNoticeRulesService } from '@core/services/rules/notices/state-notice-rules.service';
import { PersonSortService } from '@core/services/sorters/person/person-sort.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { UserService } from '@core/services/user/user.service';
import { DashboardTypes } from '@enums/dashboard-types.enum';
import { FeatureFlags } from '@enums/feature-flags.enum';
import { OrderStatus } from '@enums/order-status.enum';
import { eClosingSearchResults } from '@models/eClosing-search-results';
import { Order } from '@models/order';
import { OrderDetail } from '@models/order-detail';
import { NoticeRule } from '@models/rules/notice-rule';
import { RuleSet } from '@models/rules/rule-set';
import { SearchResults } from '@models/search-results';
import { UserProfile } from '@models/user-profile';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { SparkSkeletonSettings } from '@shared/modules/sprk-skeleton/models/skeleton-settings';
import { PropertyAddressPipe } from '@shared/pipes/address/property-address.pipe';
import { ContactFullNamePipe } from '@shared/pipes/contact-full-name/contact-full-name.pipe';
import { OrderLoanNumberPipe } from '@shared/pipes/order-loan-number/order-loan-number.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit, OnDestroy {
  public dashboardVersion$: Observable<number> = this._featureService.versions$.pipe(
    map(versions => versions.get(FeatureFlags.DashboardVersion))
  );

  @Input()
  orders: SearchResults | eClosingSearchResults;

  @Input()
  ordersLoaded: boolean;

  @Input()
  orderType: DashboardTypes;

  @Input()
  pageSize: number = 10;

  @Input()
  currentPage: number = 1;

  @Input()
  closingColumnText: string = 'Closing';

  @Input()
  loanStatusColumnText: string = 'Loan Status';

  @Input()
  showSubscriberColumn: boolean = true;

  @Input()
  showPagination: boolean = true;

  @Input()
  showClosingTypeColumn: boolean = false;

  @Input()
  skeletonSettings: SparkSkeletonSettings = new SparkSkeletonSettings();

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  rowClick: EventEmitter<Order> = new EventEmitter<Order>();

  @Output()
  acceptClick: EventEmitter<OrderDetail> = new EventEmitter<OrderDetail>();

  @Output()
  declineClick: EventEmitter<OrderDetail> = new EventEmitter<OrderDetail>();

  public userProfile: UserProfile;
  public showPopOver: Array<boolean> = [];
  public clickableStatusIds: number[] = [OrderStatus.Completed, OrderStatus.Open, OrderStatus.Closing];
  public subscribableStatusIds: number[] = [OrderStatus.Open, OrderStatus.Closing];
  public orderStatusEnum = OrderStatus;
  public isSsoUser: boolean;
  public skeletonColumns: number = 6;
  public addressPipe: PropertyAddressPipe = new PropertyAddressPipe();
  public contactNamePipe: ContactFullNamePipe = new ContactFullNamePipe();
  public orderLoanNumberPipe: OrderLoanNumberPipe = new OrderLoanNumberPipe();

  private _pristineOrderDetails: OrderDetail[] = null;
  component: {};

  constructor(
    private readonly _featureService: FeatureService,
    private readonly _modalService: BsModalService,
    private readonly _toastrService: ToastrService,
    public readonly _subscriptionService: OrderSubscriptionService,
    private readonly _personSorter: PersonSortService,
    private readonly _userService: UserService,
    private readonly _authService: AuthService,
    private readonly _customEventSvc: CustomEventsService,
    private readonly _stateNoticeService: StateNoticeRulesService) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this._modalService.hide(1);
  }

  private init(): void {
    this._userService.userProfile$
      .pipe(
        map(profile => {
          this.userProfile = profile;
        })
      ).subscribe();

    this._authService.isSSOUser$.subscribe(x => this.isSsoUser = x);

    switch (this.orderType) {
      case DashboardTypes.ActiveOrders:
        this.skeletonColumns = 6;
        break;
      case DashboardTypes.NewOrders:
      case DashboardTypes.CompletedOrders:
        this.skeletonColumns = 5;
        break;
      default:
        this.skeletonColumns = 6;
        break;
    }
  }

  public changePage(pageNumber: number): void {
    this.pageChange.emit(pageNumber);
  }

  public getNotice(stateCode: string | null, orderStatus: OrderStatus): string {
    if (!stateCode) return '';
    const ruleSet: RuleSet<string, NoticeRule> = this._stateNoticeService.getRules(stateCode, orderStatus);

    return ruleSet.rules.map(n => n.notice).join(' ');
  }

  public showNotice(stateCode: string | null, orderStatus: OrderStatus): boolean {
    if (!stateCode) return false;

    return this.getNotice(stateCode, orderStatus).length > 0;
  }

  public getBorderWidth(stateCode: string | null, orderStatus: OrderStatus): number {
    if (this.getNotice(stateCode, orderStatus).length === 0) return;

    return 0;
  }
}
