import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(utcDateTime: any, showTimeZone: boolean = true): any {
    if (!utcDateTime) {
      return '';
    }
    utcDateTime = utcDateTime + "Z";

    const dateValue = new Date(utcDateTime);

    if (showTimeZone) {
      const time = dateValue.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      });
      const timeZone = dateValue.toTimeString().split(' ');

      timeZone.splice(0, 2);
      return dateValue.toDateString() + " " + time + " " + timeZone.join(' ');
    }

    const timeShort = dateValue.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    return dateValue.toDateString() + " " + timeShort;
  }
}