<ng-container *ngIf="historyLoaded; else skeleton">
  <ng-container *ngIf="documentHistory.length > 0; else error">
    <ng-container *ngFor="let doc of documentHistory">
      <div class="sprk-c-Card sprk-o-Stack slide--down p-4 background-color--white w-100 sprk-u-mbs">
        <div class="sprk-o-Stack__item">
          <div class="row">
            <div class="col-4">
              <span class="sprk-b-TypeDisplaySeven">File Name </span>
            </div>
            <div class="col-5">
              <span class="sprk-b-TypeDisplaySeven">Upload Date </span>
            </div>
            <div class="col-3 text-right">
              <ng-container *ngIf="doc.documentStatus !== documentStatuses.UploadFailed 
                                  && doc.documentStatus !== documentStatuses.Uploading;">
                <label [ngClass]="{'sprk-b-Link': !downloadInProgress}"
                       idString="default-link"
                       analyticsString="default-link"
                       (click)="!downloadInProgress && downloadDocument(doc)">
                  Download
                </label>
              </ng-container>
              <ng-container *ngIf="doc.documentStatus === documentStatuses.UploadFailed;">
                <span class="sprk-c-Alert--fail sprk-u-pas sprk-u-WhiteSpace--nowrap">
                  <span class="sprk-c-Alert__icon sprk-u-mrs">
                    <sprk-icon iconType="exclamation-filled"
                               additionalClasses="sprk-c-Icon--l sprk-c-Icon--filled-current-color">
                    </sprk-icon>
                  </span>
                  <span class="sprk-u-WhiteSpace--nowrap">
                    Upload Failed
                  </span>
                </span>
              </ng-container>
              <ng-container *ngIf="doc.documentStatus === documentStatuses.Uploading;">
                <span class="sprk-c-Alert--info sprk-u-pas sprk-u-WhiteSpace--nowrap">
                  <span class="sprk-c-Alert__icon sprk-u-mrs">
                    <sprk-icon iconType="bell-filled"
                               additionalClasses="sprk-c-Icon--l sprk-c-Icon--filled-current-color">
                    </sprk-icon>
                  </span>
                  <span class="sprk-u-WhiteSpace--nowrap">
                    Uploading
                  </span>
                </span>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <strong>{{doc.documentName}}</strong>
            </div>
            <div class="col-5">
              {{doc.createdDate | localTime}}
            </div>
            <div class="col-3">&nbsp;</div>
          </div>
          <div class="row"
               *ngIf="doc.documentDescription">
            <div class="col col-12">
              <p *ngIf="doc.documentDescription"
                 class="sprk-u-mtm">
                <strong>Notes: </strong>{{doc.documentDescription}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #error>
    <app-no-history [milestoneTitle]="milestone.title"></app-no-history>
  </ng-template>
</ng-container>
<ng-template #skeleton>
  <sprk-card-skeleton [lines]="2"
                      [count]="1"
                      [appearance]="'line'"
                      [theme]="skeletonSettings.themes.default">
  </sprk-card-skeleton>
</ng-template>