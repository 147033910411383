import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MilestoneHelperService } from '@core/services/milestone/milestone-helper.service';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Milestone } from 'src/app/models/milestone';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-document-milestone-list',
  templateUrl: './document-milestone-list.component.html'
})
export class DocumentMilestoneListComponent extends OnDestroySubscriptionResolver implements OnDestroy, OnInit {
  @Input() milestones: Array<Milestone>;

  public hideRevision: Array<boolean>;
  public readonly milestoneStatus = MilestoneStatus;
  public readonly stringLimit = 95;
  public downloadInProgress: boolean = false;
  private _messagesProcessed = false;

  constructor(
    private readonly _orderViewService: OrderViewService,
    private readonly _milestoneHelperService: MilestoneHelperService) { super(); }

  public ngOnInit(): void {
    this.hideRevision = new Array(this.milestones?.length).fill(false);
  }

  public getDocumentMetaData(milestoneDefinitionId: number, referenceId: string): void {
    this.downloadInProgress = true;
    this._orderViewService.orderId$.pipe(
      switchMap(orderId => this._orderViewService.downloadDocument$(milestoneDefinitionId, orderId, this._unsubscribe$$, referenceId)
        .pipe(takeUntil(this._unsubscribe$$)))).pipe(takeUntil(this._unsubscribe$$)).subscribe(_ => this.handleDownloadResponse(), _ => this.handleDownloadResponse());
  }

  public showHideButton(milestone: Milestone): boolean {
    if (milestone.milestoneStatusId === MilestoneStatus.Revision && !this._messagesProcessed
      && milestone.milestoneMessage != null) {
      this.milestones.forEach((milestone, indx) => {
        if (milestone.milestoneDefinitionId === 4) {
          milestone = this._milestoneHelperService.processRevisionSubtitles(milestone);
        }
        if (milestone.milestoneMessage?.length > this.stringLimit) {
          this.hideRevision[indx] = true;
        }
      })
      this._messagesProcessed = true;
    }
    return milestone.milestoneMessage?.length > this.stringLimit;
  }

  private handleDownloadResponse() {
    this.downloadInProgress = false;
    this._unsubscribe$$.next();
  }
}
