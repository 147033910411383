import { HttpEventType } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { Milestone } from './milestone';

export class FileUploadRequest {
  public httpEventType: HttpEventType | null;
  public milestone: Milestone | null;
  public milestones: Array<Milestone>;
  public totalUploadSize: number;
  public bytesUploaded: number;
  public isCollapsed: boolean = true;
  public documentDescription: string;

  constructor(private readonly _file: File, milestone?: Milestone, milestones?: Array<Milestone>) {
    this._id = Guid.create().toString();
    this._uploadDate = new Date();
    this.httpEventType = null;
    this.milestone = milestone;
    this.milestones = milestones;
  }

  public set httpResponseCode(httpResponseCode: number) {
    if (this.httpEventType === HttpEventType.Response) {
      this._httpResponseCode = httpResponseCode;
    }
  }

  public get httpResponseCode(): number {
    return this._httpResponseCode;
  }

  public get uploadDate(): Date {
    return this._uploadDate;
  }

  public get id(): string {
    return this._id;
  }

  public get file(): File {
    return this._file;
  }

  public get uploadPercentage(): number {
    return Math.round(100 * this.bytesUploaded / this.totalUploadSize);
  }

  public get isUploading(): boolean {
    return this._isUploading;
  }

  public set isUploading(value: boolean) {
    if (this.isQueued) {
      this.isQueued = false;
    }

    this._isUploading = value;
  }

  public get isQueued(): boolean {
    if (!this._isQueued) {
      return false;
    }

    return this._isQueued as boolean;
  }

  public set isQueued(value: boolean | null) {
    this._isQueued = value;
  }

  public get hasSuccessfulResponseCode(): boolean {
    if (!this._httpResponseCode) {
      return false;
    }

    return this._httpResponseCode >= 200 && this._httpResponseCode <= 299;
  }

  public get hasUploadFailed(): boolean {
    if (!this._httpResponseCode) {
      return null;
    }

    return this._httpResponseCode < 200 || this._httpResponseCode >= 300;
  }

  private readonly _id: string;
  private _httpResponseCode?: number;
  private _uploadDate: Date;
  private _isQueued: boolean | null;
  private _isUploading: boolean | null;
}
