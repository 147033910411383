<table id="orderListSkeleton"
       aria-label="activeOrders"
       class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray sprk-b-Table--borders">
  <thead class="sprk-b-Table__head">
    <tr>
      <th *ngFor="let columns of Columns">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [appearance]="settings.appearance"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of Rows">
      <td *ngFor="let columns of Columns">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [appearance]="settings.appearance"
                            [theme]="settings.themes.default"></sprk-skeleton-bone>
      </td>
    </tr>
  </tbody>
</table>