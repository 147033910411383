import { Component } from '@angular/core';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';

@Component({
  selector: 'sprk-skeleton-bone',
  templateUrl: './sprk-skeleton-bone.component.html'
})
export class SprkSkeletonBoneComponent extends NgxSkeletonLoaderComponent {

  constructor() { super(); }
}