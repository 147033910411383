import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export abstract class OnDestroySubscriptionResolver implements OnDestroy {
  protected readonly _unsubscribe$$ = new Subject<void>();

  public ngOnDestroy(): void {
    this._unsubscribe$$.next();
    this._unsubscribe$$.complete();
  }
}
