import { Component, Input, OnDestroy } from '@angular/core';
import { MilestoneService } from '@core/services/milestone/milestone.service';
import { MilestoneDefinitionId } from '@enums/milestone-definition-ids.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { DocumentMetaData } from '@models/document-meta-data';
import { Milestone } from '@models/milestone';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-document-download-milestone-list',
  templateUrl: './document-download-milestone-list.component.html'
})
export class DocumentDownloadMilestoneListComponent extends OnDestroySubscriptionResolver implements OnDestroy {
  @Input() milestones: Array<Milestone>;
  @Input() closingDocuments: Array<DocumentMetaData>;

  public readonly milestoneStatus = MilestoneStatus;
  public readonly milestoneDefinitionIds = MilestoneDefinitionId;
  public selectedDocumentId: number;
  public downloadInProgress: boolean = false;

  constructor(
    private readonly _orderViewService: OrderViewService,
    private readonly _milestoneService: MilestoneService) {
    super();
    this.selectedDocumentId = 0;
  }

  public get isNewClosingPackage(): Observable<boolean> {
    return this._orderViewService.isNewClosingPackage$;
  }

  public downloadDocument(milestone: Milestone, selectedDocumentId: number): void {
    this.downloadInProgress = true;
    let p_orderId = 0;
    this._orderViewService.orderId$.pipe(
      switchMap(orderId => {
        p_orderId = orderId;
        return selectedDocumentId > 0 ?
          this._orderViewService.downloadDocumentByDocumentId$(selectedDocumentId, orderId, this._unsubscribe$$) :
          this._orderViewService.downloadDocument$(milestone.milestoneDefinitionId, orderId, this._unsubscribe$$, milestone.referenceId);
      }),
      switchMap(() => this._milestoneService.update$(p_orderId, milestone, MilestoneStatus.Complete)),
      map((() => {
        this._unsubscribe$$.next();
      }), takeUntil(this._unsubscribe$$))).subscribe(_ => this.downloadInProgress = false, _ => this.downloadInProgress = false);
  }
}
