<main>
  <div class="min-height--90vh">
    <ng-container *ngIf="headerVersion$ | async as version">
      <catch-header *ngIf="version === 2; else fallback"></catch-header>
      <ng-template #fallback>
        <app-header></app-header>
      </ng-template>
    </ng-container>
    <router-outlet></router-outlet>
  </div>
  <app-icon-set></app-icon-set>
  <ng-container *ngIf="headerVersion$ | async as version">
    <catch-footer *ngIf="version === 2; else fallback"></catch-footer>
    <ng-template #fallback>
      <app-footer></app-footer>
    </ng-template>
  </ng-container>
</main>
