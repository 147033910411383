import { Component, Input } from "@angular/core";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";
import { ISkeletonSettings, SparkSkeletonSettings } from "../models/skeleton-settings";

@Component({
  template: ''
})
export abstract class SprkSkeletonBaseComponent extends NgxSkeletonLoaderComponent {
  @Input() count: number = 1;

  public settings: ISkeletonSettings = new SparkSkeletonSettings();
}