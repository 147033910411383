import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { DynamicFormsModule } from '@closing-portal/dynamic-forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalTimePipe } from '@shared/pipes/local-time/local-time.pipe';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ToastrModule } from 'ngx-toastr';
import { NgxUploaderModule } from 'ngx-uploader';
import { AgencySelectionComponent } from './components/agency-selection/agency-selection.component';
import { AgentsPopupComponent } from './components/agents-popup/agents-popup.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DocumentDropdownComponent } from './components/document-dropdown/document-dropdown.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { HelpfulResourcesComponent } from './components/helpful-resources/helpful-resources.component';
import { IconSetComponent } from './components/icon-set/icon-set.component';
import { MessageTruncationToggleComponent } from './components/message-truncation-toggle/message-truncation-toggle.component';
import { MilestoneStatusComponent } from './components/milestone-status/milestone-status.component';
import { DataMilestoneListComponent } from './components/milestones/data-milestone-list/data-milestone-list.component';
import { AddressVerifyPopupComponent } from './components/milestones/data-milestone/address-verify-popup/address-verify-popup.component';
import { DataMilestoneComponent } from './components/milestones/data-milestone/data-milestone.component';
import { DocumentDownloadMilestoneListComponent } from './components/milestones/document-download-milestone-list/document-download-milestone-list.component';
import { DocumentHistoryModalComponent } from './components/milestones/document-history/document-history-modal/document-history-modal.component';
import { DocumentHistoryV2Component } from './components/milestones/document-history/document-history-v2/document-history-v2.component';
import { DocumentHistoryComponent } from './components/milestones/document-history/document-history/document-history.component';
import { NoHistoryComponent } from './components/milestones/document-history/no-history/no-history.component';
import { DocumentMilestoneListComponent } from './components/milestones/document-milestone-list/document-milestone-list.component';
import { FinalDataMilestoneListComponent } from './components/milestones/final-data-milestone-list/final-data-milestone-list.component';
import { GeneralMilestoneListComponent } from './components/milestones/general-milestone-list/general-milestone-list.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { NewOrderListComponent } from './components/new-order-list/new-order-list.component';
import { NewOrderPopupComponent } from './components/new-order-popup/new-order-popup.component';
import { OrderDeclineComponent } from './components/order-decline/order-decline.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { TabListComponent } from './components/tabs/tab-list/tab-list/tab-list.component';
import { TabComponent } from './components/tabs/tab/tab/tab.component';
import { ToTopComponent } from './components/to-top/to-top.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { DebounceClickDirective } from './directives/debounce-click/debounce-click.directive';
import { AdobeAnalyticsModule } from './modules/adobe-analytics/adobe-analytics.module';
import { SkeletonLoadersModule } from './modules/skeleton-loaders/skeleton-loaders.module';
import { SparkSkeletonModule } from './modules/sprk-skeleton/sprk-skeleton.module';
import { PropertyAddressPipe } from './pipes/address/property-address.pipe';
import { AgencyAddressPipe } from './pipes/agency-address/agency-address.pipe';
import { ClientContactPipe } from './pipes/client-contact/client-contact.pipe';
import { ClientNamePipe } from './pipes/client-name/client-name.pipe';
import { ContactFullNamePipe } from './pipes/contact-full-name/contact-full-name.pipe';
import { CustomDatePipe } from './pipes/custom-date/custom-date.pipe';
import { PartnerDocumentPipe } from './pipes/document-filter/partner-document.pipe';
import { FavoriteOrdersPipe } from './pipes/favorite-orders/favorite-orders.pipe';
import { GroupByPipe } from './pipes/group-by/group-by.pipe';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { LoanNumberPipe } from './pipes/loan-number/loan-number.pipe';
import { MaxCountPipe } from './pipes/max-count/max-count.pipe';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { OrderLoanNumberPipe } from './pipes/order-loan-number/order-loan-number.pipe';
import { OrderStatusPipe } from './pipes/order-status-pipe/order-status-pipe';
import { BusinessPhonePipe, PhonePipe } from './pipes/phone/phone.pipe';
import { QlContactPipe } from './pipes/ql-contact/ql-contact.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { SubscribeComponentV2 } from '../features/shared/components/subscribe-v2/subscribe-v2.component';
import { OrderListComponentV2 } from '../features/shared/components/order-list-v2/order-list-v2.component';

@NgModule({
  declarations: [
    ClientContactPipe,
    PhonePipe,
    BusinessPhonePipe,
    CustomDatePipe,
    ClientNamePipe,
    PartnerDocumentPipe,
    DocumentMilestoneListComponent,
    DataMilestoneListComponent,
    FinalDataMilestoneListComponent,
    ModalPopupComponent,
    IconSetComponent,
    DropZoneComponent,
    MilestoneStatusComponent,
    UploadProgressComponent,
    OrderDeclineComponent,
    QlContactPipe,
    OrderStatusPipe,
    MaxCountPipe,
    GroupByPipe,
    OrderByPipe,
    GeneralMilestoneListComponent,
    DataMilestoneComponent,
    HelpfulResourcesComponent,
    DocumentDownloadMilestoneListComponent,
    DocumentDropdownComponent,
    AgentsPopupComponent,
    FavoriteOrdersPipe,
    MessageTruncationToggleComponent,
    DebounceClickDirective,
    DocumentHistoryComponent,
    AgencySelectionComponent,
    LocalTimePipe,
    BadgeComponent,
    LoanNumberPipe,
    PropertyAddressPipe,
    PrintButtonComponent,
    OrderListComponent,
    NewOrderListComponent,
    ToTopComponent,
    AddressVerifyPopupComponent,
    DocumentHistoryV2Component,
    DocumentHistoryModalComponent,
    NoHistoryComponent,
    NewOrderPopupComponent,
    HighlightPipe,
    ContactFullNamePipe,
    OrderLoanNumberPipe,
    AgencyAddressPipe,
    SubscribeComponent,
    TabComponent,
    TabListComponent
  ],
  imports: [
    CommonModule,
    NgxUploaderModule,
    FontAwesomeModule,
    NgxFilesizeModule,
    SparkAngularModule,
    ReactiveFormsModule,
    DynamicFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true
    }),
    AngularMultiSelectModule,
    FormsModule,
    SparkSkeletonModule,
    SkeletonLoadersModule,
    BsDatepickerModule.forRoot(),
    AdobeAnalyticsModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    SubscribeComponentV2,
    OrderListComponentV2
  ],
  exports: [
    NgxUploaderModule,
    FontAwesomeModule,
    NgxFilesizeModule,
    SparkAngularModule,
    SparkSkeletonModule,
    SkeletonLoadersModule,
    ClientContactPipe,
    PhonePipe,
    BusinessPhonePipe,
    CustomDatePipe,
    ClientNamePipe,
    PartnerDocumentPipe,
    DocumentMilestoneListComponent,
    ModalPopupComponent,
    IconSetComponent,
    DropZoneComponent,
    MilestoneStatusComponent,
    UploadProgressComponent,
    OrderDeclineComponent,
    DataMilestoneListComponent,
    FinalDataMilestoneListComponent,
    QlContactPipe,
    OrderStatusPipe,
    MaxCountPipe,
    LoanNumberPipe,
    GroupByPipe,
    OrderByPipe,
    GeneralMilestoneListComponent,
    DataMilestoneComponent,
    HelpfulResourcesComponent,
    BsDatepickerModule,
    DocumentDownloadMilestoneListComponent,
    FavoriteOrdersPipe,
    MessageTruncationToggleComponent,
    DebounceClickDirective,
    AgencySelectionComponent,
    LocalTimePipe,
    BadgeComponent,
    PropertyAddressPipe,
    PrintButtonComponent,
    OrderListComponent,
    ToTopComponent,
    DocumentHistoryV2Component,
    DocumentHistoryModalComponent,
    NewOrderPopupComponent,
    HighlightPipe,
    ContactFullNamePipe,
    OrderLoanNumberPipe,
    AgencyAddressPipe,
    SubscribeComponent,
    TabComponent,
    TabListComponent
  ],
  providers: [ClientNamePipe, PhonePipe]
})
export class SharedModule { }
