import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyHttpClientService } from '@core/services/clients/lazy-http-client.service';
import { HttpHelperService } from '@core/services/http-helper/http-helper.service';
import { DocMetaData } from '@models/doc-metadata';
import { DocumentConfigurations } from '@models/document-configurations';
import { DocumentData } from '@models/document-data';
import { DocumentMetaData } from '@models/document-meta-data';
import { DocumentResourceResponse } from '@models/doucment-resource-response';
import { DocumentResponse } from '@models/doucment-response';
import { DocumentMetaDataReference } from '@shared/modules/adobe-analytics/models/custom-events-metadata';
import { Observable, throwError } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private readonly _httpHelper: HttpHelperService,
    private readonly _httpClient: HttpClient,
    private readonly _lazyHttpClientService: LazyHttpClientService
  ) { }

  public getDocuments$(orderId: number): Observable<DocumentMetaData[]> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<DocumentMetaData[]>('GET', this.createDocumentsRoutePath(orderId));
  }

  /*
  Calls DocumentDocumentBytes in the document controller V1 UI service.
  */
  public getDocument$(orderId: number, documentId: number, s3ObjectKey?: string): Observable<DocumentResponse> {
    let downloadDocumentsRoutePath = `${this.createDocumentsRoutePath(orderId)}/${documentId}`;

    if (!!s3ObjectKey) {
      downloadDocumentsRoutePath += `?s3ObjectKey=${s3ObjectKey}`;
    }

    return this._httpHelper.sendHttpRequestWithRetryStrategy$<DocumentResponse>('GET', downloadDocumentsRoutePath);
  }

  /*
  Calls GetDocumentDownloadUrl in the document controller V2 controller
  */
  public getDocumentV2$(orderId: number, documentId: number, s3ObjectKey?: string): Observable<DocumentResourceResponse> {
    let downloadDocumentsRoutePath = `${this.createDocumentsRoutePath(orderId)}/${documentId}`;

    if (!!s3ObjectKey) {
      downloadDocumentsRoutePath += `?s3ObjectKey=${s3ObjectKey}`;
    }

    return this._httpClient
      .get<DocumentResourceResponse>(downloadDocumentsRoutePath, { headers: { "api-version": "2.0" } })
      .pipe(first());
  }

  public getDocumentsMetaData$(orderId: number, milestoneDefinitionId: number, referenceId: string): Observable<Array<DocumentMetaData>> {
    let url = `${this.createDocumentsRoutePath(orderId)}?milestoneDefinitionId=${milestoneDefinitionId}`;

    if (referenceId)
      url += `&referenceId=${referenceId}`;

    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<Array<DocumentMetaData>>('GET', url);

  }

  public getDocumentConfigurations$(): Observable<DocumentConfigurations> {
    return this._httpHelper
      .sendHttpRequestWithRetryStrategy$<DocumentConfigurations>('GET', `${environment.catchApiUrl}/documents/configurations`)
      .pipe(shareReplay(1));
  }

  /*
  Calls UploadDocumentsMetaData in UI Service
  */
  public getPreSignUrl$(
    orderId: number,
    loanNumber: string,
    documentName: string,
    documentDescription: string = null,
    milestoneReference?: Array<DocumentMetaDataReference>): Observable<DocumentData> {
    const requestBody = milestoneReference?.length > 0 ?
      { loanNumber, documentName, documentDescription, milestoneReference } :
      { loanNumber, documentName, documentDescription };

    return this._httpClient.put<DocumentData>(`${this.createDocumentsRoutePath(orderId)}`, requestBody, { headers: { "api-version": "2.1" } });
  }

  public uploadFile$(preSignUrl: string, file: File): Observable<HttpEvent<any>> {
    try {
      const s3HttpClient = this._lazyHttpClientService.getHttpClient();
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      return s3HttpClient.put(preSignUrl, formData, { observe: 'events', reportProgress: true });
    } catch (error) {
      return throwError(error);
    }
  }

  private createDocumentsRoutePath(orderId: number): string {
    return `${environment.catchApiUrl}/orders/${orderId}/documents`;
  }
}
