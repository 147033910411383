import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Milestone } from '@models/milestone';

@Component({
  selector: 'app-document-history-modal',
  templateUrl: './document-history-modal.component.html'
})
export class DocumentHistoryModalComponent implements OnInit {
  @Input() milestone: Milestone = new Milestone();
  @Input() title: string = '';
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>()

  ngOnInit(): void {
    document.querySelector('body').style.overflow = 'hidden';
  }

  public close(): void {
    document.querySelector('body').style.overflow = 'visible';
    this.onClose.emit();
  }
}