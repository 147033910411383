import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import { NavigationHistory } from '@models/navigation-history';
import { filter } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {
  public dashboardUrl: string = ApplicationPaths.Dashboard;
  private _history: NavigationHistory[] = [];

  constructor(private _router: Router) { }

  public init(): void {
    this._history = [];
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.addHistory(e);
      });
  }

  public get previousUrl(): NavigationHistory {
    return this.getHistory(-2);
  }

  private addHistory(route: NavigationEnd): void {
    const previous = this.getHistory(-1);
    if(previous?.url !== route.urlAfterRedirects){
      let history = new NavigationHistory();
      const segments = this._router.getCurrentNavigation().extractedUrl.root.children['primary']?.segments;
      history.url = route.urlAfterRedirects;
  
      history.segments = segments ?? [new UrlSegment('dashboard', null)];
  
      this._history.push(history);
    }    
  }

  private getHistory(offset: number): NavigationHistory {
    return this._history[this._history.length + offset];
  }
}