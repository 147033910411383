import { Component } from '@angular/core';
import { OfficeHoursService } from '@core/services/office-hours/office-hours.service';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { RktLinkModule, RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  standalone: true,
  imports: [
    RktStackModule,
    RktLinkModule,
    CommonModule
  ]
})
export class ContactUsComponent extends OnDestroySubscriptionResolver {
  phone: string = "(800) 985-2488";
  email: string = "ClosingPortalSupport@RocketMortgage.com";
  public get hoursOfOperation$(): Observable<string> {
    return this._officeHourService.officeHours$;
  };

  constructor(private readonly _officeHourService: OfficeHoursService) { super(); }
}
