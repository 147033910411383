<table *ngIf="documents?.length>0"
  class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray"
  description="Documents available to download for users">
  <thead sprkTableHead>
    <tr>
      <th id="rescourceTable">Document Name</th>
      <th id="th_history"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngFor="let document of (documents | partnerDocument: false) | groupBy: 'documentName' | orderBy:'documentName'; let idx=index;">
      <tr>
        <td>
          {{ document.key }}
        </td>
        <td class="d-flex justify-content-end">
          <label (click)="toggleHistory(idx)" class="sprk-b-Link" idString="default-link"
            analyticsString="default-link">
            View History
          </label>
        </td>
      </tr>
      <tr *ngIf="isDocHistoryVisible[idx]" id="RowX" colspan="3">
        <td colspan="3" class="background-color--off-white overflow-hidden">
          <div class="sprk-c-Card sprk-o-Stack  p-4 background-color--white w-100" style="max-width: none;">
            <sprk-table idString="table-2" additionalTableClasses="sprk-b-Table--secondary sprk-b-Table--white-head"
              aria-label="spark-table-2">
              <tbody>
                <thead sprkTableHead>
                  <tr>
                    <th id="th_uploadDate_{{idx}}">Created Date</th>
                    <th id="th_download_{{idx}}"></th>
                  </tr>
                </thead>
                <tr *ngFor="let doc of document.value | orderBy:'createdDate':'desc'; let inx = index;">
                  <td>{{doc.createdDate | localTime}}</td>
                  <td class="text-right">
                    <label [ngClass]="{'sprk-b-Link': !isDownloading(doc.documentName, inx)}"
                           idString="default-link"
                           analyticsString="default-link"
                           (click)="!isDownloading(doc.documentName, inx) && downloadDocument(doc.documentId, doc.documentName, inx)">
                        {{isDownloading(doc.documentName, inx) ? 'Downloading...' : 'Download'}}
                    </label>
                  </td>
                </tr>
              </tbody>
            </sprk-table>
          </div>
        </td>
      </tr>
  </ng-container>
  </tbody>
</table>