import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxCount',
  pure: false
})
export class MaxCountPipe implements PipeTransform {

  transform(value: number, max: number = 99, suffix: string = '+'): string {
    if (!value)
      return '';
    return (value > max) ? (`${max}${suffix ?? ''}`) : value.toString();
  }
}