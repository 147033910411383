import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HighlightHelperService, HighlightSection } from '@core/services/highlight-helper/highlight-helper.service';
import { OrderStatusService } from '@core/services/order-status/order-status.service';
import { OrderStatus } from '@enums/order-status.enum';
import { OrderDetail } from '@models/order-detail';
import { PropertyAddressPipe } from '@shared/pipes/address/property-address.pipe';
import { ContactFullNamePipe } from '@shared/pipes/contact-full-name/contact-full-name.pipe';
import { OrderLoanNumberPipe } from '@shared/pipes/order-loan-number/order-loan-number.pipe';

@Component({
  selector: 'app-order-search-list-item',
  templateUrl: './order-search-list-item.component.html'
})
export class OrderSearchListItemComponent {
  @Input() orderDetails: OrderDetail = new OrderDetail();
  @Output() orderClicked: EventEmitter<OrderDetail> = new EventEmitter<OrderDetail>();
  public addressPipe: PropertyAddressPipe = new PropertyAddressPipe();
  public contactNamePipe: ContactFullNamePipe = new ContactFullNamePipe();
  public orderLoanNumberPipe: OrderLoanNumberPipe = new OrderLoanNumberPipe();
  public highlightSections = HighlightSection;

  constructor(private readonly _orderStatusService: OrderStatusService, private readonly _highlightHelper: HighlightHelperService) { }

  public onOrderClicked(): void {
    if (!this.canClickOrder)
      return;

    this.orderClicked.emit(this.orderDetails);
  }

  public get canClickOrder(): boolean {
    return this._orderStatusService.canClickOrder(this.orderDetails.orderStatusId);
  }

  public get orderStatus(): string {
    return OrderStatus[this.orderDetails.orderStatusId];
  }

  public get highlightSection(): HighlightSection {
    return this._highlightHelper.getHighlightSection(this.orderDetails.highlight);
  }
}
