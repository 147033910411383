import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RktSkeletonModule, RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { OrderListComponent } from '@shared/components/order-list/order-list.component';
import { SkeletonLoadersModule } from '@shared/modules/skeleton-loaders/skeleton-loaders.module';
import { SparkSkeletonModule } from '@shared/modules/sprk-skeleton/sprk-skeleton.module';
import { ClientContactPipeV2 } from '../../pipes/client-contact-v2/client-contact-v2.pipe';
import { HighlightPipeV2 } from '../../pipes/highlight-v2/highlight-v2.pipe';
import { OrderStatusPipeV2 } from '../../pipes/order-status-v2/order-status-v2.pipe';
import { SubscribeComponentV2 } from '../subscribe-v2/subscribe-v2.component';

@Component({
  selector: 'catch-order-list',
  standalone: true,
  imports: [
    CommonModule,
    RktStackModule,
    MatTableModule,
    MatPaginatorModule,
    RktSkeletonModule,
    SkeletonLoadersModule,
    SparkSkeletonModule,
    SubscribeComponentV2,
    HighlightPipeV2,
    ClientContactPipeV2,
    OrderStatusPipeV2,
    SubscribeComponentV2
  ],
  templateUrl: './order-list-v2.component.html',
  styleUrls: ['./order-list-v2.component.scss'],
})
export class OrderListComponentV2 extends OrderListComponent implements OnInit {
  @Input()
  showActionColumn: boolean = false;

  @Input()
  showOrderStatusColumn: boolean = false;

  private displayableColumns: string[] = [
    'loanNumber',
    'borrowers',
    'propertyAddress',
    'targetClosing',
  ];

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.showSubscriberColumn) {
      this.displayableColumns.unshift('subscribers');
    }
    if (this.showClosingTypeColumn) {
      this.displayableColumns.push('closingType');
    }
    if (this.showActionColumn) {
      this.displayableColumns.push('actions');
    }
    if (this.showOrderStatusColumn) {
      this.displayableColumns.push('orderStatus');
    }
  }

  public get displayedColumns(): string[] { return this.displayableColumns; }

  public handlePageEvent(event: PageEvent): void {
    this.currentPage = event.pageIndex+1;
    this.changePage(this.currentPage);
  }
}
