import { Component, Input, OnInit, Output } from '@angular/core';
import { DocumentUploaderService } from '@core/services/documents/document-uploader.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FileUploadRequest } from '@models/file-upload-request';
import { Milestone } from '@models/milestone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html'
})
export class UploadProgressComponent implements OnInit {
  @Input() orderId: number;
  @Input() milestones: Array<Milestone>;
  @Output() uploadSucceed: boolean;

  public fileUploadRequest: Observable<FileUploadRequest>;
  public readonly faTrash = faTrash;
  public readonly faCheck = faCheck;
  private _loanNumber: string;

  constructor(
    public readonly _documentUploaderService: DocumentUploaderService,
    private readonly _orderViewService: OrderViewService) { }

  ngOnInit() {
    this.loanNumber$.subscribe();
  }

  public get dropdownSettings(): object {
    return {
      text: 'Required: Select Document',
      unSelectAllText: 'Deselect All',
      maxHeight: '200',
      searchBy: ["title"],
      primaryKey: "milestoneDefinitionId",
      labelKey: "title",
      enableSearchFilter: false,
      searchAutofocus: true,
      badgeShowLimit: 1,
      classes: "dropdown dropdown-large"
    };
  }

  private get loanNumber$(): Observable<string> {
    return this._orderViewService.order$.pipe(map(order => this._loanNumber = order.loanNumber));
  }

  public onOptionsSelected(fileUploadRequest: FileUploadRequest, milestoneDefinitionId: number): void {
    const milestoneDefinitionIdIndex = this.milestones.map(x => x.milestoneDefinitionId).indexOf(+milestoneDefinitionId);

    fileUploadRequest.milestone = milestoneDefinitionIdIndex > -1 ? this.milestones[milestoneDefinitionIdIndex] : null;

    this._documentUploaderService.update(fileUploadRequest);
  }

  public onMultipleOptionsSelected(fileUploadRequest: FileUploadRequest, $event): void {
    fileUploadRequest.milestones = $event;

    this._documentUploaderService.update(fileUploadRequest);
  }

  public onUploadClick(): void {
    this._documentUploaderService.uploadAll(this.orderId, this._loanNumber).subscribe();
  }

  public onRemoveClick(fileUploadRequest: FileUploadRequest): void {
    this._documentUploaderService.remove(fileUploadRequest);
  }
}
