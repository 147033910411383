import { Component } from '@angular/core';
import { BaseLoaderComponent } from '../base-loader.component';

@Component({
  selector: 'app-order-view-skeleton-header',
  templateUrl: './order-view-skeleton-header.component.html'
})
export class OrderViewSkeletonHeaderComponent extends BaseLoaderComponent {

  constructor() { super(); }

}
