import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { SessionStorageKey } from '@enums/session-storage-key.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { UserProfile } from '@models/user-profile';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { concatMap, filter, map, shareReplay, single, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends OnDestroySubscriptionResolver {
  private readonly _auth0Client$: Observable<Auth0Client>;
  private readonly _callbackRoute = `${ApplicationPaths.Callback}`;
  private readonly _baseRedirectPath = `/${ApplicationPaths.Dashboard}`;
  private readonly _isLoggedIn$$ = new BehaviorSubject(false);

  public constructor(
    private readonly _router: Router) {
    super();
    this._auth0Client$ = from(
      createAuth0Client({
        domain: environment.authConfig.auth0Domain,
        client_id: environment.authConfig.auth0ClientId,
        audience: environment.authConfig.audience
      })
    ).pipe(shareReplay(1), single());
  }

  public get userProfile$(): Observable<UserProfile> {
    return this._auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims())),
      switchMap(token => of({
        agentId: +('9999' + token['https://ql.custom.openid.com/common_id']) ?? 0,
        firstName: token?.given_name,
        lastName: token?.family_name,
        emailAddress: token?.email,
        isTermsAccepted: true
      } as UserProfile))
    );
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$$.asObservable();
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.isAuthenticated())),
      tap((isLoggedIn: boolean) => {
        this._isLoggedIn$$.next(isLoggedIn);
      })
    );
  }

  public get isSSOUser$(): Observable<boolean> {
    return this._isLoggedIn$$.pipe(
      filter(isLoggedIn => !!isLoggedIn),
      switchMap(_ =>
        this._auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getIdTokenClaims())))
      ),
      map(token => {
        return token['sub']?.startsWith('waad') ?? false;
      })
    );
  }

  public handleRedirectCallback$(url?: string): Observable<RedirectLoginResult> {
    return this._auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );
  }

  public getTokenSilently$(options?): Observable<any> {
    return this._isLoggedIn$$.pipe(
      filter(isLoggedIn => !!isLoggedIn),
      switchMap(_ =>
        this._auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently(options))))
      )
    );
  }

  public login(redirectPath: string = this._baseRedirectPath, agencyId: number = 0): void {
    this._auth0Client$.subscribe((client: Auth0Client) => {
      sessionStorage.clear();

      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/${this._callbackRoute}`,
        appState: { target: redirectPath, agencyId: agencyId },
        connection: +agencyId ? 'azure-mi-corp-rockfin-com' : 'catch'
      });
    });
  }

  public handleAuthCallback(): void {
    if (this.hasDesiredCallbackQueryParameters(window.location.search)) {
      this.handleRedirectCallback$()
        .pipe(
          map(redirectLoginResult => {
            this._router.navigateByUrl(this.getRedirectLoginTarget(redirectLoginResult));
          }),
          takeUntil(this._unsubscribe$$)
        )
        .subscribe();
    }
    else {
      this.routeByAuthStatus(this._baseRedirectPath);
    }
  }

  public logout(): void {
    this._auth0Client$.subscribe((client: Auth0Client) => {
      sessionStorage.clear();

      client.logout({
        client_id: environment.authConfig.auth0ClientId,
        returnTo: `${window.location.origin}${this._baseRedirectPath}`
      });
    });
  }

  private getRedirectLoginTarget(redirectLoginResult: RedirectLoginResult): string {
    if (redirectLoginResult.appState?.agencyId != null) {
      sessionStorage.setItem(SessionStorageKey.AdminAgency, redirectLoginResult.appState?.agencyId);
    }
    if (redirectLoginResult.appState &&
      redirectLoginResult.appState.target &&
      redirectLoginResult.appState.target !== '/') {
      return redirectLoginResult.appState.target;
    }
    else {
      return this._baseRedirectPath;
    }
  }

  private routeByAuthStatus(path: string): void {
    this.isAuthenticated$.subscribe(isAuthenticated => isAuthenticated ? this._router.navigateByUrl(path) : this.logout())
  }

  private hasDesiredCallbackQueryParameters(urlQueryString: string): boolean {
    return urlQueryString.includes('code=') && urlQueryString.includes('state=')
  }
}
