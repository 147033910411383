import { Injectable } from '@angular/core';
import { Badge } from '@models/badge';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrderStatus } from '@enums/order-status.enum';
import { IBadgeService } from '@core/services/badges/IBadge-service';
import { HttpHelperService } from '@core/services/http-helper/http-helper.service';
import { OrderBadgeParameters } from '@models/order-badge-parameters';

@Injectable({
  providedIn: 'root'
})
export class OrdersBadgeService implements IBadgeService<OrderBadgeParameters> {

  constructor(private readonly _httpClient: HttpHelperService) {
  }

  public badge$$: BehaviorSubject<Badge> = new BehaviorSubject<Badge>(new Badge());
  public agencyIdError: string = 'Invalid Agency ID.';
  public orderStatusError: string = 'Invalid Order Status.';
  public missingParamsError: string = 'Missing Parameters.';

  public getBadge(params: OrderBadgeParameters): void {
    const errors = this.validateParameters(params);

    if (errors.length > 0) {
      this.badge$$.error(errors.join(' '));
    } else {
      this._httpClient.sendHttpRequestWithRetryStrategy$<Badge>('GET',
        `${environment.catchApiUrl}/badges/orders?status=${params.statusId}&agencyId=${params.agencyId}`)
        .subscribe(
          badge => {
            this.badge$$.next(badge);
          },
          error => this.badge$$.error(error));
    }
  };

  public setBadge(count: number, name: string) {
    const badge = new Badge();
    badge.count = count;
    badge.name = name;

    this.badge$$.next(badge);
  };

  public get badgeCount(): number {
    return this.badge$$.value.count;
  }

  private validateParameters(params: OrderBadgeParameters): string[] {
    if (!params)
      return [this.missingParamsError];

    const errors: string[] = [];

    if (params.agencyId === null)
      errors.push(this.agencyIdError);

    if (params.statusId === null || !Object.values(OrderStatus).includes(params.statusId))
      errors.push(this.orderStatusError);

    return errors;
  };
}