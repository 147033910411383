<ng-template [ngIf]="!isReadonly"
             [ngIfElse]="readonly">
  <sprk-dropdown *ngIf="userProfile?.associatedAgencies?.length > 1"
                 dropdownType="informational"
                 additionalTriggerClasses="sprk-c-Button sprk-c-Button--secondary sprk-c-Button--compact"
                 triggerText={{triggerText}}
                 title="My Agencies"
                 triggerIconType="chevron-down"
                 [choices]=choices
                 (choiceMade)="onAgencySelection($event)">
  </sprk-dropdown>
</ng-template>
<ng-template #readonly>
  <strong>{{readonlyAgencyInformation}}</strong>
</ng-template>