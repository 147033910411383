<sprk-masthead id="catch-masthead"
               additionalClasses="sprk-c-Masthead--scroll">
  <div sprkMastheadBranding
       sprkStackItem
       class="sprk-o-Stack__item--center-column@xxs">
    <a sprkLink
       (click)="onDashboardClick()"
       variant="unstyled">
      <img class="sprk-c-Masthead__logo"
           logo-slot
           data-analytics-click="return-to-dash-logo"
           [src]="logoSrc"
           alt="Closing Portal By Rocket Mortgage Logo" />
    </a>
  </div>

  <nav sprkMastheadNavItems
       sprkStackItem
       aria-label="Navigation Search"
       class="sprk-o-Stack__item--flex@xxs
       sprk-o-Stack
       sprk-o-Stack--misc-a
       sprk-o-Stack--split@xxs
       sprk-o-Stack--end-row">
    <ul class="sprk-c-Masthead__search
              sprk-o-HorizontalList
              sprk-o-HorizontalList--spacing-large
              sprk-o-Stack--center-column"
        [ngStyle]="{ 'width': searchWidth }">
      <li class="w-100">
        <ng-container *ngIf="canShowDashboardActions()">
          <app-order-search-input (searchWidthChanged)="changeSearchWidth($event)"></app-order-search-input>
        </ng-container>
      </li>
    </ul>
  </nav>

  <nav sprkMastheadNavItems
       sprkStackItem
       aria-label="Navigation Links"
       role="navigation"
       class="sprk-o-Stack__item
       sprk-o-Stack--misc-a
       sprk-o-Stack--split@xxs
       sprk-o-Stack--end-row">
    <ul class="sprk-o-HorizontalList
              sprk-o-HorizontalList--spacing-large
              sprk-o-Stack--center-column">
      <li id="resource-center-link">
        <a sprkMastheadLink>Resource Center</a>
      </li>
      <li>
        <sprk-dropdown [choices]="accountSelection"
                       [triggerText]="[userProfile?.firstName]"
                       triggerIconType="user"
                       additionalTriggerClasses="sprk-b-Link--plain sprk-c-Masthead__link"
                       additionalIconClasses="sprk-c-Icon--l"
                       additionalClasses="sprk-u-Right--zero sprk-u-mrm"
                       (choiceMade)="onAccountSelectionEvent($event)"
                       screenReaderText="User Account">
        </sprk-dropdown>
      </li>
    </ul>

    <ng-template #loggedOut>
      <ul sprkStackItem
          class="
        sprk-o-HorizontalList
        sprk-o-HorizontalList--spacing-large
        sprk-o-Stack--center-column">
        <li>
          <sprk-dropdown [choices]="basicAccountSelection"
                         triggerIconType="user"
                         additionalTriggerClasses="sprk-b-Link--plain sprk-c-Masthead__link"
                         additionalIconClasses="sprk-c-Icon--l"
                         additionalClasses="sprk-u-Right--zero sprk-u-mrm"
                         (choiceMade)="onAccountSelectionEvent($event)"
                         screenReaderText="User Account">
          </sprk-dropdown>
        </li>
      </ul>
    </ng-template>
  </nav>

  <nav sprkMastheadNavCollapsible
       role="navigation"
       aria-label="Collapsible Navigation"
       idString="default-collapsible-nav">
    <ng-container *ngIf="canShowDashboardActions()">
      <sprk-masthead-accordion>
        <sprk-masthead-accordion-item heading="Account Tools">
          <ul class="sprk-b-List sprk-b-List--bare sprk-c-MastheadAccordion__details">
            <li class="sprk-c-MastheadAccordion__item">
              <a sprkLink
                 variant="unstyled"
                 class="sprk-c-MastheadAccordion__summary"
                 (click)="onAccountSelectionEvent(accountSelection[0].value)">
                {{ accountSelection[0].text }}
              </a>
            </li>

            <li class="sprk-c-MastheadAccordion__item">
              <a sprkLink
                 variant="unstyled"
                 class="sprk-c-MastheadAccordion__summary"
                 (click)="onAccountSelectionEvent(accountSelection[1].value)">
                {{ accountSelection[1].text }}
              </a>
            </li>

            <li class="sprk-c-MastheadAccordion__item">
              <a sprkLink
                 variant="unstyled"
                 class="sprk-c-MastheadAccordion__summary"
                 (click)="onAccountSelectionEvent(accountSelection[2].value)">
                {{ accountSelection[2].text }}
              </a>
            </li>
          </ul>
        </sprk-masthead-accordion-item>
      </sprk-masthead-accordion>
    </ng-container>

    <div sprkBox
         spacing="small">
      <a sprkLink
         (click)="onAccountSelectionEvent('sign-out')"
         class="
          sprk-c-Button
          sprk-c-Button--secondary
          sprk-c-Button--compact
          sprk-c-Button--full@s">
        Sign Out
      </a>
    </div>
  </nav>
  <div sprkMastheadNavItem
       sprkStackItem
       class="sprk-o-Stack__item--center-column@xxs">
    <a sprkMastheadLink>
      {{ userProfile?.firstName }}
    </a>
  </div>
</sprk-masthead>

<ng-template #contactUsModal>
  <modal-popup [header]="'Contact Us'"
               (onConfirm)="bsModalRef.hide()">
    <app-contact-us></app-contact-us>
  </modal-popup>
</ng-template>