import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SubscriptionStatusIconsV2 } from '@enums/subscription-status-icons-enum-v2';
import { SubscriptionStatus } from '@enums/subscription-status.enum';
import { SubscribeComponent } from '@shared/components/subscribe/subscribe.component';

@Component({
  selector: 'catch-subscribe',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './subscribe-v2.component.html',
  styleUrls: ['./subscribe-v2.component.scss'],
})
export class SubscribeComponentV2 extends SubscribeComponent {
  public subscriptionStatusIconV2: SubscriptionStatusIconsV2 =
    SubscriptionStatusIconsV2.unsubscribed;

  protected setBookmarkStyle(): void {
    switch (this._subscriptionStatus) {
      case SubscriptionStatus.primaryAgent:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.primaryAgent;
        break;
      case SubscriptionStatus.subscribed:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.subscribed;
        break;
      default:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.unsubscribed;
    }
  }

  public getSubscribers(): string {
    var returnString = '';
    for (var subscriber of this.subscriptions) {
      returnString += `${subscriber.firstName} ${subscriber.lastName}, `;
    }
    return returnString.slice(0, -2);
  }
}
