import { Component, Input } from '@angular/core';
import { LesAddress } from '@models/les-address';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-address-verify-popup',
  templateUrl: './address-verify-popup.component.html'
})
export class AddressVerifyPopupComponent {
  @Input() address: LesAddress;
  @Input() providedAddress: LesAddress;	
  @Input() noAddressFound: boolean;
  public bsModalRef: BsModalRef;
  public addressSelection: string;

  constructor(public _modalSvc: BsModalService) { }

  public hideModel(val: string): void{
    this._modalSvc.setDismissReason(val);
    this._modalSvc.hide(1);
  }

  public selectAddress(selection: string): void{
    this.addressSelection = selection;
  }

}
