<div class="sprk-u-pvs sprk-u-phm order-list-item"
     [ngClass]="{ 'cursor-pointer': canClickOrder, 'cursor-not-allowed': !canClickOrder}"
     (click)="onOrderClicked()"
     [title]="orderStatus">
  <div sprkText
       variant="bodyOne">
    <span [innerHtml]="orderDetails | highlight:orderDetails.highlight:orderLoanNumberPipe"></span>
  </div>
  <div sprkText
       variant="bodyFour">
    <div class="property"
         [innerHtml]="orderDetails.propertyInfo | highlight:orderDetails.highlight:addressPipe"></div>
    <div>
      <ng-container *ngFor="let contact of orderDetails.contacts | clientContact | slice: 0:4">
        <span [innerHtml]="contact | highlight:orderDetails.highlight:contactNamePipe"></span>
      </ng-container>
    </div>
  </div>
</div>