<ng-container>
  <article class="d-flex flex-column pt-3">
    <header class="d-flex align-items-center">
      <h6 sprkHeading
          variant="displaySix"
          class="col px-0">
        <div class="row">
          <div class="col">
            <sprk-skeleton-bone appearance="circle"
                                [animation]="settings.animation"
                                [theme]="settings.themes.circle"></sprk-skeleton-bone>
          </div>
          <div class="col col-lg-10">
            <sprk-skeleton-bone [animation]="settings.animation"
                                [theme]="settings.themes.title"></sprk-skeleton-bone>
          </div>
          <div class="col col-lg-1">
            <sprk-skeleton-bone [animation]="settings.animation"
                                [theme]="settings.themes.title"></sprk-skeleton-bone>
          </div>
        </div>
      </h6>
    </header>
    <div class="pt-2">
      <div class="row pt-2">
        <div class="col-lg-12">
          <sprk-card-skeleton [count]="2"
                              [lines]="3"
                              [theme]="settings.themes.default"></sprk-card-skeleton>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="m-2">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.smallButton"></sprk-skeleton-bone>
      </div>
      <div class="m-2">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.smallButton"></sprk-skeleton-bone>
      </div>
    </div>
  </article>
</ng-container>