<ng-container *ngIf="loaded">
  <mat-icon
    class="rkt-Icon cursor-pointer"
    aria-hidden="false"
    aria-label="Subscribers"
    [svgIcon]="subscriptionStatusIconV2"
    color="accent"
    (click)="changeSubscription($event)"
  ></mat-icon>
  <ng-container *ngIf="showCount">
    <span [matTooltip]="getSubscribers()" matTooltipPosition="after" class="rkt-TooltipPopper"
      >+{{ subscriptions.length }}
      <div *ngIf="showPopOver" (click)="$event.stopPropagation()">
        <h4 al class="rkt-Heading-20" variant="displayFive">
          Subscribers on this Order
          <button type="button" class="close" aria-label="Close" (click)="togglePopup($event)">
            <span aria-hidden="true">&times;</span>
          </button>
        </h4>
      </div>
    </span>
  </ng-container>
</ng-container>
