import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrderRouterService } from '@core/services/order-router/order-router.service';
import { OrderStatusService } from '@core/services/order-status/order-status.service';
import { SearchListService } from '@core/services/search/search-list.service';
import { SearchService } from '@core/services/search/search.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { UserService } from '@core/services/user/user.service';
import { OrderStatus } from '@enums/order-status.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { OrderDetail } from '@models/order-detail';
import { SearchListResults } from '@models/search-list-results';
import { AssociatedAgency } from '@models/user-profile';
import { NewOrderPopupComponent } from '@shared/components/new-order-popup/new-order-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { debounceTime, first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-search-input',
  templateUrl: './order-search-input.component.html',
  styleUrls: ['./order-search-input.component.scss']
})
export class OrderSearchInputComponent extends OnDestroySubscriptionResolver implements OnInit {
  @Output() searchWidthChanged: EventEmitter<string> = new EventEmitter<string>();
  private _minSearchWidth: string = '200px';
  private _maxSearchWidth: string = '50%';
  private _searchWidth: string = this._minSearchWidth;
  private _maxLatency: number = 400;
  private _doSearch: any = null;
  private _searchValue: string = '';
  private _associatedAgencies: Array<AssociatedAgency> = null;

  public searchResults: SearchListResults = new SearchListResults();

  constructor(private readonly _router: Router,
    private readonly _searchListService: SearchListService,
    private readonly _searchService: SearchService,
    private readonly _userService: UserService,
    private _orderStatusService: OrderStatusService,
    private _modalService: BsModalService,
    private readonly _subscriptionService: OrderSubscriptionService,
    private readonly _orderRouterService: OrderRouterService) { super(); }

  public ngOnInit(): void {
    this.initializeSearchResultBinding();
    this.initSearchTermsSubscription();
  }

  public openSearch(event: PointerEvent): void {
    this._searchWidth = this._maxSearchWidth;
    this.searchWidthChanged.emit(this._searchWidth);

    if (this._searchValue && this._searchValue.length > 1)
      this._searchListService.searchTermValue = this._searchValue;
  }

  public blurSearch(waitTime: number): void {
    setTimeout(() => {
      this.searchResults = new SearchListResults();
    }, waitTime);
  }

  public clearSearch(): void {
    const input = document.getElementById('search-input') as HTMLInputElement;
    input.value = '';
    this._searchValue = '';
    this.searchResults = new SearchListResults();
    this._searchWidth = this._minSearchWidth;
    this.searchWidthChanged.emit(this._searchWidth);
  }

  public orderSelected(orderDetails: OrderDetail): void {
    if (this._orderStatusService.canClickOrder(orderDetails.orderStatusId)) {
      this._userService.changeAgency$(orderDetails.agencyId)
        .pipe(
          takeUntil(this._unsubscribe$$),
          map(_ => {
            this.blurSearch(1);

            if (this._orderStatusService.canViewOrder(orderDetails.orderStatusId)) {
              this._orderRouterService.routeToOrderView(orderDetails, 'order-search-input', true);
            }
            else if (orderDetails.orderStatusId === OrderStatus.New) {
              this.handleNewOrderClick(orderDetails);
            }
          }))
        .subscribe();
    }
  }

  public keyPressed(e: KeyboardEvent, term: string): void {
    if (e.key.toLowerCase() === 'enter' && term.length > 1) {
      clearTimeout(this._doSearch);
      this.showAllResults(term);
    }
  }

  public search(searchQuery: string): void {
    clearTimeout(this._doSearch);
    if (searchQuery.length > 1)
      this._doSearch = setTimeout(() => {
        this._searchListService.searchTermValue = searchQuery;
      }, this._maxLatency);
    else
      this.searchResults = new SearchListResults();
  }

  public get searchWidth(): string {
    return this._searchWidth;
  }

  private initializeSearchResultBinding(): void {
    this._searchListService.searchResult$
      .pipe(
        takeUntil(this._unsubscribe$$))
      .subscribe(results => {
        this.searchResults = results;
      });
  }

  private initSearchTermsSubscription(): void {
    this._searchListService.searchTerm$
      .pipe(
        debounceTime(400),
        switchMap(term => {
          this._searchValue = term;

          if (!this._associatedAgencies || this._associatedAgencies.length <= 0)
            return this._userService.associatedAgencies$.pipe(first());

          return of(this._associatedAgencies);
        })
      )
      .subscribe(agencies => {
        this._associatedAgencies = agencies;
        this._searchListService.searchByAgencies$(this._searchValue, agencies.map(a => a.agencyId))
      });
  }

  private handleNewOrderClick(orderDetail: OrderDetail): void {
    this._modalService.show(NewOrderPopupComponent, {
      ignoreBackdropClick: true,
      class: 'sprk-c-Modal accept-modal',
      initialState: {
        agencyId: orderDetail.agencyId,
        loanNumber: orderDetail.loanNumber,
        currentSubscriptions: orderDetail.subscriptions,
        orderDetail: orderDetail,
        level: 1
      }
    });
  }

  public showAllResults(term: string): void {
    this.searchResults = new SearchListResults();
    this._searchService.searchTermValue = term;
    this._subscriptionService.clearCache();
    this._router.navigateByUrl('/search/order-search');
  }
}
