import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '@core/services/order/order.service';
import { StateNoticeRulesService } from '@core/services/rules/notices/state-notice-rules.service';
import { NullablePersonSortService } from '@core/services/sorters/nullable-person/nullable-person-sort.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { UserService } from '@core/services/user/user.service';
import { AgentStatus } from '@enums/agent-status.enum';
import { OrderStatus } from '@enums/order-status.enum';
import { Agent } from '@models/agent';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { Order } from '@models/order';
import { OrderDetail } from '@models/order-detail';
import { OrderSubscription } from '@models/subscription';
import { UserProfile } from '@models/user-profile';
import { FollowEventLocation } from '@shared/modules/adobe-analytics/models/custom-events-metadata';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';
import { OrdersBadgeService } from 'src/app/modules/order-dashboard/services/badges/orders-badge.service';

@Component({
  selector: 'app-new-order-popup',
  templateUrl: './new-order-popup.component.html'
})
export class NewOrderPopupComponent extends OnDestroySubscriptionResolver implements OnInit {

  public agents: Array<Agent> = [];
  public showDropdown: boolean;
  public selectedAgents: Array<Agent> = [];
  public orderSubscriptions: Array<OrderSubscription> = [];
  public currentSubscriptions: Array<OrderSubscription> = [];
  public subscriptions: Subscription = new Subscription();
  public orderDetail: OrderDetail;
  public confirmDeclineActive: boolean = false;
  public title: string = 'Accept Order';
  component: {};

  public selectAll($event: Array<Agent>): void {
    this.selectedAgents = $event;
  }

  public deselectAll(): void {
    this.selectedAgents = [];
  }

  constructor(
    private _userService: UserService,
    private _modalService: BsModalService,
    private _subscriptionService: OrderSubscriptionService,
    private readonly _orderService: OrderService,
    private readonly _personSort: NullablePersonSortService,
    private readonly _stateNoticesService: StateNoticeRulesService,
    private readonly _badgeService: OrdersBadgeService,
    private readonly _router: Router,
    private readonly _customEventSvc: CustomEventsService) { super(); }

  ngOnInit(): void {
    this.currentSubscriptions = this.orderDetail.subscriptions;
    this.initialize()
      .subscribe(userProfile => {
        let agentIndx = this.agents.findIndex(a => a.agentId === userProfile?.agentId);
        if (agentIndx > -1) {
          this.selectedAgents = [this.agents[agentIndx]];
        }

        if (this.currentSubscriptions[0]?.agentId !== userProfile?.agentId) {
          let subIndx = this.agents.findIndex(x => x.agentId === this.currentSubscriptions[0]?.agentId);
          if (subIndx > -1) {
            this.selectedAgents.push(this.agents[subIndx]);
          }
        }
        this.showDropdown = true;
      });
  }

  public get dropdownSettings(): object {
    return {
      noneSelectedBtnText: 'Add Follower(s) to the Order',
      maxHeight: this.agents.length > 4 ? '150' : '300',
      text: "Add Follower(s) to the Order",
      searchBy: ["agentName"],
      badgeShowLimit: 1,
      primaryKey: "agentId",
      labelKey: "agentName",
      enableSearchFilter: true,
      searchAutofocus: true,
      classes: "dropdown"
    };
  }

  public cancel(): void {
    this.hideModel();
  }

  public getNotice(stateCode: string | null): string {
    if (!stateCode) return '';

    const ruleSet = this._stateNoticesService.getRules(stateCode, this.orderDetail.orderStatusId);
    if (!ruleSet.hasRules)
      return '';

    return ruleSet.rules.map(r => r.notice).join(' ');
  }

  public acceptOrder(): void {
    this.orderSubscriptions = [];
    this.selectedAgents.map((agent) => {
      this.orderSubscriptions.push({
        agentId: agent.agentId,
        firstName: agent.firstName,
        lastName: agent.lastName,
        isActive: true
      });
    });
    
    this._orderService.updatePrimaryAgent$(this.orderDetail.orderId, this.orderSubscriptions[0].agentId, this.orderDetail.loanNumber).subscribe();

    this.subscribeToOrderAndUpdate(this.orderSubscriptions)
      .pipe(first())
      .subscribe(order => this.acceptCallback(order));

    this._customEventSvc.pushSubscriberEvent(FollowEventLocation.Acceptance, true, this.orderSubscriptions.length);
  }

  public declineOrder(): void {
    this.title = 'Decline Order Confirmation';
    this.confirmDeclineActive = true;
  }

  public confirmDecline(): void {
    this.updateOrderStatus(this.orderDetail, OrderStatus.Denied)
      .pipe(
        first(),
        catchError(e => of('error')))
      .subscribe(_ => {
        this.hideModel();
      });
  }

  public cancelDecline(): void {
    this.confirmDeclineActive = false;
  }

  private updateOrderStatus(orderDetail: OrderDetail, newOrderStatus: OrderStatus): Observable<Order> {
    orderDetail.orderStatusId = newOrderStatus;
    return this._orderService.updateOrderStatus$(orderDetail.orderId, newOrderStatus, orderDetail.loanNumber);
  }

  private subscribeToOrderAndUpdate(subscriptions: Array<OrderSubscription>): Observable<Order> {
    return this._subscriptionService.subscribeToOrder(this.orderDetail.orderId, subscriptions)
      .pipe(
        switchMap(_ => this.updateOrderStatus(this.orderDetail, OrderStatus.Open))
      );
  }

  private hideModel(): void {
    this._modalService.hide(this._modalService.getModalsCount());
  }

  private acceptCallback(orderDetail: Order): void {
    this._badgeService.setBadge(this._badgeService.badgeCount - 1, OrderStatus.New.toString());
    this._router.navigateByUrl(`${ApplicationPaths.Orders}/${orderDetail.orderId}`);
  }

  private initialize(): Observable<UserProfile> {
    return this._userService.getFilteredAgents$(this.orderDetail.agencyId, (agent => agent.statusCode !== AgentStatus.Deleted))
      .pipe(
        takeUntil(this._unsubscribe$$),
        map(agent => { agent.forEach(i => i.agentName = i.firstName + ' ' + i.lastName); return agent; }),
        switchMap(agents => {
          this._personSort.sortAscending(agents);
          this.agents = agents;
          return this._userService.userProfile$;
        }));
  }
}
