import { Contact } from "./contact";
import { LoanData } from "./loan-data";
import { Property } from "./property";

export class SearchListItem {
  orderId: number;
  loanNumber: string;
  agencyId: number;
  orderStatusId: number;
  propertyInfo: Property;
  contacts: Array<Contact>;
  loanData: LoanData
  highlight: Map<string, Array<string>>;
}