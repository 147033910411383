import { Component, Input } from '@angular/core';
import { BaseLoaderComponent } from '../base-loader.component';

@Component({
  selector: 'app-loan-info-skeleton',
  templateUrl: './loan-info-skeleton.component.html'
})
export class LoanInfoSkeletonComponent extends BaseLoaderComponent {
  @Input() showLoanNumber: boolean = false;

  constructor() { super(); }
}
