export enum DocumentStatuses {
  Unknown = 0,
  Uploading = 1,
  Received = 2,
  Transferring = 3,
  Transferred = 4,
  Reviewing = 5,
  Ready = 6,
  UploadFailed = 7,
  SentToOnbase = 8,
  FailedInDPM = 9
}