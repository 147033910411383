import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/Auth0/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private readonly _router: Router, private readonly _toastrService: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._authService.getTokenSilently$().pipe(
      mergeMap(token => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });

        return next.handle(request);
      }),
      catchError(err => {
        if (err.status == 403) {
          this._toastrService.error(err?.error?.errors[0]?.errorMessage, 'Unauthorized Access');
          this._router.navigateByUrl('/dashboard');
        }
        return throwError(err)
      })
    );
  }
}
