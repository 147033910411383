import { Injectable } from '@angular/core';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';
import { TransactionType } from '@enums/transaction-type.enum';
import { KeyValuePair } from '@closing-portal/dynamic-forms/lib/models/control-meta-data';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SurveyTypeService {

  constructor(private readonly _orderViewService: OrderViewService) { }

  public get surveyOptions$(): Observable<KeyValuePair<string>[]> {
    return this._orderViewService.loanData$.pipe(
      switchMap(loanData => this.surveyTypes$(loanData.transactionType))
    );
  }

  private surveyTypes$(transactionType: TransactionType): Observable<Array<KeyValuePair<string>>> {
    const options = [
      { key: "The Title Company", value: "The Title Company" },
      { key: "The Client", value: "The Client" },
      { key: "Existing Survey", value: "Existing Survey" }
    ];

    if (transactionType === TransactionType.Purchase) {
      options.push({ key: "The Seller", value: "The Seller" });
    }

    return of(options);
  }
}