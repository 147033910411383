import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';

@Pipe({
  name: 'orderStatus',
  pure: false
})
export class OrderStatusPipe implements PipeTransform {
  private readonly _mapOrderStatusDisplayName = new Map([
    [OrderStatus.Closing, 'Clear to Close'],
    [OrderStatus.Open, 'In Process...'],
  ]);

  transform(orderStatus: OrderStatus): string {
    if (!orderStatus) {
      return '';
    }

    return this.getOrderStatus(orderStatus);
  }

  private getOrderStatus(orderStatusId: number): string {
    if (this._mapOrderStatusDisplayName.has(orderStatusId)) {
      return this._mapOrderStatusDisplayName.get(orderStatusId);
    }

    return OrderStatus[orderStatusId];
  }
}
