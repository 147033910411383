import { Injectable } from '@angular/core';
import { UserProfile } from '@models/user-profile';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  public openFeedback(agentId: number): void {
    window.open(`https://quicken.co1.qualtrics.com/jfe/form/SV_bE3eBvXdRuyiptc/?AgentID=${agentId}`);
  }
}
