import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { States } from '@models/states';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  public readonly states$: Observable<Array<States>>;

  constructor(private readonly _httpHelper: HttpHelperService) { 
    this.states$ = this.getStates('USA');
  }

  private getStates(countryCode: string): Observable<Array<States>>{
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<Array<States>>('GET', `${environment.catchApiUrl}/states?countryCode=${countryCode}`)
      .pipe(
        map(states => states.sort((a, b) => this.alphabeticalSort(a, b))), 
        shareReplay(1));
  }

  private alphabeticalSort(a: States, b: States){
    if (a.state < b.state)
      return -1;
    if (a.state > b.state)
      return 1;
    return 0;
  }
}
