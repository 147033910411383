import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '@enums/contact-type-converter.enum';
import { Contact } from '../../../models/contact';

@Pipe({
  name: 'clientContact',
  pure: false
})
export class ClientContactPipe implements PipeTransform {
  transform(items: Contact[], filter = ((a: Contact) => a.contactType == ContactType.Client)): Array<Contact> {
    if (!items){
      return [];
    }

    return items.filter(item => filter(item));
  }
}
