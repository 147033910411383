import { Injectable } from '@angular/core';
import { ClosingType } from '@models/closing-type';
import { ClosingTypeMapping } from '@models/closing-type-mapping';
import { eClosingSearchResults } from '@models/eClosing-search-results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as enums from 'src/app/shared/enums/closing-type.enum';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ClosingTypeService {
  constructor(private readonly _httpHelper: HttpHelperService) { }

  private readonly _closingTypeServiceUrl: string = `${environment.catchApiUrl}`;

  public getClosingType$(orderId: number): Observable<ClosingType> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<ClosingType>('GET', `${this._closingTypeServiceUrl}/orders/${orderId}/closingType`);
  }

  public getClosingTypes$(orderIds: number[]): Observable<ClosingTypeMapping[]> {
    const url: string = `${this._closingTypeServiceUrl}/orders/closing-types?orderIds=${orderIds.join(',')}`;
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<ClosingType[]>('GET', url)
      .pipe(
        map(closingTypes => {
          const mappings: ClosingTypeMapping[] = [];
          closingTypes.forEach(closingType => {
            mappings.push({ orderId: closingType.orderId, closingType: enums.ClosingType[closingType.closingType] });
          });

          return mappings;
        })
      );
  }

  public mapClosingTypes(searchResults: eClosingSearchResults, typeMappings: ClosingTypeMapping[]): void {
    searchResults.orderDetails.forEach(order => {
      const typeMapping = typeMappings.find(closingType => closingType.orderId === order.orderId);

      if (typeMapping)
        order.closingType = typeMapping.closingType;
      else
        order.closingType = enums.ClosingType.Paper;
    });
  }

  public filterByClosingTypes(searchResults: eClosingSearchResults, closingTypes: enums.ClosingType[]): eClosingSearchResults {
    const filteredResults = new eClosingSearchResults();
    filteredResults.orderDetails = searchResults.orderDetails.filter(o => closingTypes.includes(o.closingType));
    return filteredResults;
  }
}
