import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanInfoSkeletonComponent } from './components/loan-info-skeleton/loan-info-skeleton.component';
import { OrderSideSkeletonComponent } from './components/order-side-skeleton/order-side-skeleton.component';
import { ToDoSkeletonComponent } from './components/to-do-skeleton/to-do-skeleton.component';
import { OrderViewSkeletonHeaderComponent } from './components/order-view-skeleton-header/order-view-skeleton-header.component';
import { MessagesSkeletonComponent } from './components/messages-skeleton/messages-skeleton.component';
import { FinalSignoffSkeletonComponent } from './components/final-signoff-skeleton/final-signoff-skeleton.component';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { SparkSkeletonModule } from '../sprk-skeleton/sprk-skeleton.module';

@NgModule({
  declarations: [
    LoanInfoSkeletonComponent,
    OrderSideSkeletonComponent,
    ToDoSkeletonComponent,
    OrderViewSkeletonHeaderComponent,
    MessagesSkeletonComponent,
    FinalSignoffSkeletonComponent
  ],
  imports: [
    CommonModule,
    SparkAngularModule,
    SparkSkeletonModule
  ],
  exports: [
    LoanInfoSkeletonComponent,
    OrderSideSkeletonComponent,
    ToDoSkeletonComponent,
    OrderViewSkeletonHeaderComponent,
    MessagesSkeletonComponent,
    FinalSignoffSkeletonComponent
  ]
})
export class SkeletonLoadersModule { }
