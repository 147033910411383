<ng-container *ngIf="ordersLoaded && dashboardVersion$ | async as version; else orderListSkeleton">
  <table
    id="activeOrders"
    class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray sprk-b-Table--borders"
    aria-label="activeOrders"
  >
    <thead sprkTableHead>
      <tr>
        <th id="agents" class="text-center" *ngIf="showSubscriberColumn">
          Subscribers
          <a id="infoIcon">
            <sprk-icon
              iconName="information"
              data-toggle="tooltip"
              data-placement="left"
              viewbox="0 0 32 32"
              additionalClasses="sprk-c-Icon--filled sprk-u-Color--blue sprk-c-Icon--stroke-current-color sprk-u-mbm"
              title="Subscribers to an order will receive email notifications"
            >
            </sprk-icon>
          </a>
        </th>
        <th id="loanNumber">Loan Number</th>
        <th id="borrowers">Borrowers</th>
        <th id="propertyAddress">Property Address</th>
        <ng-container *ngIf="showClosingTypeColumn">
          <th id="closingType">Closing Type</th>
        </ng-container>
        <th id="targetClosing">{{ closingColumnText }}</th>
        <th id="loanStatus">{{ loanStatusColumnText }}</th>
      </tr>
    </thead>
    <tbody
      *ngFor="let order of orders?.orderDetails; let i = index"
      [class]="{ 'row-clickable': clickableStatusIds.includes(order.orderStatusId) }"
    >
      <tr
        (click)="rowClick.emit(order)"
        [ngStyle]="{
          'border-width.px': getBorderWidth(order?.propertyInfo?.stateCode, order.orderStatusId)
        }"
      >
        <td class="text-center" (click)="$event.stopPropagation()" *ngIf="showSubscriberColumn">
          <ng-container *ngIf="subscribableStatusIds.includes(order.orderStatusId)">
            <catch-subscribe
              *ngIf="version === 2; else fallback"
              [order]="order"
              [userProfile]="userProfile"
              [subscriptions]="order.subscriptions"
              [showCount]="true"
            ></catch-subscribe>
            <ng-template #fallback>
              <app-subscribe
                [order]="order"
                [userProfile]="userProfile"
                [subscriptions]="order.subscriptions"
                [showCount]="true"
              ></app-subscribe>
            </ng-template>
          </ng-container>
        </td>
        <td [innerHtml]="order | highlight: order.highlight:orderLoanNumberPipe"></td>
        <td>
          <div *ngFor="let contact of order.contacts | clientContact | slice: 0:4">
            <span [innerHtml]="contact | highlight: order.highlight:contactNamePipe"></span>
          </div>
        </td>
        <td [innerHtml]="order.propertyInfo | highlight: order.highlight:addressPipe"></td>
        <td *ngIf="showClosingTypeColumn">
          {{ order.closingType }}
        </td>
        <td *ngIf="order.loanData?.scheduledClosingDate == null; else scheduledClosingDate">
          {{ order.loanData?.anticipatedClosingDate | date }}
        </td>
        <ng-template #scheduledClosingDate>
          <td>{{ order.loanData?.scheduledClosingDate | date }}</td>
        </ng-template>
        <td *ngIf="order.orderStatusId !== orderStatusEnum.New">
          {{ order.orderStatusId | orderStatus }}
        </td>
        <td *ngIf="order.orderStatusId === orderStatusEnum.New">
          <div class="d-flex">
            <div class="col">
              <button
                class="sprk-c-Button--compact sprk-c-Button--purple m-1"
                (click)="acceptClick.emit(order)"
                sprkButton
              >
                Accept
              </button>
              <button
                class="sprk-c-Button--compact sprk-c-Button--secondary m-1"
                variant="tertiary"
                (click)="declineClick.emit(order)"
                sprkButton
              >
                Decline
              </button>
            </div>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="showNotice(order?.propertyInfo?.stateCode, order?.orderStatusId)">
        <tr (click)="rowClick.emit(order)" class="notice">
          <td colspan="6" class="notice">
            <strong>{{ getNotice(order.propertyInfo.stateCode, order.orderStatusId) }}</strong>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tbody *ngIf="orders == null || orders?.orderDetails?.length < 1">
      <tr>
        <td colspan="6">There are currently no {{ orderType }}.</td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="showPagination">
    <sprk-pagination
      [additionalClasses]="'mt-3'"
      [currentPage]="currentPage"
      [totalItems]="orders?.totalOrderRecords"
      [itemsPerPage]="pageSize"
      idString="pagination-1"
      (nextClick)="changePage(currentPage + 1)"
      (previousClick)="changePage(currentPage - 1)"
      (pageClick)="changePage($event.page)"
    >
    </sprk-pagination>
  </ng-container>
</ng-container>

<ng-template #orderListSkeleton>
  <sprk-table-skeleton
    [rows]="5"
    [columns]="skeletonColumns"
    [appearance]="'line'"
    [theme]="skeletonSettings.themes.default"
  ></sprk-table-skeleton>
</ng-template>
