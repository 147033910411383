export enum OrderStatus {
    'Pending' = 1,
    'New' = 2,
    'Open' = 3,
    'Completed' = 4,
    'Cancelled' = 5,
    'Denied' = 6,
    'Archived' = 7,
    'Closing' = 8
}
