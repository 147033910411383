<main class="sprk-o-Box sprk-o-Box--large">
  <article>
    <header class="sprk-o-CenteredColumn sprk-u-phm">
      <h3>Rocket Mortgage Closing Portal Access and Usage Agreement</h3>
      <p *ngIf="userProfile?.isTermsAccepted == false" class="pt-2">Please read through the entire agreement before acknowledging below.</p>
    </header>
    <div class="d-flex justify-content-center"
         *ngIf="!isRendered">
      <div class="
          my-5
          sprk-o-Stack__item
          sprk-c-Spinner
          sprk-c-Spinner--circle
          sprk-c-Spinner--large
          sprk-c-Spinner--primary"></div>
    </div>
    <div class="sprk-o-CenteredColumn sprk-u-phm pdf py-4">
      <pdf-viewer [src]="termsOfUseSrc"
                  [(page)]="pagesRead"
                  [render-text]="true"
                  [fit-to-page]="true"
                  [original-size]="false"
                  [zoom]="1"
                  (after-load-complete)="onPdfLoad($event)"></pdf-viewer>
    </div>
    <div *ngIf="totalPages && userProfile?.isTermsAccepted == false"
         class="sprk-o-CenteredColumn sprk-u-phm d-flex flex-row flex-sm-column flex-md-row justify-content-between align-items-start">
      <div class="
          sprk-b-SelectionContainer
          sprk-b-Checkbox
          sprk-b-Checkbox--huge"
           data-sprk-input="checkbox">
        <input class="sprk-b-Checkbox__input"
               id="checkbox-1"
               [checked]="hasAcceptedAgreement"
               (change)="hasAcceptedAgreement = !hasAcceptedAgreement"
               data-id="checkbox-1"
               type="checkbox"
               aria-describedby="checkbox--error-container">
        <label for="checkbox-1"
               class="sprk-b-Label sprk-b-Label--inline sprk-b-Checkbox__label">
          Check here to indicate that you have read and agree to the terms of the Rocket Mortgage Access and Usage
          Agreement above.
        </label>
      </div>
      <div class="d-flex justify-content-end">
        <a class="sprk-c-Button sprk-c-Button--secondary mr-3" 
                download="AccessAndUsageAgreement" 
                href="{{termsOfUseSrc}}" 
                data-id="button-download">
          Download as PDF
        </a>
        <button class="sprk-c-Button" 
                (click)="acceptAgreement()" 
                data-id="button-primary"
                [disabled]="!canAcceptAgreement()">
          Continue
        </button>
      </div>
    </div>
  </article>
</main>
