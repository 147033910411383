import { Component, HostListener, Renderer2, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-tab-list',
  templateUrl: './tab-list.component.html'
})
export class TabListComponent implements AfterViewInit {
  @Input() isLocked = false;
  
  previousElement: EventTarget;
  readonly activeClass = "sprk-c-Tabs__button--active";

  @HostListener('click', ['$event.target'])
  public onClick(target: EventTarget): void {
    if (!this.isLocked) {
      if(this.previousElement !== target && this.previousElement != null) {
        this.renderer.removeClass(this.previousElement, this.activeClass);
      }
      this.renderer.addClass(target, this.activeClass)
      this.previousElement = target;
    }
  }

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    let initTab = document.getElementsByClassName(this.activeClass)[0];
    if(initTab != null) {
      this.previousElement = initTab;
    }
  }
}
