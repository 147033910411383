<div class="sprk-u-pvs sprk-u-phm order-list-item"
     [ngClass]="{ 'cursor-pointer': canClickOrder, 'cursor-not-allowed': !canClickOrder}"
     (click)="onOrderClicked()"
     [title]="orderStatus">
  <div sprkText
       variant="bodyOne">
    <ng-container *ngFor="let contact of orderDetails.contacts | clientContact | slice: 0:4">
      <span [innerHtml]="contact | highlight:orderDetails.highlight:contactNamePipe"></span>
    </ng-container>
  </div>
  <div>
    <div sprkText
         variant="bodyFour">
      <span class="property"
            [innerHtml]="orderDetails.propertyInfo | highlight:orderDetails.highlight:addressPipe"></span>
      <span>&middot;</span>
      <span [innerHtml]="orderDetails | highlight:orderDetails.highlight:orderLoanNumberPipe"></span>
    </div>
  </div>
</div>