import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/Auth0/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

  constructor(private readonly _authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.handleLoginAuthorization(state.url, +route.queryParamMap?.get("agencyId"));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleLoginAuthorization(state.url, +next.queryParamMap?.get("agencyId"));
  }

  private handleLoginAuthorization(url: string, agencyId: number) {
    return this._authService.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this._authService.login(url, agencyId);
        }
      })
    );
  }
}
