<ng-container *ngFor="let card of cards;">
  <sprk-card sprk
             additionalClasses="my-4">
    <sprk-stack sprkCardContent
                sprkStackItem
                itemSpacing="medium">
      <div class="sprk-c-Card__content">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [appearance]="settings.appearance"
                            [theme]="settings.themes.default"
                            [count]="lines"></sprk-skeleton-bone>
      </div>
    </sprk-stack>
  </sprk-card>
</ng-container>