import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentConfigurations } from '@models/document-configurations';
import { FileUploadRequest } from '@models/file-upload-request';
import { Milestone } from '@models/milestone';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import * as Mime from 'mime';
import { UploaderOptions, UploadOutput } from 'ngx-uploader';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';
import { DocumentMetaDataReference, DocumentUploadMetadata } from './../../../shared/modules/adobe-analytics/models/custom-events-metadata';
import { DocumentsService } from './documents.service';

@Injectable()
export class DocumentUploaderService {
  public dragOver: boolean;
  public options: UploaderOptions;
  public documentConfig: DocumentConfigurations;
  private _fileUploadRequests: Set<FileUploadRequest>;
  private _fileUploadRequests$$: BehaviorSubject<Set<FileUploadRequest>>;
  private _hasRejectedFiles$$: BehaviorSubject<boolean>;
  private _isFileBytesValid$$: BehaviorSubject<boolean>;
  private _hasProcessedRequests$$: Subject<boolean>;
  private _isUploading: boolean;

  public get fileUploadRequests$(): Observable<Set<FileUploadRequest>> {
    return this._fileUploadRequests$$.asObservable();
  }

  public get hasProcessedRequests$(): Observable<boolean> {
    return this._hasProcessedRequests$$.asObservable();
  }

  public get hasFileUploadRequests(): boolean {
    return this._fileUploadRequests.size > 0;
  }

  public get canUploadFiles(): boolean {
    for (const fileRequests of this._fileUploadRequests) {
      if (!fileRequests.milestones || fileRequests.milestones.length === 0) {
        return false;
      }
    }

    return true;
  }

  public get isUploading(): boolean {
    return this._isUploading;
  }

  public get hasRejectedFiles$(): Observable<boolean> {
    return this._hasRejectedFiles$$.asObservable();
  }

  public get _isValidBytes$(): Observable<boolean> {
    return this._isFileBytesValid$$.asObservable();
  }

  constructor(
    public readonly _documentsService: DocumentsService,
    private readonly _orderViewService: OrderViewService,
    private readonly _customEventsService: CustomEventsService) {
    this.options = {
      maxFileSize: 52428800,
      concurrency: 1,
      allowedContentTypes: ['application/pdf']
    };

    this._fileUploadRequests = new Set<FileUploadRequest>();
    this._fileUploadRequests$$ = new BehaviorSubject<Set<FileUploadRequest>>(this._fileUploadRequests);
    this._hasRejectedFiles$$ = new BehaviorSubject<boolean>(false);
    this._isFileBytesValid$$ = new BehaviorSubject<boolean>(false);
    this._hasProcessedRequests$$ = new Subject<boolean>();
    this._isUploading = false;
  }

  public onUploadOutput(output: UploadOutput, milestone?: Milestone): void {
    this._isFileBytesValid$$.next(true);
    this._orderViewService.orderId$.pipe(first()).subscribe(orderId => {
      const documentEvent = {
        order_id: orderId,
        upload_type: output.type,
        milestoneReferences: [{ milestoneDefinitionId: milestone?.milestoneDefinitionId, referenceId: milestone?.referenceId }],
        has_description: null,
        is_success: null
      } as DocumentUploadMetadata;

      this._customEventsService.pushDocumentUploadEvent(documentEvent);
    });

    switch (output.type) {
      case 'addedToQueue':
        if (output.file.name.length > this.documentConfig?.maximumFileNameLength) {
          this.rejectFile();
          break;
        }
        this.resetFileRejection();
        this.registerUploadRequest(output.file.nativeFile, milestone);
        break;
      case 'dragOver':
        this.setDragOver(true);
        break;
      case 'dragOut':
      case 'drop':
        this.setDragOver(false);
        break;
      case 'rejected':
        this.rejectFile();
        break;
    }
  }

  private readFileBytes(file: File): Promise<boolean> {
    const blob = file;
    let reader = new FileReader;
    reader.readAsBinaryString(blob);

    return new Promise((resolve) => {
      reader.onload = () => {
        const stringCoversion = reader.result.toString();
        let fileBytes = stringCoversion;

        try {
          if (stringCoversion.startsWith('----') == true) {
            const subStr = stringCoversion.substring(0, stringCoversion.indexOf("%"));
            const cleanBytes = stringCoversion.replace(subStr, '');

            fileBytes = cleanBytes;
          }

          const transformedFileString = Mime["getType"](fileBytes.slice(1, 4));

          this.documentConfig?.acceptedMimeTypes.includes(transformedFileString) ?
            resolve(true) : resolve(false);
        }
        catch {
          this._isFileBytesValid$$.next(false);
          resolve(false);
        }
      };
    });
  }

  private setDragOver(isDraggedOver: boolean): void {
    this.dragOver = isDraggedOver;
  }

  private registerUploadRequest(file: File, milestone?: Milestone): void {
    this.readFileBytes(file).then(isAcceptableFileType => {
      isAcceptableFileType ? this.add(file, milestone) : this.rejectFile();
    });
  }

  public add(file: File, milestone?: Milestone): FileUploadRequest {
    if (file) {
      const requestUploadFile = milestone
        ? new FileUploadRequest(file, milestone, [milestone])
        : new FileUploadRequest(file);
      requestUploadFile.isQueued = true;
      this._fileUploadRequests.add(requestUploadFile);
      this.emitFileUploadRequests();

      return requestUploadFile;
    }

    return null;
  }

  public resetFileRejection(): void {
    this._hasRejectedFiles$$.next(false);
  }

  public rejectFile(): void {
    this._hasRejectedFiles$$.next(true);
  }

  public update(fileUploadRequest: FileUploadRequest): void {
    this._fileUploadRequests.add(fileUploadRequest);
  }

  public remove(fileUploadRequest: FileUploadRequest): void {
    this._fileUploadRequests.delete(fileUploadRequest);
    this.emitFileUploadRequests();
  }

  public removeAllRequests(): void {
    this._fileUploadRequests.clear()
  }

  public removeProcessedRequests(): void {
    this._fileUploadRequests.forEach(fileUploadRequest => {
      if (fileUploadRequest.hasSuccessfulResponseCode) {
        this.remove(fileUploadRequest);
      }
    });
  }

  private emitFileUploadRequests(): void {
    this._fileUploadRequests$$.next(this._fileUploadRequests);
  }

  public uploadAll(orderId: number, loanNumber: string): Observable<any> {
    this._isUploading = true;

    try {
      return this.joinFileUploadRequests(orderId, loanNumber).pipe(map(() => {
        this._hasProcessedRequests$$.next(true);
        this._isUploading = false;
        this._orderViewService.refresh('documents');
      }),
        catchError(error => {
          this._hasProcessedRequests$$.next(false);
          this._isUploading = false;

          return of(error);
        }))
    } catch (error) {
      this._hasProcessedRequests$$.next(false);
      this._isUploading = false;

      return of(null);
    }
  }

  private joinFileUploadRequests(orderId: number, loanNumber: string): Observable<void[]> {
    const fileUploadRequestObservables = new Array<Observable<void>>();

    for (const fileUploadRequest of this._fileUploadRequests) {
      if (fileUploadRequest.isQueued) {
        fileUploadRequestObservables.push(this.processUploadRequest(fileUploadRequest, orderId, loanNumber));
      }
    }

    return forkJoin(fileUploadRequestObservables)
      .pipe(
        take(this._fileUploadRequests.size),
        catchError(error => {
          return of(error)
        }));
  }
  private updateFileUploadRequestByHttpEvent(requestUploadFile: FileUploadRequest, httpEvent: HttpEvent<any>) {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        requestUploadFile.isQueued = false;
        requestUploadFile.isUploading = true;
        break;
      case HttpEventType.UploadProgress:
        requestUploadFile.httpEventType = httpEvent.type;
        requestUploadFile.totalUploadSize = httpEvent.total;
        requestUploadFile.bytesUploaded = httpEvent.loaded;
        requestUploadFile.isUploading = true;
        requestUploadFile.isQueued = false;
        break;
      case HttpEventType.Response:
        requestUploadFile.httpEventType = httpEvent.type;
        requestUploadFile.httpResponseCode = httpEvent.status;
        requestUploadFile.isUploading = false;
        requestUploadFile.isQueued = false;
        break;
    }

    this.update(requestUploadFile);
  }

  private processUploadRequest(
    requestUploadFile: FileUploadRequest,
    orderId: number,
    loanNumber: string): Observable<void> {
    requestUploadFile.isQueued = false;
    requestUploadFile.isUploading = true;
    this.update(requestUploadFile);

    const _sub$$ = new Subject<void>();

    const milestoneDefinitionIds: Array<DocumentMetaDataReference> =
      requestUploadFile?.milestones?.map<DocumentMetaDataReference>(x => { return { milestoneDefinitionId: x.milestoneDefinitionId, referenceId: x.referenceId } });

    const uploadStart = new Date();

    return this._documentsService.getPreSignUrl$(orderId, loanNumber, requestUploadFile.file.name, requestUploadFile.documentDescription, milestoneDefinitionIds)
      .pipe(
        switchMap(documentData => this._documentsService.uploadFile$(documentData.presignedUrl, requestUploadFile.file)),
        map(httpEvent => {
          this.updateFileUploadRequestByHttpEvent(requestUploadFile, httpEvent);

          if (!requestUploadFile.isUploading && requestUploadFile.httpEventType === HttpEventType.Response) {
            _sub$$.next();
            _sub$$.complete();
            _sub$$.unsubscribe();
          }
        }),
        finalize(() => {
          const documentEvent = {
            order_id: orderId,
            upload_type: 'Upload',
            milestoneReferences: requestUploadFile.milestones.map(x => { return { milestoneDefinitionId: x.milestoneDefinitionId, referenceId: x.referenceId }; }),
            is_success: String(requestUploadFile.hasSuccessfulResponseCode),
            has_description: String(!!requestUploadFile.documentDescription),
            file_size: requestUploadFile.file.size.toString(),
            process_start: uploadStart.toString(),
            process_end: requestUploadFile.uploadDate.toString()
          } as DocumentUploadMetadata;

          this._customEventsService.pushDocumentUploadEvent(documentEvent)
        }),
        takeUntil(_sub$$),
        catchError(error => {
          requestUploadFile.httpEventType = HttpEventType.Response;
          requestUploadFile.httpResponseCode = 500;
          requestUploadFile.isUploading = false;
          requestUploadFile.isQueued = false;

          this.update(requestUploadFile);

          _sub$$.next();
          _sub$$.complete();
          _sub$$.unsubscribe();

          return of(error);
        })
      );
  }
}
