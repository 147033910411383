import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SprkCardSkeletonComponent } from './components/sprk-card-skeleton/sprk-card-skeleton.component';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { SprkTableSkeletonComponent } from './components/sprk-table-skeleton/sprk-table-skeleton.component';
import { SprkSkeletonBoneComponent } from './components/sprk-skeleton-bone/sprk-skeleton-bone';

@NgModule({
  declarations: [
    SprkTableSkeletonComponent,
    SprkSkeletonBoneComponent,
    SprkCardSkeletonComponent
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    SparkAngularModule
  ],
  exports: [
    SprkTableSkeletonComponent,
    SprkSkeletonBoneComponent,
    SprkCardSkeletonComponent
  ]
})
export class SparkSkeletonModule { }
