<div class="sprk-c-Card sprk-o-Stack slide--down p-4 background-color--white w-100"
     style="max-width: none;">
  <ng-container *ngIf="documentHistory; else skeleton">
    <ng-container *ngIf="!showError; else error">
      <sprk-table idString="table-2"
                  additionalTableClasses="sprk-b-Table--secondary sprk-b-Table--white-head"
                  aria-label="spark-table-2">
        <thead sprkTableHead>
          <tr>
            <th id="th_fileName">File Name</th>
            <th id="th_uploadDate">Upload Date</th>
            <th id="th_download"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of documentHistory">
            <td>
              <strong>{{doc.documentName}}</strong>
              <p>{{doc.documentDescription}}</p>
            </td>
            <td>{{doc.createdDate | localTime}}</td>
            <td class="text-right">
              <label [ngClass]="{'sprk-b-Link': !downloadInProgress}"
                     idString="default-link"
                     analyticsString="default-link"
                     (click)="!downloadInProgress && downloadDocument(doc)">
                {{downloadInProgress ? 'Downloading...' : 'Download'}}
              </label>
            </td>
          </tr>
        </tbody>
      </sprk-table>
    </ng-container>
    <ng-template #error>
      <app-no-history [milestoneTitle]="milestone.title"></app-no-history>
    </ng-template>
  </ng-container>
  <ng-template #skeleton>
    <sprk-table-skeleton [rows]="1"
                         [columns]="3"
                         [appearance]="'line'"
                         [theme]="skeletonSettings.themes.default"></sprk-table-skeleton>
  </ng-template>
</div>