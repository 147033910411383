import { Component } from '@angular/core';
import { OrderStatusService } from '@core/services/order-status/order-status.service';
import { CommonHighlightComponent } from '../common/common-highlight.component';

@Component({
  selector: 'app-address-highlight',
  templateUrl: './address-highlight.component.html'
})
export class AddressHighlightComponent extends CommonHighlightComponent {

  constructor(_orderStatusService: OrderStatusService) { super(_orderStatusService); }
}
