<rkt-masthead>
  <div
    rktMastheadBranding
    rktStackItem
    class="rkt-Masthead__branding rkt-Stack__item--center-column@1">
    <a (click)="onDashboardClick()">
      <img
        class="rkt-Masthead__logo"
        data-analytics-click="return-to-dash-logo"
        [src]="logoSrc"
        alt="Closing Portal By Rocket Mortgage Logo" />
    </a>
  </div>

  <div
    rktMastheadNavItem
    rktStackItem
    class="rkt-Masthead__nav-item rkt-Stack__item--center-column@1">
    <a
      class="rkt-Masthead__link"
      (click)="onAccountSelectionEvent(signOut.value)"
      >Sign Out</a>
  </div>

  <nav
    rktMastheadNavItems
    rktStackItem
    role="navigation"
    aria-label="Navigation Links"
    class="rkt-Masthead__nav-items rkt-Stack__item--flex@1 rkt-Stack rkt-Stack--space-4 rkt-Stack--split@1 rkt-Stack--end-row">
    <!-- <ul rktStackItem
      class="rkt-Masthead__site-links rkt-Spacing--mt0 rkt-Spacing--mb0 rkt-Spacing--pr24 rkt-Stack__item--center-column rkt-Stack rkt-Stack--split@1 rkt-Stack--space-4">
      <li rktStackItem class="rkt-Masthead__nav-bar-item" id="resource-center-link">
        <a mat-button class="rkt-Masthead__nav-link rkt-Spacing--pa0" [disableRipple]="true">Resource Center</a>
      </li>
    </ul> -->

    <ul
      rktStackItem
      class="rkt-Spacing--mt0 rkt-Spacing--mt0 rkt-Spacing--pl0 rkt-Stack--center-column rkt-ListStyle--none rkt-Stack rkt-Stack--space-4 rkt-Stack--split@1 rkt-Width--auto">
      <li rktStackItem class="rkt-Masthead__nav-bar-item">
        <button
          style="padding: 0;"
          mat-button
          class="rkt-Spacing--pa0 rkt-Masthead__nav-link"
          [disableRipple]="true"
          [matMenuTriggerFor]="menuItems"
          (menuOpened)="isOpen = true"
          (menuClosed)="isOpen = false">
          <span class="rkt-Spacing--mr8">{{ userProfile?.firstName }}</span>
          <mat-icon
            [svgIcon]="isOpen ? 'expand_less-outlined' : 'expand_more-outlined'"
          ></mat-icon>
        </button>

        <mat-menu
          #menuItems="matMenu"
          class="rkt-Menu rkt-Menu--nav"
          [hasBackdrop]="true">
          <ng-template ngFor let-selection [ngForOf]="accountSelection">
            <a
              class="rkt-Menu__item"
              style="text-decoration: none;"
              mat-menu-item
              (click)="onAccountSelectionEvent(selection.value)">
              <span class="rkt-Menu__item-text">{{ selection.text }}</span>
            </a>
          </ng-template>
        </mat-menu>
      </li>
    </ul>
  </nav>

  <nav
    rktMastheadNavCollapsible
    role="navigation"
    aria-label="Collapsible Navigation"
    idString="default-collapsible-nav">
    <mat-accordion class="rkt-Spacing--mv16 rkt-Display--block">
      <div
        class="rkt-AccordionPanel rkt-AccordionPanel--nav mat-expansion-panel rkt-Elevation-0">
        <div class="mat-expansion-panel-header">
          <a
            class="mat-expansion-panel-header-title rkt-TextDecoration--none"
            (click)="onAccountSelectionEvent(myAccount.value)">
            My Account
          </a>
        </div>
      </div>
    </mat-accordion>

    <div class="rkt-Spacing--pl16 rkt-Spacing--pr16">
      <button
        class="rkt-Button rkt-Button--secondary rkt-Button--large rkt-Width--100"
        mat-stroked-button
        color="accent"
        (click)="onAccountSelectionEvent(signOut.value)">
        Sign Out
      </button>
    </div>
  </nav>
</rkt-masthead>
