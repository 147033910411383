<modal-popup [header]="'User Survey Participation Request'" 
             [submitBtnTxt]="'Submit'" 
             [showClose]="false"
             [disableSubmit]="selection == null"
             (onConfirm)="submit()" *ngIf="userSurveyPreference">
    <ng-template [ngIf]="preferences" [ngIfElse]="loader">
        <div class="row pb-4">
            <div class="col-9 d-flex align-items-center">
                {{userSurveyPreference?.preferenceText}}
            </div>
            <div class="col-3">
                <sprk-radio-group>
                    <fieldset sprkFieldset>
                        <sprk-radio-item>
                            <input type="radio" [value]="'false'" name="choice" [(ngModel)]="selection" sprkRadioInput
                                #radioInput="ngModel" idString="radio-1" id="radio-1">
                            <label for="radio-1" sprkRadioLabel>
                                No
                            </label>
                            <input class="ml-3" type="radio" [value]="'true'" name="choice" [(ngModel)]="selection" sprkRadioInput
                                #radioInput="ngModel" idString="radio-2" id="radio-2">
                            <label for="radio-2" sprkRadioLabel>
                                Yes
                            </label>
                        </sprk-radio-item>
                    </fieldset>
                </sprk-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <em>If you choose to be a participant in our user surveys, you will be able to opt out at any time by
                    updating your Survey Preference on the My Account page in your User Preferences.</em>
            </div>
        </div>
    </ng-template>
    <ng-template #loader>
        <div class="d-flex justify-content-center">
            <div class="
          my-5
          sprk-o-Stack__item
          sprk-c-Spinner
          sprk-c-Spinner--circle
          sprk-c-Spinner--large
          sprk-c-Spinner--primary"></div>
        </div>
    </ng-template>
</modal-popup>