import { EnvironmentTypes } from './models/environment-types.enum';
import { PortalEnvironment } from './models/portal-environment';
import { IEnvironment } from './models/IEnvironment';

const PORTAL_ENVIRONMENT = new PortalEnvironment(EnvironmentTypes.test);

export const environment: IEnvironment = {
  production: false,
  catchApiUrl: PORTAL_ENVIRONMENT.catchApiUrl,
  eClosingUrl: "https://previewclose.eoriginal.com",
  authConfig: {
    auth0Domain: "sso.test.authrock.com",
    auth0ClientId: "YNz45vpM6alXjN0yPbBycmmyCfusdBj2",
    audience: "urn:ql-api:catch-api-203912:test"
  },
  splitConfig: {
    clientId: "bsu9ak8sh6a71cvrd0i3lvdvbov564ghtg6q"
  },
  adobeAnalyticsConfig: {
    scriptUrl: "//assets.adobedtm.com/b14636b10888/a7f35d584cc6/launch-f34f3a339e80-staging.min.js"
  },
  liveChatConfig: {
    sfdcInstanceUrl: "https://ql--qltest.my.salesforce.com",
    liveAgentSetupFlow: "https://qltest-rocketmortgage.cs196.force.com/liveAgentSetupFlow",
    orgId: "00D030000004dgH",
    sBaseLiveAgentContentURL: "https://c.la2-c1cs-ia4.salesforceliveagent.com/content",
    sDeploymentId: "5721J000000Cgxg",
    sButtonId: "573030000008OSP",
    sBaseLiveAgentURL: "https://d.la2-c1cs-ia4.salesforceliveagent.com/chat",
    sEswJsUrl: "https://ql--qltest.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I030000004CJwEAM_17b1bc1c735"
  }
};
