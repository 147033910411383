import { Component } from '@angular/core';
import { BaseLoaderComponent } from '../base-loader.component';

@Component({
  selector: 'app-messages-skeleton',
  templateUrl: './messages-skeleton.component.html'
})
export class MessagesSkeletonComponent extends BaseLoaderComponent {

  constructor() { super(); }

}
