import { Component, Input } from '@angular/core';
import { SprkSkeletonBaseComponent } from '../sprk-skeleton-base.component';

@Component({
  selector: 'sprk-card-skeleton',
  templateUrl: './sprk-card-skeleton.component.html',
  styleUrls: ['./sprk-card-skeleton.component.scss']
})
export class SprkCardSkeletonComponent extends SprkSkeletonBaseComponent {
  @Input() lines: number = 3;

  constructor() { super(); }

  get cards(): number[] {
    const arr: number[] = [];
    for (let x = 0; x < this.count; x++) {
      arr.push(x);
    }
    return arr;
  }
}