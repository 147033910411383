<div class="rkt-GlobalFooter">
    <footer class="rkt-CenteredColumn rkt-Spacing--pv16 rkt-Spacing--ph48" role="contentinfo" data-id="footer-2">
      <div class="rkt-Spacing--mv4 rkt-Spacing--pv4">
        <div class="text-center rkt-Spacing--mb8 rkt-Body-16 rkt-Color--white">© 2020-{{year}} {{nameSelector()}}, LLC All Rights Reserved</div>
        <div class="rkt-Spacing--mt3  align-self-center">
          <ul id="menu" class="rkt-ListStyle--none rkt-Spacing--ml0 rkt-Spacing--pl0 rkt-Spacing--mt0 rkt-Color--white rkt-Spacing--pr32 display-flex-center">
            <li class="rkt-Spacing--pr32">
              <a (click)="openContactUsModal()" rktLink [onDark]="true" variant="simple">Contact Us</a>
            </li>
            <li class="rkt-Spacing--pr32">
              <a (click)="openTermsModal()" rktLink [onDark]="true" variant="simple">Terms Of Use</a>
            </li>
            <li class="rkt-Spacing--pr32">
              <a id="privacy" (click)="openPrivacyPage()" rktLink [onDark]="true" variant="simple">Privacy Policy</a>
            </li>
            <li class="rkt-Spacing--pr32">
              <a (click)="openAccessUsageModal()" rktLink [onDark]="true" variant="simple">Access and Usage Agreement</a>
            </li>
            <li class="rkt-Spacing--pr32">
              <a (click)="onFeedbackClick()" rktLink [onDark]="true" variant="simple">Share Feedback</a>
            </li>
          </ul>
        </div>
      </div>
  
      <ng-template #usageModal>
        <modal-popup [header]="'Access and Usage Agreement'" (onConfirm)="bsModalRef.hide()">
          <app-pdf-viewer [src]="usageSrc"></app-pdf-viewer>
        </modal-popup>
      </ng-template>
  
      <ng-template #termsModal>
        <modal-popup [header]="'Terms of Use'" (onConfirm)="bsModalRef.hide()">
          <app-pdf-viewer [src]="termsSrc"></app-pdf-viewer>
        </modal-popup>
      </ng-template>
  
      <ng-template #contactUsModal>
        <modal-popup [header]="'Contact Us'" (onConfirm)="bsModalRef.hide()">
          <app-contact-us></app-contact-us>
        </modal-popup>
      </ng-template>
    </footer>
</div>