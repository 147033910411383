import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyHttpClientService {
  private _lazyHttpClient: HttpClient | undefined;

  constructor(private readonly _handler: HttpBackend) { }

  public getHttpClient(): HttpClient {
    if (this._lazyHttpClient) {
      return this._lazyHttpClient;
    }

    this._lazyHttpClient = new HttpClient(this._handler);

    return this._lazyHttpClient;
  }
}
