import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DocumentUploaderService } from '@core/services/documents/document-uploader.service';
import { DocumentsService } from '@core/services/documents/documents.service';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { Milestone } from '@models/milestone';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadInput } from 'ngx-uploader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html'
})
export class DropZoneComponent implements OnInit {

  @Input() orderId: number;
  @Input() milestone?: Milestone;
  @Input() canShowUploadResults ?= true;
  @Input() canUploadMultipleFiles ?= true;

  @ViewChild('docUploadAlertModal') docUploadAlertModal: TemplateRef<any>;

  public formData: FormData;
  public uploadInput: EventEmitter<UploadInput>;
  public faUpload = faUpload;
  public hasRejectedFiles = false;
  private _loanNumber: string;
  public bsModalRef: BsModalRef;

  private get documentConfigurations$(): Observable<void> {
    return this._documentsService.getDocumentConfigurations$().pipe(
      map(config => {
        this.documentUploaderService.options.allowedContentTypes = config.acceptedMimeTypes;
        this.documentUploaderService.options.maxFileSize = config.maximumFileSizeInBytes;
        this.documentUploaderService.documentConfig = config;
      })
    );
  }

  private get orderId$(): Observable<string> {
    return this._orderViewService.order$.pipe(
      map(order => this._loanNumber = order.loanNumber)
    );
  }

  public get showInValidBytesBanner(): boolean {
    let isValidBytesResult;

    this.documentUploaderService._isValidBytes$?.pipe(map(isValidBytes => {
      isValidBytesResult = isValidBytes;
    })).subscribe();

    return isValidBytesResult == false ? true : false;
  }

  constructor(
    public readonly documentUploaderService: DocumentUploaderService,
    private readonly _orderViewService: OrderViewService,
    private readonly _documentsService: DocumentsService,
    protected readonly _modalService: BsModalService
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  public ngOnInit(): void {
    if (!this.canUploadMultipleFiles) {
      this.documentUploaderService.options.maxUploads = 1;
    }

    this.orderId$.subscribe();
    this.documentConfigurations$.subscribe();
  }

  public get hasRequirements(): boolean {
    return !!this.orderId && !!this._loanNumber;
  }
}
