import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '@models/property';

@Pipe({
  name: 'propertyAddress'
})
export class PropertyAddressPipe implements PipeTransform {

  transform(property: Property): unknown {
    let output: string = '';
    if (property) {
      output += this.addAddressSection(property.streetAddress1, (property.streetAddress2 || property.city || property.stateCode || property.zipCode) ? ', ' : '');
      output += this.addAddressSection(property.streetAddress2, (property.city || property.stateCode || property.zipCode) ? ', ' : '');
      output += this.addAddressSection(property.city, (property.stateCode || property.zipCode) ? ', ' : '');
      output += this.addAddressSection(property.stateCode, (property.zipCode) ? ' ' : '');
      output += this.addAddressSection(property.zipCode, '');
    }
    return output;
  }

  private addAddressSection(section: string, suffix: string): string {
    if (!section) return '';
    return section.concat(suffix);
  }
}