import { Injectable } from '@angular/core';
import { LoanData } from '@models/loan-data';
import { Order } from '@models/order';
import { UserProfile } from '@models/user-profile';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './../../../core/services/user/user.service';
import { ChatBaseService } from './chat-base.service';
import { IChat } from './IChat';

@Injectable({
  providedIn: 'root'
})
export class SalesForceChatService extends ChatBaseService implements IChat {
  private _userProfile: UserProfile;
  private _instantiated: boolean = false;

  constructor(private readonly _userService: UserService) {
    super();
    this._chatServiceContainerClass = 'embeddedServiceHelpButton';
  }

  private readonly _scriptId: string = 'sfScript'
  private _embeddedService: any;

  instantiate(): void {
    this._userService.userProfile$.pipe(
      first(),
      map(profile => {
        if (!this._instantiated) {    
          this._userProfile = profile;
          this._embeddedService = window['embedded_svc'];
    
          this.createEmbedScriptTag(this.initEmbeddedService);
          this._instantiated = true;
        }
      })
    ).subscribe();
  }

  tearDown(): void {
    this.hideButton();

    if(this._embeddedService) {
      this._embeddedService.settings.extraPrechatFormDetails = this._embeddedService.settings.extraPrechatFormDetails.filter(x =>
        x.label !== `Loan Number` && x.label !== 'Agency Id');
  
      this._embeddedService.liveAgentAPI.endChat();
    }
  }

  configure(order: Order, loanData: LoanData): void {
    const agencyId = order.agencyId.toString();
    if (order.loanNumber) {
      this._embeddedService.settings.extraPrechatFormDetails.push({
        label: "Loan Number",
        value: order.loanNumber,
        transcriptFields: ["LoanNumber__c"],
      });
    }

    this._embeddedService.settings.extraPrechatFormDetails.push({
      label: "Agency Id",
      value: agencyId,
      transcriptFields: ["AgencyId__c"],
    });
  }

  private createEmbedScriptTag = (onScriptLoaded: {
    (): void;
  }): void => {
    if (!document.getElementById(this._scriptId)) {
      const embedSourceElement = document.createElement("script");
      embedSourceElement.id = this._scriptId;
      embedSourceElement.type = "text/javascript";

      embedSourceElement.src =
        "https://service.force.com/embeddedservice/5.0/esw.min.js";

      const head = document.getElementsByTagName('head')[0];
      head.appendChild(embedSourceElement);
      embedSourceElement.addEventListener('load', function () {
        onScriptLoaded();
      });
    }
  };

  private initEmbeddedService = (): void => {
    let bTxtAdded = false;

    this._embeddedService = window['embedded_svc'];

    this._embeddedService.settings.displayHelpButton = true; // Or false
    this._embeddedService.settings.language = "en-US"; // For example, enter 'en' or 'en-US'

    /***************************************************************************
    // This ONLY works by referencing files uploaded to the Static Resources
    // section within SalesForce itself. The name given the resource there
    // is what is use here.
    // The file should be saved with the alias in the form of: {company}{site}ChatCSS
    //// this._embeddedService.settings.externalStyles = [ `${this.company}${this.site}ChatCSS` ];
    ***************************************************************************/

    this._embeddedService.settings.defaultMinimizedText = "Chat Now";
    this._embeddedService.settings.disabledMinimizedText = "Chat Offline";

    this._embeddedService.settings.prechatBackgroundImgURL = "";

    if (this._userProfile.firstName && this._userProfile.lastName) {
      this._embeddedService.settings.prepopulatedPrechatFields = {
        FirstName: this._userProfile.firstName,
        LastName: this._userProfile.lastName,
      }; // Sets the auto-population of pre-chat form fields
    }

    this._embeddedService.settings.enabledFeatures = ["LiveAgent"];
    this._embeddedService.settings.entryFeature = "LiveAgent";

    // Passing Loan Number and RAID# to ChatTranscript object
    this._embeddedService.settings.extraPrechatFormDetails = [
      {
        label: "Origin",
        value: "Web Chat",
        displayToAgent: true,
      },
      {
        label: "Status",
        value: "In Progress",
        displayToAgent: true,
      },
    ];

    if (this._userProfile.firstName) {
      this._embeddedService.settings.extraPrechatFormDetails.push({
        label: "First Name",
        value: this._userProfile.firstName,
        transcriptFields: ["FirstName__c"],
      });
    }

    if (this._userProfile.lastName) {
      this._embeddedService.settings.extraPrechatFormDetails.push({
        label: "Last Name",
        value: this._userProfile.lastName,
        transcriptFields: ["LastName__c"],
      });
    }

    if (this._userProfile.agentId) {
      this._embeddedService.settings.extraPrechatFormDetails.push({
        label: "Agent Id",
        value: this._userProfile.agentId,
        transcriptFields: ["AgentId__c"],
      });
    }

    // Suppressing Contact Creation In Salesforce.
    this._embeddedService.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: false,
            fieldName: "LastName",
            isExactMatch: false,
            label: "Last Name",
          },
          {
            doCreate: false,
            doFind: false,
            fieldName: "FirstName",
            isExactMatch: false,
            label: "First Name",
          },
        ],
        entityName: "Contact",
      },
      {
        entityName: "Case",
        showOnCreate: true,
        saveToTranscript: "CaseId",
        entityFieldMaps: [
          {
            isExactMatch: false,
            fieldName: "Origin",
            doCreate: true,
            doFind: false,
            label: "Origin",
          },
          {
            isExactMatch: false,
            fieldName: "Status",
            doCreate: true,
            doFind: false,
            label: "Status",
          },
        ],
      },
    ];

    this._embeddedService.addEventHandler("afterMinimize", this.handlers.afterMinimized);

    this._embeddedService.addEventHandler('afterMaximize', this.handlers.afterMaximized);

    this._embeddedService.init(
      environment.liveChatConfig.sfdcInstanceUrl,
      environment.liveChatConfig.liveAgentSetupFlow,
      "https://service.force.com",
      environment.liveChatConfig.orgId,
      "Web_Chat_Closing_Portal",
      {
        baseLiveAgentContentURL:
          environment.liveChatConfig.sBaseLiveAgentContentURL,
        deploymentId: environment.liveChatConfig.sDeploymentId,
        buttonId: environment.liveChatConfig.sButtonId,
        baseLiveAgentURL: environment.liveChatConfig.sBaseLiveAgentURL,
        eswLiveAgentDevName: environment.liveChatConfig.eswLiveAgentDevName,
        isOfflineSupportEnabled: false,
      }
    );
  };

  public showButton(): void {
    this.changeButtonVisibility('visible', true);
  }

  public hideButton(): void {
    this.changeButtonVisibility('hidden', false);
  }

  private handlers = {
    afterMinimized: (data) => {
      let embeddedSvcSidebar = document.getElementsByClassName(
        "embeddedServiceSidebarMinimizedDefaultUI"
      ) as HTMLCollectionOf<HTMLElement>;
      embeddedSvcSidebar[0].style.borderRadius = "100px";

      let content = document.getElementsByClassName(
        "content"
      ) as HTMLCollectionOf<HTMLElement>;
      content[0].style.backgroundColor = "white";
    },
    afterMaximized: (data) => {
      let inputSplitName = document.getElementsByClassName(
        'inputSplitName'
      ) as HTMLCollectionOf<HTMLElement>;
      if (inputSplitName[0] !== undefined) {
        inputSplitName[0].style.display = 'none';
        inputSplitName[1].style.display = 'none';
      }

      let emailInput = document.getElementsByClassName(
        "uiInputEmail"
      ) as HTMLCollectionOf<HTMLElement>;
      if (emailInput[0] !== undefined) {
        emailInput[0].style.display = "none";
      }

      let myImg = document.getElementsByClassName('embeddedServiceSidebarFormField')[0];
      let existingGreetingNode = document.getElementsByClassName('greetingTextStyle')[0];
      if (myImg !== undefined && existingGreetingNode === undefined) {
        let greetingText = document.createTextNode('Hi! 👋');
        let greetingNode = document.createElement("div");
        greetingNode.className = "greetingTextStyle";
        greetingNode.appendChild(greetingText);
        greetingNode.appendChild(document.createElement("br"));

        let startChatText = document.createTextNode("Start a chat with a Rocket Mortgage team member here.")
        greetingNode.appendChild(startChatText);
        greetingNode.appendChild(document.createElement("br"));

        myImg.parentNode.insertBefore(greetingNode, myImg.nextSibling);

        let helpTextContentNode = document.createElement("p");
        helpTextContentNode.className = "helpTextContentStyle";
        let helpText = document.createTextNode('What can we help you with?');
        helpTextContentNode.appendChild(helpText);
        let customLblElement = document.getElementsByClassName('greetingTextStyle')[0];
        customLblElement.parentNode.insertBefore(helpTextContentNode, customLblElement.nextSibling);
      }

      const input = document.getElementById('Subject');
      input.focus();
    }
  }
}