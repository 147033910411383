import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from '@core/services/Auth0/auth.service';
import { PendoService } from '@shared/modules/pendo/services/pendo.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private readonly _authService: AuthService,
    private readonly _pendoService: PendoService) { }

  ngOnInit(): void {
    this._pendoService.clearSession();
    this._authService.logout();
  }
}
