import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionError, SubscriptionResponse } from '@models/subscription-response';
import { OrderSubscription } from '@models/subscription';
import { UserProfile } from '@models/user-profile';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderService } from '../order/order.service';
import { Order } from '@models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderSubscriptionService {

  private readonly _alreadySubscribedMessage: string = 'You are currently subscribed to this order.'
  private readonly _primaryContactWarningMessage: string = 'You are currently the primary contact on this order. Please choose a new primary contact before you unfollow this order.';
  private readonly _subscriptionMessageTitle: string = 'Subscription Message';
  private readonly _ssoErrorMessage: string = 'You cannot subscribe/unsubscribe to this order as an SSO user';
  private readonly _unexpectedErrorTitle: string = 'Unexpected Error';
  private _subscriptions: Map<number, Array<OrderSubscription>> = new Map<number, Array<OrderSubscription>>();

  constructor(private readonly _httpClient: HttpClient,
    private readonly _orderService: OrderService) {
  }

  public subscribeToOrder(orderId: number, subscriptions: Array<OrderSubscription>): Observable<any> {
    return this._httpClient.put(`${environment.catchApiUrl}/orders/${orderId}/subscriptions`, subscriptions);
  }

  public getOrderSubscriptions(orderId: number): Observable<Array<OrderSubscription>> {
    if (this._subscriptions.has(orderId) && this._subscriptions.get(orderId) !== null)
      return of(this._subscriptions.get(orderId));

    return this._httpClient.get<Array<OrderSubscription>>(`${environment.catchApiUrl}/orders/${orderId}/subscriptions`)
      .pipe(
        map(subscriptions => {
          this._subscriptions.set(orderId, subscriptions);
          return subscriptions;
        })
      );
  }

  public setOrderSubscriptions(orderId: number, subscriptions: Array<OrderSubscription>): void {
    this._subscriptions.set(orderId, subscriptions);
  }

  public canSubscribeToOrder(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<boolean> {
    if (isSsoUser) return of(false);

    return this.isSubscribedToOrder(orderId, userProfile)
      .pipe(
        map(isSubscribed => !isSubscribed));
  }

  public canUnsubscribeFromOrder(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<boolean> {
    if (isSsoUser) return of(false);

    return combineLatest([
      this.getOrderSubscriptions(orderId),
      this.isSubscribedToOrder(orderId, userProfile),
      this.isPrimaryAgentOnOrderByOrderId(orderId, userProfile)
    ])
      .pipe(
        map(([subscriptions, isSubscribed, isPrimary]) => {
          return isSubscribed && subscriptions.length > 1 && !isPrimary;
        })
      );
  }

  public isSubscribed(orderId: number, userProfile: UserProfile): boolean {
    if (!this._subscriptions.has(orderId))
      return false;

    return this._subscriptions.get(orderId).findIndex(s => s.agentId === userProfile.agentId) >= 0;
  }

  public isSubscribedToOrder(orderId: number, userProfile: UserProfile): Observable<boolean> {
    return this.getOrderSubscriptions(orderId)
      .pipe(
        switchMap(subscriptions => of(subscriptions.findIndex(sub => sub?.agentId === userProfile?.agentId) >= 0)));
  }

  public addSubscriptionToOrder(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<SubscriptionResponse> {
    const response = new SubscriptionResponse();

    return this.validateSubscription(orderId, userProfile, isSsoUser)
      .pipe(
        switchMap(_ => {
          return this.getOrderSubscriptions(orderId)
            .pipe(
              switchMap(subscriptions => {
                const newSubscriptions = this.prepareForSaving(subscriptions, userProfile, true);

                return this.subscribeToOrder(orderId, newSubscriptions.newList)
                  .pipe(
                    map(_ => newSubscriptions.newSubscription));
              }),
              map(subscribeResponse => {
                response.error = null;
                response.value = subscribeResponse;
                this._subscriptions.get(orderId).push(subscribeResponse);
                return response;
              }),
              catchError(err => this.createErrorResponse(err, userProfile, false)));
        }),
        catchError(err => this.createErrorResponse(err, userProfile, false)));
  }

  public removeSubscriptionFromOrder(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<SubscriptionResponse> {
    const response: SubscriptionResponse = new SubscriptionResponse();

    return this.validateUnsubscribe(orderId, userProfile, isSsoUser)
      .pipe(
        switchMap(_ => {
          return this.getOrderSubscriptions(orderId)
            .pipe(
              switchMap(subscriptions => {
                const foundSubscrition = subscriptions.find(s => s.agentId === userProfile.agentId);
                foundSubscrition.isActive = false;

                return this.subscribeToOrder(orderId, subscriptions)
                  .pipe(
                    map(_ => foundSubscrition)
                  );
              }),
              map(subscription => {
                response.error = null;
                response.value = subscription;
                const subs = this._subscriptions.get(orderId);
                const index = subs.findIndex(s => s.agentId === subscription.agentId);
                this._subscriptions.get(orderId).splice(index, 1);

                return response;
              }),
              catchError(err => this.createErrorResponse(err, userProfile, true)));
        }),
        catchError(err => this.createErrorResponse(err, userProfile, true)));
  }

  public isPrimaryAgentOnOrderByOrderId(orderId: number, userProfile: UserProfile): Observable<boolean> {
    return this._orderService.getOrder$(orderId)
      .pipe(
        map(order => {
          return order.agentId === userProfile.agentId;
        }));
  }

  public isPrimaryAgentOnOrder(order: Order, userProfile: UserProfile): boolean {
    return order.agentId === userProfile.agentId;
  }

  public clearCache(): void {
    this._subscriptions.clear();
  }

  public clearCachedOrder(orderId: number): void {
    this._subscriptions.delete(orderId);
  }

  private prepareForSaving(subscriptions: Array<OrderSubscription>, userProfile: UserProfile, activate: boolean): { newSubscription: OrderSubscription, newList: Array<OrderSubscription> } {
    const preparedList = Object.assign([], subscriptions);
    const subscription: OrderSubscription = this.createSubscriptionFromProfile(userProfile, activate);
    preparedList.push(subscription);

    return { newSubscription: subscription, newList: preparedList };
  }

  private createSubscriptionFromProfile(userProfile: UserProfile, isActive: boolean): OrderSubscription {
    return {
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      agentId: userProfile.agentId,
      isActive: isActive
    }
  }

  private createErrorResponse(err: any, userProfile: UserProfile, isActive: boolean): Observable<SubscriptionResponse> {
    const error = new SubscriptionError(err.message, err.title ?? this._unexpectedErrorTitle);
    return of({ error: error, value: this.createSubscriptionFromProfile(userProfile, isActive) });
  }

  private validateSubscription(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<void> {
    if (isSsoUser) {
      return throwError(this.ssoError);
    }
    else {
      return this.canSubscribeToOrder(orderId, userProfile, isSsoUser)
        .pipe(
          switchMap(canSubscribe => {
            if (!canSubscribe)
              return throwError(this.alreadySubscribedError);

            return of(null);
          }));
    }
  }

  private validateUnsubscribe(orderId: number, userProfile: UserProfile, isSsoUser: boolean): Observable<void> {

    if (isSsoUser) {
      return throwError(this.ssoError);
    }

    return this.isPrimaryAgentOnOrderByOrderId(orderId, userProfile)
      .pipe(
        switchMap(isPrimary => {
          if (isPrimary)
            return throwError(this.primaryContactError);

          return of(null);
        }))
  }

  private get ssoError(): SubscriptionError {
    return new SubscriptionError(this._ssoErrorMessage, this._subscriptionMessageTitle);
  }

  private get primaryContactError(): SubscriptionError {
    return new SubscriptionError(this._primaryContactWarningMessage, this._subscriptionMessageTitle);
  }

  private get alreadySubscribedError(): SubscriptionError {
    return new SubscriptionError(this._alreadySubscribedMessage, this._subscriptionMessageTitle);
  }

}
