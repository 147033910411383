import { Injectable } from '@angular/core';
import { SessionStorageKey } from '@enums/session-storage-key.enum';
import { Agency } from '@models/agency';
import { Agent } from '@models/agent';
import { AgentPreference } from '@models/agentPreference';
import { Preference } from '@models/preference';
import { AssociatedAgency, UserProfile } from '@models/user-profile';
import { WireInformation } from '@models/wire-information';
import { IAccount } from '@shared/modules/pendo/models/pendo-meta-data';
import { PendoService } from '@shared/modules/pendo/services/pendo.service';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, first, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../Auth0/auth.service';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly _agentPreferences$$: ReplaySubject<Array<AgentPreference>> = new ReplaySubject<Array<AgentPreference>>(1);
  private readonly _preferences$$: ReplaySubject<Array<Preference>> = new ReplaySubject<Array<Preference>>(1);
  private _userProfile$: Observable<UserProfile>;
  private _userProfileListener$$: BehaviorSubject<UserProfile>;
  private _userAgencies$: BehaviorSubject<Array<AssociatedAgency>> = new BehaviorSubject<Array<AssociatedAgency>>([]);
  private readonly _userProfileReloader$$ = new Subject<void>();

  constructor(
    private readonly _httpHelper: HttpHelperService,
    private readonly _authService: AuthService,
    private readonly _pendoService: PendoService) { }

  public get userProfile$(): Observable<UserProfile> {
    const userProfile$ = this._authService.isSSOUser$.pipe(
      distinctUntilChanged(),
      switchMap(isSSOUser => this.getUserProfile(isSSOUser)),
      shareReplay(1)
    );

    return this.configureCache('userProfile', userProfile$);
  }

  public get agentPreferences$$(): ReplaySubject<Array<AgentPreference>> {
    this._authService.isSSOUser$
      .pipe(take(1))
      .subscribe(isSSOUser => {
        if (!isSSOUser) {
          this._httpHelper
            .sendHttpRequestWithRetryStrategy$<Array<AgentPreference>>('GET', `${environment.catchApiUrl}/agents/agent-preferences`)
            .pipe(
              map(
                agentPrefs => this._agentPreferences$$.next(agentPrefs)),
              first()
            ).subscribe();
        }
        else {
          this._agentPreferences$$.next([]);
        }
      });
    return this._agentPreferences$$;
  }

  public get preferences$$(): ReplaySubject<Array<Preference>> {
    this._httpHelper
      .sendHttpRequestWithRetryStrategy$<Array<Preference>>('GET', `${environment.catchApiUrl}/agents/preferences`)
      .pipe(
        map(prefs =>
          this._preferences$$.next(prefs)),
        first()
      ).subscribe();

    return this._preferences$$;
  }

  public get targetAgencyId(): number {
    const targetAgencyId = +sessionStorage.getItem(SessionStorageKey.TargetAgency);

    if (targetAgencyId && !Number.isNaN(targetAgencyId)) {
      return targetAgencyId;
    }

    sessionStorage.removeItem(SessionStorageKey.TargetAgency);
    return null;
  }

  public set targetAgencyId(agencyId: number) {
    if (!!agencyId && !Number.isNaN(agencyId)) {
      sessionStorage.setItem(SessionStorageKey.TargetAgency, agencyId.toString())
    }
  }

  public get associatedAgencies$(): Observable<Array<AssociatedAgency>> {
    return this._userAgencies$;
  }

  public changeAgency$(agencyId: number): Observable<void> {
    this.targetAgencyId = agencyId;
    return this.getAgency$(agencyId)
      .pipe(
        map(agency => {
          const account: IAccount = {
            id: agency.agencyId.toString(),
            name: agency.agencyName,
            location: `${agency.city}, ${agency.stateCode}`
          };

          this._pendoService.changeAccount(account);
        })
      );
  }

  public saveAgentPreferences(preferences: AgentPreference[]): Observable<void> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$(
      'PUT',
      `${environment.catchApiUrl}/agents/agent-preferences`,
      { body: preferences })
      .pipe(map(_ => {
        this._agentPreferences$$.next(preferences);
      }));
  }

  public acceptTerms(): void {
    this._userProfileListener$$.value.isTermsAccepted = true;
    this._userProfileListener$$.next(this._userProfileListener$$.value);
  }

  public updateProfile(func: (userProfile: UserProfile) => void): void {
    func(this._userProfileListener$$.value);
    this._userProfileListener$$.next(this._userProfileListener$$.value);
  }

  public getUserProfile(isSSOUser: boolean): Observable<UserProfile> {
    if (isSSOUser) {
      const ssoAgencyId = Number(sessionStorage.getItem(SessionStorageKey.AdminAgency));

      return this.getSSOAccount$(ssoAgencyId).pipe(
        map(userProfile => {
          this.configureAgencyIdSelection(userProfile);
          this._userAgencies$.next(userProfile.associatedAgencies);
          return userProfile;
        }),
        shareReplay(1)
      );
    }

    return this._httpHelper
      .sendHttpRequestWithRetryStrategy$<UserProfile>('GET', `${environment.catchApiUrl}/agents/me`)
      .pipe(
        map(userProfile => {
          this.configureAgencyIdSelection(userProfile);
          this._userAgencies$.next(userProfile.associatedAgencies);
          return userProfile;
        }),
        first(),
        shareReplay(1));
  }

  public get requestPasswordResetTicket$(): Observable<any> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('POST', `${environment.catchApiUrl}/agents/me/reset-ticket`);
  }

  public updateAgent$(agentId: number, agent: Agent | Object): Observable<void> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$(
      'PATCH',
      `${environment.catchApiUrl}/agents/${agentId}`,
      { body: agent });
  }

  public getAgents$(agencyId: number): Observable<Array<Agent>> {
    return this._httpHelper
      .sendHttpRequestWithRetryStrategy$<Array<Agent>>('GET', `${environment.catchApiUrl}/agencies/${agencyId}/agents`);
  }

  public getFilteredAgents$(agencyId: number, func?: (agents: Agent) => boolean): Observable<Array<Agent>> {
    return this.getAgents$(agencyId).pipe(map(agents => agents.filter(func)));
  }

  public getAgency$(agencyId: number): Observable<Agency> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<Agency>('GET', `${environment.catchApiUrl}/agencies/${agencyId}`);
  }

  public updateAgency$(agency: Agency): Observable<void> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$(
      'PATCH',
      `${environment.catchApiUrl}/agencies/${agency.agencyId}`,
      { body: agency });
  }

  public getWireInformation$(agencyId: number): Observable<WireInformation> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<WireInformation>('GET', `${environment.catchApiUrl}/agencies/${agencyId}/wire-information`);
  }

  public updateWireInformation$(agencyId: number, wireInformation: WireInformation): Observable<void> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$(
      'PUT',
      `${environment.catchApiUrl}/agencies/${agencyId}/wire-information`,
      { body: wireInformation });
  }

  private configureAgencyIdSelection(userProfile: UserProfile): void {
    const targetAgencyId = +sessionStorage.getItem(SessionStorageKey.TargetAgency)

    if (!targetAgencyId && !Number.isNaN(targetAgencyId) && userProfile?.associatedAgencies?.length > 0) {
      const selectedAgencyId = userProfile.associatedAgencies.sort((a, b) => a.agencyId - b.agencyId)[0];

      sessionStorage.setItem(SessionStorageKey.TargetAgency, selectedAgencyId?.agencyId?.toString());
    }
  }

  private getSSOAccount$(adminAgencyId: number): Observable<UserProfile> {
    return this.getAgency$(adminAgencyId).pipe(
      switchMap(agency => this._authService.userProfile$.pipe(
        switchMap(profile => {
          profile.associatedAgencies = [
            {
              agencyId: agency?.agencyId,
              name: agency?.agencyName
            }
          ] as AssociatedAgency[];

          return of(profile);
        }),
        shareReplay(1))))
  }

  private configureCache<T>(
    data: string,
    func: Observable<T>): Observable<T> {
    if (!this[`_${data}\$`]) {
      this[`_${data}\$`] = func;

      this[`_${data}\$`].subscribe(x => {
        if (this[`_${data}Listener\$\$`]) {
          this[`_${data}Listener\$\$`].next(x);
        } else {
          this[`_${data}Listener\$\$`] = new BehaviorSubject<T>(x)
        }
      });
    }

    return this[`_${data}Listener\$\$`] ?? this[`_${data}\$`];
  }
}
