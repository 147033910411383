import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user/user.service';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { UserProfile } from '@models/user-profile';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';

@Component({
  selector: 'app-usage-agreement',
  templateUrl: './usage-agreement.component.html',
  styleUrls: ['./usage-agreement.component.scss']
})
export class UsageAgreementComponent extends OnDestroySubscriptionResolver implements OnInit {
  public termsOfUseSrc: string;
  public userProfile: UserProfile;
  public isRendered = false;
  public pagesRead = 0;
  public totalPages;
  public hasAcceptedAgreement = false;

  constructor(
    private readonly _userService: UserService,
    private readonly _toasterService: ToastrService,
    private readonly _router: Router) { super(); }

  ngOnInit() {
    this.termsOfUseSrc = `${window.location.origin}/assets/files/legal/AccessAndUsageAgreement.v1.5.pdf`;
    this._userService.userProfile$
      .pipe(
        map(userProfile => this.userProfile = userProfile),
        takeUntil(this._unsubscribe$$)
      ).subscribe();
  }

  public onPdfLoad(pdf: PDFDocumentProxy): void {
    this.isRendered = true;
    this.totalPages = pdf.numPages;
  }

  public acceptAgreement(): void {
    const updatedAgentTerms = {
      agentId: this.userProfile.agentId,
      isTermsAccepted: 1
    };

    this._userService.updateAgent$(this.userProfile.agentId, updatedAgentTerms)
      .pipe(
        map(_ => this._userService.acceptTerms()),
        map(_ => this._router.navigateByUrl(window?.history?.state?.redirect ?? `/${ApplicationPaths.Dashboard}`)),
        first(),
        catchError(() => {
          this._toasterService.error('Something Went Wrong');
          return of(undefined);
        }))
      .subscribe();
  }

  public canAcceptAgreement(): boolean {
    return this.pagesRead == this.totalPages && this.hasAcceptedAgreement;
  }
}
