export class PendoMetaData {
  visitor: IVisitor;
  account: IAccount;
}

export interface IVisitor {
  id: string;
  full_name: string;
  email: string;
}

export interface IAccount {
  id: string;
  name: string;
  location: string;
}