<select class="sprk-b-Select mt-2" id="select-normal-1"
        [(ngModel)]="selectedDocumentId" 
        (change)="selectChangeHandler(selectedDocumentId)"
        aria-describedby="select-normal--error-container"
        data-id="select-1" sprkInput>
        <option [value]=0 disabled hidden>Select a document to download</option>
        <option *ngFor="let document of documents"
        [value]=document.documentId>{{document.documentName}}</option>        
</select>
<sprk-icon
        iconType="chevron-down"
        additionalClasses="sprk-c-Icon--filled-current-color sprk-c-Icon--stroke-current-color sprk-b-SelectContainer__icon"
        sprk-select-icon>
</sprk-icon>
