<header class="rkt-Display--flex mt-3 mx-3">
  <h2 class="rkt-Heading-20 rkt-FontWeight--500 rkt-Display--flex-1" mat-dialog-title>
    Accept Order
  </h2>
  <button
    class="rkt-ButtonReset rkt-Dialog__dismiss-button"
    type="button"
    title="Dismiss"
    mat-dialog-close
    (click)="cancel()"
  >
    <mat-icon color="accent" svgIcon="close-outlined" aria-hidden="true"></mat-icon>
  </button>
</header>
<mat-dialog-content class="rkt-Display--flex mx-3">
  <rkt-stack splitAt="2">
    <div rktStackItem class="rkt-Stack__item--half@2">
      <h6 class="mb-2">
        <mat-icon
          class="rkt-Icon"
          aria-hidden="false"
          aria-label="Subscribers Icon"
          svgIcon="notifications-outlined"
          color="accent"
        ></mat-icon>
        Add more Agents to the Order:</h6>
      <mat-form-field appearance="fill" color="accent" class="rkt-FormField width-80-percent" *ngIf="showDropdown">
        <mat-label>Agents</mat-label>
        <mat-select class="rkt-Input" [(value)]="selectedAgents" multiple>
          <mat-option *ngFor="let agent of agents" [value]="agent">
            {{ agent.agentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mt-5" *ngIf="!showDropdown">
        <h3 class="text-center" al sprkHeading variant="displayFive">Loading Agents...</h3>
      </div>
      <div *ngIf="selectedAgents.length < 1" class="mb-1">
        <mat-error alertType="fail" idString="alert-info-1" analyticsString="test">
          At least One Agent Should be Selected
        </mat-error>
      </div>
    </div>
    <div rktStackItem class="rkt-Stack__item--half@2">
      <div class="pb-3" *ngIf="!!agency">
        <h6 class="mb-2">Assigned Agency:</h6>
        <div class="ml-3">
          <h5>{{ agency.agencyName }}</h5>
          <p>{{ agency | agencyAddressV2 }}</p>
        </div>
      </div>
      <div *ngIf="showDropdown">
        <h6 class="mb-2">Assigned Agents:</h6>
        <ul class="data-list ml-3">
          <p *ngFor="let agent of selectedAgents">{{ agent.agentName }}</p>
        </ul>
      </div>
    </div>
  </rkt-stack>
</mat-dialog-content>
<rkt-stack spacing="16" additionalClasses="rkt-Stack--center-column rkt-Stack--end-row" splitAt="1">
  <button
    rktStackItem
    mat-button
    class="rkt-Button rkt-Button--secondary rkt-Button--large"
    color="accent"
    mat-dialog-close
    (click)="cancel()"
  >
    Cancel
  </button>

  <button
    rktStackItem
    mat-flat-button
    [disabled]="selectedAgents.length < 1"
    class="rkt-Button rkt-Button--large"
    color="primary"
    mat-dialog-close
    (click)="submit()"
  >
    Accept
  </button>
</rkt-stack>
<div class="mt-2">
  <strong>{{ getNotice(orderDetail?.propertyInfo?.stateCode) }}</strong>
</div>
