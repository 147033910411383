import { MilestoneStatus } from "@enums/milestone-status.enum";
import { OrderStatus } from "@enums/order-status.enum";

export interface PageLoadMetadata extends AgentMetadata {
  page_name: string;
  qls?: string;
  order_id?: number;
  order_status?: OrderStatus
}

export interface DocumentUploadMetadata extends AgentMetadata {
  order_id: number;
  milestoneReferences?: DocumentMetaDataReference[];
  upload_type?: string;
  has_description?: string;
  process_start?: string;
  process_end?: string;
  file_size?: string;
  is_success?: string;
  referenceId?: string;
}

export interface DocumentMetaDataReference {
  milestoneDefinitionId: number,
  referenceId: string
}

export interface UpdatePrimaryAgentMetadata extends AgentMetadata {
  order_id: number;
  updated_agent_id?: number;
}

export interface ChangeAgencyViewMetadata extends AgentMetadata {
  page_name?: string;
}

export interface DataToDoMilestoneMetadata extends AgentMetadata {
  order_id: number;
  todo_name: string;
  todo_status: MilestoneStatus | DocumentDownloadStatus;
  order_status: OrderStatus;
}

export interface OrderAccessedMetadata extends AgentMetadata {
  access_location: string;
  order_status: OrderStatus;
}

export interface SearchEventMetadata extends AgentMetadata {
  search_characters: SearchCharactersType
}

export interface MessageFilterEventMetadata extends AgentMetadata {
  order_status: OrderStatus;
  filter_category: string;
}

export interface FollowersEventMetadata extends AgentMetadata {
  count: number;
}

export interface AccountUpdatesMetadata extends AgentMetadata {
  type: AccountUpdatesType;
}

export interface CustomEvent {
  event: string;
  metadata: PageLoadMetadata | UpdatePrimaryAgentMetadata | ChangeAgencyViewMetadata | DataToDoMilestoneMetadata | OrderAccessedMetadata | SearchEventMetadata | MessageFilterEventMetadata | FollowersEventMetadata | AccountUpdatesMetadata | QlsEvent
}

export interface QlsEvent extends AgentMetadata {
  page_name: string;
  qls_string: string;
}

export interface SalesForceEvent extends AgentMetadata {
  order_id: number;
}

export enum DocumentDownloadStatus {
  Failed = "FAILED",
  Success = "SUCCESS"
}

export enum SearchCharactersType {
  AlphaNumeric = "alphanumeric",
  Alpha = "alpha",
  Numeric = "numeric",
  SpecialCharacter = "special-character",
  Unknown = "unknown"
}

export enum FollowEventLocation {
  Acceptance = "ON_ACCEPTANCE",
  ActiveOrder = "ON_ACTIVE_ORDER"
}

export enum AccountUpdatesType {
  WireInstructionInformation = "wire-instruction-information",
  AgentStateLicenseId = "agent-state-license-id",
  AgencyStateLicenseId = "agency-state-license-id"
}

interface AgentMetadata {
  agent_id?: number;
  agency_id?: number;
}