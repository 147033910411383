import { Injectable } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';
import { LoanData } from '@models/loan-data';
import { Order } from '@models/order';
import { UserProfile } from '@models/user-profile';
import { first, map } from 'rxjs/operators';
import { UserService } from './../../../core/services/user/user.service';
import { ChatBaseService } from './chat-base.service';
import { IChat } from './IChat';

@Injectable({
  providedIn: 'root'
})
export class LivePersonChatService extends ChatBaseService implements IChat { 
  private _lpTag;
  private _userProfile: UserProfile;

  constructor(private readonly _userService: UserService) { super(); this._chatServiceContainerClass = 'LPMcontainer LPMoverlay'; this._lpTag = window['lpTag']; }

  instantiate(): void {
    this._userService.userProfile$.pipe(
      first(),
      map(profile => {        
        this._lpTag = window['lpTag'];
        this.init(profile);
        this.loadScript('assets/scripts/pcs_autosubmit.js');
      })).subscribe();
  }

  tearDown(): void {
    this.hideButton();
  }

  configure(order: Order, loanData: LoanData): void {
    this._lpTag.section = this.tagSelectorFuture(order, loanData);

    this._lpTag.sdes.reset();
    this.setPersonalData();
    this.setOrderData(order);
    this._lpTag.sdes.reinit();
  }

  public hideButton(): void {
    this.changeButtonVisibility('hidden', false);
  }

  public showButton(): void {
    this.changeButtonVisibility('visible', true);
  }

  private init(userProfile: UserProfile): void {
    this._userProfile = userProfile;
    this.setPersonalData();
    this.setOrderData();
    this._lpTag.sdes.init();
  }

  private loadScript(url: string): void {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.id = 'pcs_script';
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  private setPersonalData(): void {
    const personal = {
      "type": "personal",  //MANDATORY
      "personal": {
        "firstname": this._userProfile.firstName,  // FIRST NAME
        "lastname": this._userProfile.lastName,  // SURNAME
        "contacts": [{
          "email": this._userProfile.emailAddress // use this for email
        }]
      }
    };

    this._lpTag.sdes.push(personal);
  }

  private setOrderData(order?: Order): void {
    const customerInfo = {
      "type": "ctmrinfo",  //MANDATORY
      "info": {
        "cstatus": "In Process",  //Status of loan
        "customerId": order ? order.loanNumber : null,  //use for the loan number
        "userName": this._userProfile.emailAddress,  //user for User Name
      }
    };
    this._lpTag.sdes.push(customerInfo);
  }

  private tagSelectorFuture(order: Order, loanData: LoanData) {
    if (order?.orderStatusId == OrderStatus.Closing && !loanData?.isTPO) {
      return this.closingTagSelector(loanData?.transactionType);
    }
    else if (order?.orderStatusId != OrderStatus.Closing && !loanData?.isTPO) {
      return this.nonClosingTagSelector(loanData?.transactionType)
    }
    else {
      return this.qlmsTagSelector(order?.orderStatusId);
    }
  }

  private nonClosingTagSelector(transactionType: string): string {
    switch (transactionType) {
      case ('Refinance'): {
        return 'closing-retail-refi-title';
      }
      case ('Purchase'): {
        return 'closing-retail-purch-title'
      }
      default: {
        return 'closing-retail-purch-title';
      }
    }
  }

  private closingTagSelector(transactionType: string): string {
    switch (transactionType) {
      case 'Refinance': {
        return 'closing-retail-refi';
      }
      case 'Purchase': {
        return 'closing-retail-purch'
      }
      default: {
        return 'closing-retail-purch-title';
      }
    }
  }

  private qlmsTagSelector(orderStatusId: number): string {
    return orderStatusId === OrderStatus.Closing ? 'closing-tpo' : 'closing-tpo-title';
  }
}
