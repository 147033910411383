import { Injectable } from '@angular/core';
import { UserService } from '@core/services/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { AccountUpdatesType, ChangeAgencyViewMetadata, CustomEvent, DataToDoMilestoneMetadata, DocumentUploadMetadata, FollowEventLocation, MessageFilterEventMetadata, OrderAccessedMetadata, PageLoadMetadata, SalesForceEvent, SearchCharactersType, UpdatePrimaryAgentMetadata } from '../models/custom-events-metadata';

@Injectable({
  providedIn: 'root'
})
export class CustomEventsService {

  private _loggedInAgentId: number;
  private readonly _adobeDataLayer: Array<CustomEvent>;
  private qls: BehaviorSubject<string>;
  private readonly _qlsCampaigns: Map<string, string>;

  constructor(
    private readonly _userService: UserService) {
    this._adobeDataLayer = window['adobeDataLayer'] || [];
    this.qls = new BehaviorSubject<string>("");
    this._userService.userProfile$.subscribe(x => this._loggedInAgentId = x.agentId);

    this._qlsCampaigns = new Map<string, string>([
      ["ENL_nexsyscp.closinstrc", "ClosingInstructionsPackage"],
      ["ENL_nexsyscp.clospackav", "ClosingPackageAvailable"],
      ["ENL_nexsyscp.critfuddoc", "CriticalFundingDoc"],
      ["ENL_nexsyscp.doctupload", "DocumentUploaded"],
      ["ENL_nexsyscp.actionneed", "EarnestMoney/Messages"],
      ["ENL_nexsyscp.fundingnum", "FundingNumber"],
      ["ENL_nexsyscp.loanstruch", "LoanStructureChanged"],
      ["ENL_nexsyscp.ordassignd", "OrderAssigned"],
      ["ENL_nexsyscp.ordercancl", "OrderCancelled"],
      ["ENL_nexsyscp.purcclr2cl", "Purch_CleartoClose"],
      ["ENL_nexsyscp.reficlr2cl", "Refi_CleartoClose"],
      ["ENL_nexsyscp.regapprval", "RegistrationApproval"],
      ["ENL_nexsyscp.passwreset", "ResetPassword"],
    ]);

  }

  public set qlsParam(value: string) {
    this.qls.next(value);
  }

  public get qlsParam(): string {
    return this.qls.getValue();
  }

  public pushPageLoadCustomEvent(metadata: PageLoadMetadata): void {
    metadata.agency_id = this._userService.targetAgencyId;
    metadata.qls = this.qlsParam;
    metadata.agent_id = this._loggedInAgentId;
    this._adobeDataLayer.push({
      event: "PAGEVIEW",
      metadata: metadata
    });
  }

  public pushPrimaryAgentUpdateEvent(metadata: UpdatePrimaryAgentMetadata) {
    metadata.agent_id = this._loggedInAgentId;
    metadata.agency_id = this._userService.targetAgencyId;
    this._adobeDataLayer.push({
      event: "PRIMARY_CONTACT_UPDATE_EVENT",
      metadata: metadata
    });
  }

  public pushAgencyChangeEvent(metadata: ChangeAgencyViewMetadata) {
    metadata.page_name = window.location.pathname.substring(1).replace('/', '-');
    metadata.agent_id = this._loggedInAgentId;
    this._adobeDataLayer.push({
      event: "AGENCY_VIEW_CHANGE_EVENT",
      metadata: metadata
    });
  }

  public pushDataToDoMilestoneUpdateEvent(metadata: DataToDoMilestoneMetadata) {
    metadata.agent_id = this._loggedInAgentId;
    this._adobeDataLayer.push({
      event: 'TO_DO_EVENT',
      metadata: metadata
    })
  }

  public pushOrderAccessedEvent(metadata: OrderAccessedMetadata){
    metadata.agency_id = this._loggedInAgentId;
    metadata.agency_id = this._userService.targetAgencyId;
    this._adobeDataLayer.push({
      event: 'ORDER_ACCESSED',
      metadata: metadata
    });
  }

  public pushSearchCharactersEvent(searchString: string){
    let searchCharactersType = this.getStringType(searchString);

    this._adobeDataLayer.push({
      event: 'SEARCH',
      metadata: {
        search_characters: searchCharactersType,
        agency_id: this._userService.targetAgencyId,
        agent_id: this._loggedInAgentId
      }
    })
  }

  public pushMessageFilterEvent(metadata: MessageFilterEventMetadata){
    metadata.agency_id = this._userService.targetAgencyId;
    metadata.agent_id = this._loggedInAgentId;

    this._adobeDataLayer.push({
      event: 'MESSAGE_FILTER_EVENT',
      metadata: metadata
    })
  }

  public pushDocumentUploadEvent(metadata: DocumentUploadMetadata){
    metadata.agency_id = this._userService.targetAgencyId;
    metadata.agent_id = this._loggedInAgentId;    

    this._adobeDataLayer.push({
      event: 'UPLOAD',
      metadata: metadata
    })
  }

  public pushSubscriberEvent(location: FollowEventLocation, isSubscribingActivity: boolean, count: number){
    this._adobeDataLayer.push({
      event: `${isSubscribingActivity ? 'FOLLOWERS' : 'UNFOLLOW'}_${location}`,
      metadata: {
        count: count,
        agency_id: this._userService.targetAgencyId,
        agent_id: this._loggedInAgentId
      }
    })
  }

  public pushMyAccountUpdateEvent(eventType: AccountUpdatesType){
    this._adobeDataLayer.push({
      event: "MY_ACCOUNT_UPDATE",
      metadata: {
        type: eventType,
        agency_id: this._userService.targetAgencyId,
        agent_id: this._loggedInAgentId
      }
    });
  }

  public pushQlsLogin(qlsString: string, pageName: string){
    this._adobeDataLayer.push({
      event: "PAGEVIEW",
      metadata: {
        page_name: pageName,
        qls_string: this._qlsCampaigns.has(qlsString) ? this._qlsCampaigns.get(qlsString) : null,
        agency_id: this._userService.targetAgencyId,
        agent_id: this._loggedInAgentId
      }
    })
  }

  public pushSalesForceInitializationEvent(metadata: SalesForceEvent) {
    this._adobeDataLayer.push({
      event: 'SALES_FORCE_INITIALIZATION',
      metadata: {
        agency_id: this._loggedInAgentId,
        order_id: metadata.order_id
      }
    });
  }

  private getStringType(value: string): SearchCharactersType{
    if (value.match(/^[a-z ]+$/i)!=null){
      return SearchCharactersType.Alpha;
    } else if (value.match(/^[0-9 ]+$/i)!=null){
      return SearchCharactersType.Numeric;
    } else if (value.match(/^[a-z0-9 ]+$/i)!=null){
      return SearchCharactersType.AlphaNumeric;
    } else if (value.match(/^[a-zA-Z0-9 ?!@/\\~,`#\$%\^\&*\)\(+=._-]+$/g)){
      return SearchCharactersType.SpecialCharacter;
    } else{
      return SearchCharactersType.Unknown;
    }
  }
}
