<div class="sprk-c-Footer min-height--10vh">
  <footer class="sprk-o-CenteredColumn sprk-o-Stack sprk-o-Stack--misc-b sprk-c-Footer__text"
          role="contentinfo"
          data-id="footer-1">
    <div class="d-flex flex-column my-3">
      <div class="text-center">© 2020-{{year}} {{nameSelector()}}, LLC All Rights Reserved
      </div>
      <div class="mt-3 align-self-center">
        <ul id="menu">
          <li class="px-3"><a (click)="openContactUsModal()"
               class="sprk-b-Link sprk-b-Link--simple sprk-b-Link--light">Contact Us</a></li>
          <li class="px-3"><a (click)="openTermsModal()"
               class="sprk-b-Link sprk-b-Link--simple sprk-b-Link--light">Terms Of Use</a></li>
          <li class="px-3"><a id="privacy"
               class="sprk-b-Link sprk-b-Link--simple sprk-b-Link--light"
               (click)="openPrivacyPage()">Privacy Policy</a></li>
          <li class="px-3"><a (click)="openAccessUsageModal()"
               class="sprk-b-Link sprk-b-Link--simple sprk-b-Link--light">Access and Usage Agreement</a></li>
          <li class="px-3">
            <a class="sprk-b-Link sprk-b-Link--simple sprk-b-Link--light"
               (click)="onFeedbackClick()">
              Share Feedback
            </a>
          </li>
        </ul>
      </div>
    </div>

    <ng-template #usageModal>
      <modal-popup [header]="'Access and Usage Agreement'"
                   (onConfirm)="bsModalRef.hide()">
        <app-pdf-viewer [src]="usageSrc"></app-pdf-viewer>
      </modal-popup>
    </ng-template>

    <ng-template #termsModal>
      <modal-popup [header]="'Terms of Use'"
                   (onConfirm)="bsModalRef.hide()">
        <app-pdf-viewer [src]="termsSrc"></app-pdf-viewer>
      </modal-popup>
    </ng-template>

    <ng-template #contactUsModal>
      <modal-popup [header]="'Contact Us'"
                   (onConfirm)="bsModalRef.hide()">
        <app-contact-us></app-contact-us>
      </modal-popup>
    </ng-template>
  </footer>
</div>