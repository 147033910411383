import { Injectable } from '@angular/core';
import { FeatureFlagProvider } from '@closing-portal/feature-flags';
import { FeatureFlags } from '@enums/feature-flags.enum';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private _headerVersion$: ReplaySubject<number> = new ReplaySubject(1);
  private _salesForceEnabled$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _preferredEmailsEnabled$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _closingType$: ReplaySubject<boolean> = new ReplaySubject(1);
  private readonly _documentVersion$: ReplaySubject<number> = new ReplaySubject<number>(1);
  private readonly _milestoneApiVersion$: ReplaySubject<number> = new ReplaySubject(1);
  private readonly _messageApiVersion$: ReplaySubject<number> = new ReplaySubject(1);
  private readonly _loanApiVersion$: ReplaySubject<number> = new ReplaySubject(1);
  private readonly _versions$: ReplaySubject<Map<FeatureFlags | string, number>> = new ReplaySubject(1);
  private readonly features = new Set([
    FeatureFlags.LoanDataApiVersion,
    FeatureFlags.MessageApiVersion,
    FeatureFlags.MilestoneApiVersion,
    FeatureFlags.DocumentUploadVersion,
    FeatureFlags.HeaderVersion,
    FeatureFlags.DashboardVersion,
  ]);

  constructor(private _featureFlagProvider: FeatureFlagProvider) { }

  public get documentVersion$(): Observable<number> {
    return this._documentVersion$;
  }

  public get salesForceEnabled$(): Observable<boolean> {
    this._featureFlagProvider.getFeatureFlagWhenReady$(FeatureFlags.SalesForceLiveChat, active => this._salesForceEnabled$.next(active));

    return this._salesForceEnabled$;
  }

  public get preferredEmailEnabled$(): Observable<boolean> {
    this._featureFlagProvider.getFeatureFlagWhenReady$(FeatureFlags.PreferredEmail, active => this._preferredEmailsEnabled$.next(active));

    return this._preferredEmailsEnabled$;
  }

  public get closingType$(): Observable<boolean> {
    this._featureFlagProvider.getFeatureFlagWhenReady$(FeatureFlags.ClosingType, active => this._closingType$.next(active));

    return this._closingType$;
  }

  public get versions$() {
    return this._versions$.pipe(distinctUntilChanged());
  }

  public get milestoneApiVersion$(): Observable<number> {
    return this._milestoneApiVersion$.pipe(distinctUntilChanged());
  }

  public get messageApiVersion$(): Observable<number> {
    return this._messageApiVersion$.pipe(distinctUntilChanged());
  }

  public get loanApiVersion$(): Observable<number> {
    return this._loanApiVersion$.pipe(distinctUntilChanged());
  }

  public configureFeatureVersions() {
    this._featureFlagProvider.getFeatureVersionsWhenReady$(this.features, versions => {
      this._versions$.next(versions);
      this._documentVersion$.next(versions.get(FeatureFlags.DocumentUploadVersion));
      this._loanApiVersion$.next(versions.get(FeatureFlags.LoanDataApiVersion));
      this._messageApiVersion$.next(versions.get(FeatureFlags.MessageApiVersion));
      this._milestoneApiVersion$.next(versions.get(FeatureFlags.MilestoneApiVersion));
    });
  }
}
