import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FeedbackService } from '@core/services/feedback/feedback.service';
import { UserService } from '@core/services/user/user.service';
import { UserProfile } from '@models/user-profile';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public termsSrc = `${window.location.origin}/assets/files/legal/TermsOfUse.v1.2.pdf`;
  public usageSrc = `${window.location.origin}/assets/files/legal/AccessAndUsageAgreement.v1.5.pdf`;
  public year: number;
  public bsModalRef: BsModalRef
  private _userProfile: UserProfile;
  private _canShowRocketMorgageTitle = false;
  @ViewChild('termsModal') termsModal: TemplateRef<any>;
  @ViewChild('usageModal') usageModal: TemplateRef<any>;
  @ViewChild('contactUsModal') contactUsModal: TemplateRef<any>;

  constructor(
    private readonly _modalService: BsModalService,
    private readonly _userService: UserService,
    private readonly _feedbackService: FeedbackService
  ) { }

  ngOnInit(): void {
    this._userService.userProfile$.pipe(first()).subscribe(userProfile => this._userProfile = userProfile);
    this.year = new Date().getFullYear();
  }

  public get canShowRocketMorgageTitle(): boolean {
    return this._canShowRocketMorgageTitle;
  }

  public openTermsModal(): void {
    this.bsModalRef = this._modalService.show(this.termsModal, {
      class: 'sprk-c-Modal sprk-c-Modal--alt sprk-c-Modal--hidden-scroll',
      ignoreBackdropClick: false
    });
  }

  public openAccessUsageModal(): void {
    this.bsModalRef = this._modalService.show(this.usageModal, {
      class: 'sprk-c-Modal sprk-c-Modal--alt sprk-c-Modal--hidden-scroll',
      ignoreBackdropClick: false
    });
  }

  public openPrivacyPage(): void {
    window.open("https://www.rocketmortgage.com/legal/security-privacy");
  }

  public openContactUsModal(): void {
    this.bsModalRef = this._modalService.show(this.contactUsModal, {
      class: 'sprk-c-Modal sprk-c-Modal--hidden-scroll',
      ignoreBackdropClick: false
    });
  }

  public onFeedbackClick(): void {
    this._feedbackService.openFeedback(this._userProfile.agentId);
  }

  public nameSelector(): string {
    return 'Rocket Mortgage';
  }
}
