import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HighlightHelperService {
  public getHighlightSection(highlight: object): HighlightSection {
    if (highlight['contacts.ClientName'])
      return HighlightSection.Client;

    if (highlight['LoanNumber'])
      return HighlightSection.LoanNumber;

    return HighlightSection.Address;
  }
}

export enum HighlightSection {
  Address = 'address',
  Client = 'contact',
  LoanNumber = 'loan-number'
}