<div class="sprk-o-Stack sprk-o-Stack--large">
  <header class="sprk-o-Stack__item sprk-c-Modal__header">
    <h2
      *ngIf="header?.length > 0"
      class="sprk-c-Modal__heading sprk-b-TypeDisplayFour"
      id="modalChoiceHeading">
      {{ header }}
    </h2>

    <button
      class="sprk-c-Modal__icon"
      (click)="cancel()"
      data-sprk-modal-cancel="exampleChoiceModal"
      type="button"
      aria-label="Close Modal"
      *ngIf="showClose">
      <svg
        class="sprk-c-Icon"
        viewBox="0 0 64 64"
        aria-hidden="true"
        focusable="false">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </header>

  <div class="sprk-o-Stack__item sprk-c-Modal__body">
    <ng-content></ng-content>
  </div>

  <footer
    class="sprk-o-Stack__item
           sprk-c-Modal__footer
           sprk-o-Stack
           sprk-o-Stack--split@xs
           sprk-o-Stack--end-row
           sprk-o-Stack--medium"
    *ngIf="showButtons">
    <div class="sprk-o-Stack__item mr-0">
      <button
        *ngIf="cancelBtnTxt"
        class="sprk-c-Button
               sprk-c-Button--secondary
               sprk-c-Button--full@xs"
        (click)="cancel()">
        {{ cancelBtnTxt }}
      </button>
    </div>
    <div class="sprk-o-Stack__item mr-0">
      <button
        *ngIf="submitBtnTxt"
        class="sprk-c-Button 
               sprk-c-Button--full@xs 
               ml-3"
        [disabled]="disableSubmit"
        (click)="submit()">
        {{ submitBtnTxt }}
      </button>
    </div>
  </footer>
</div>
