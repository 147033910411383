export enum DataSource {
  Contacts = 'Contacts',
  SurveyOptions = 'SurveyOptions',
  Loan = 'Loan',
  Property = 'Property',
  Agents = 'Agents',
  Agency = 'Agency',
  Milestone = 'Milestone',
  States = "States"
}
