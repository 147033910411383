import * as Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

export const isNonSupportedBrowser = browser.isBrowser('Internet Explorer');

export function openFallbackBrowser() {
  if (isNonSupportedBrowser) {
    window.location.assign('microsoft-edge:' + window.location);

    setTimeout(function () {
      window.location = <any>'https://go.microsoft.com/fwlink/?linkid=2135547';
    }, 1);
  }
}
