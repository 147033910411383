import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loanNumber'
})
export class LoanNumberPipe implements PipeTransform {

  transform(value: number | null): string {
    if (!value) return 'Loan#';
    return `Loan# ${value}`;
  }
}