import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SessionStorageKey } from '@enums/session-storage-key.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { SearchResults } from '@models/search-results';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends OnDestroySubscriptionResolver {
  private _searchResult$$: BehaviorSubject<SearchResults>;
  private _searchTerms$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _searchResultsLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly _httpHelper: HttpHelperService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _customEventSvc: CustomEventsService) {
    super();
    this._searchResult$$ = new BehaviorSubject<SearchResults>(new SearchResults());
  }

  private get searchUrl(): string {
    return `${environment.catchApiUrl}/search/orders`;
  }

  public get searchResultsLoaded$(): Observable<boolean> {
    return this._searchResultsLoaded$$;
  }

  public get searchTerm(): Observable<string> {
    return this._searchTerms$$.asObservable();
  }

  public set searchTermValue(query: string) {
    this._searchTerms$$.next(query);
  }

  public get searchTermValue(): string {
    return this._searchTerms$$.value;
  }

  public get searchResult$(): Observable<SearchResults> {
    return this._searchResult$$.asObservable();
  }

  public get agencyId$() {
    return this._activatedRoute.queryParamMap
      .pipe(
        switchMap(paramMap => this.getAgencyId(paramMap))
      );
  }

  public search(query: string): void {
    this._searchResultsLoaded$$.next(false);
    if (query !== '' && query != null) {
      this.agencyId$.pipe(
        switchMap(agencyId => this._httpHelper.sendHttpRequestWithRetryStrategy$<SearchResults>('GET', `${this.searchUrl}/${query}?agencyid=${agencyId}`)
          .pipe(first())),
        map(searchResults => {
          this._searchResult$$.next(searchResults);
          this._searchResultsLoaded$$.next(true);
        }),
        first()
      ).subscribe();

      this._customEventSvc.pushSearchCharactersEvent(query);
    }
    else {
      this._searchResult$$.next(new SearchResults());
      this._searchResultsLoaded$$.next(true);
    }
  }

  public searchByAgencies$(query: string, agencyIds: Array<number>, statusIds: Array<number> = [], size: number = 5, page: number = 1): void {
    this._searchResultsLoaded$$.next(false);

    if (query && agencyIds && agencyIds.length > 0) {
      let url: string = `${this.searchUrl}/${query}?size=${size}&page=${page}&`;

      agencyIds.forEach(agencyId => {
        url += `agencyId=${agencyId}&`;
      });

      if (statusIds.length > 0) {
        statusIds.forEach(status => url += `statusId=${status}&`);
      }

      url = url.substring(0, url.length - 1);

      this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<SearchResults>('GET', `${url}`, { headers: this._httpHelper.createVersionHeader(2) })
        .pipe(map(searchResults => {
          this._searchResult$$.next(searchResults);
          this._searchResultsLoaded$$.next(true);
        }),
          first()
        ).subscribe();

      this._customEventSvc.pushSearchCharactersEvent(query);
    }
    else {
      this._searchResult$$.next(new SearchResults());
      this._searchResultsLoaded$$.next(true);
    }
  }

  public searchByAgenciesOnly$(agencyIds: Array<number>, statusIds: Array<number> = [], size: number = 5, page: number = 1): void {
    this._searchResultsLoaded$$.next(false);

    let url: string = `${this.searchUrl}/%20?size=${size}&page=${page}&`;

    agencyIds.forEach(agencyId => {
      url += `agencyId=${agencyId}&`;
    });

    if (statusIds.length > 0) {
      statusIds.forEach(status => url += `statusId=${status}&`);
    }

    url = url.substring(0, url.length - 1);

    this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<SearchResults>('GET', `${url}`, { headers: this._httpHelper.createVersionHeader(2) })
      .pipe(map(searchResults => {
        this._searchResult$$.next(searchResults);
        this._searchResultsLoaded$$.next(true);
      }),
        first()
      ).subscribe();

  }

  public searchByStatuses$(agentId: number, statusIds: number[], size: number = 5, page: number = 1): Observable<SearchResults> {
    let requestUri = `${environment.catchApiUrl}/search/orders?`;
    for (const statusId of statusIds) {
      requestUri += `statusId=${statusId}&`;
    }

    requestUri += `agentId=${agentId}&size=${size}&page=${page}`

    return this.agencyId$.pipe(
      switchMap(agencyId => this._httpHelper.sendHttpRequestWithRetryStrategy$<SearchResults>('GET', `${requestUri}&agencyid=${agencyId}`)
        .pipe(first())),
      first()
    );
  }

  private getAgencyId(paramMap: ParamMap) {
    if (paramMap.has('agencyId')) {
      return of(+paramMap.get('agencyId'))
    }

    return of(+sessionStorage.getItem(SessionStorageKey.TargetAgency))
  }

}
