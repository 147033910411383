<ng-container *ngIf="loaded">
  <sprk-icon [iconName]="subscriptionStatusIcon"
             (click)="changeSubscription($event)"
             class="cursor-pointer"></sprk-icon>
  <ng-container *ngIf="showCount">
    <span (click)="togglePopup($event);"
          class="popover__wrapper popover__title">+{{subscriptions.length}}
      <div *ngIf="showPopOver"
           (click)="$event.stopPropagation()"
           class="popover__content">
        <h4 al
            sprkHeading
            variant="displayFive">
          Subscribers on this Order
          <button type="button"
                  class="close"
                  aria-label="Close"
                  (click)="togglePopup($event); ">
            <span aria-hidden="true">&times;</span>
          </button>
        </h4>
        <hr>
        <ul class="popover__list">
          <li *ngFor="let sub of subscriptions">{{sub.firstName}} {{sub.lastName}}</li>
        </ul>
        <button class="mt-3 sprk-c-Button"
                appDebounceClick
                [disabled]="(isSubscribedToOrder && subscriptions.length == 1) || isSsoUser || isPrimaryAgentOnOrder"
                (click)="changeSubscription($event)"
                sprkButton>
          {{isSubscribedToOrder ? 'Unsubscribe' : 'Subscribe'}}
        </button>
      </div>
    </span>
  </ng-container>
</ng-container>