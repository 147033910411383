import { Injectable } from '@angular/core';
import { SearchListResults } from '@models/search-list-results';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { ClientNamePipe } from '@shared/pipes/client-name/client-name.pipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SearchListService {
  private _searchResult$$: BehaviorSubject<SearchListResults> = new BehaviorSubject<SearchListResults>(new SearchListResults());
  private _searchTerms$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _searchResultsLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get searchResult$(): Observable<SearchListResults> {
    return this._searchResult$$.asObservable();
  }

  public get searchResultsLoaded$(): Observable<boolean> {
    return this._searchResultsLoaded$$.asObservable();
  }

  public get searchTerm$(): Observable<string> {
    return this._searchTerms$$.asObservable();
  }

  public set searchTermValue(query: string) {
    this._searchTerms$$.next(query);
  }

  constructor(private readonly _httpHelper: HttpHelperService,
    private readonly _customEventSvc: CustomEventsService,
    private readonly _clientNamePipe: ClientNamePipe) { }

  public searchByAgencies$(query: string, agencyIds: Array<number>, pagesize: number = 5): void {
    this._searchResultsLoaded$$.next(false);

    if (query && agencyIds && agencyIds.length > 0) {
      let url: string = `${this.searchUrl}/${query}?`;

      agencyIds.forEach(agencyId => {
        url += `agencyId=${agencyId}&`;
      });

      url += `size=${pagesize}&page=1`;

      this._httpHelper.sendHttpRequestWithRetryStrategy$<SearchListResults>('GET', url)
        .pipe(
          map(searchResults => {
            this._searchResult$$.next(searchResults);
            this._searchResultsLoaded$$.next(true);
          }),
          first()
        ).subscribe();
    }
    else {
      this._searchResult$$.next(new SearchListResults());
      this._searchResultsLoaded$$.next(true);
    }

    this._customEventSvc.pushSearchCharactersEvent(query);

  }

  private get searchUrl(): string {
    return `${environment.catchApiUrl}/search/orders`;
  }
}
