import { Pipe, PipeTransform } from '@angular/core';
import { DocumentMetaData } from '@models/document-meta-data';
import { DocMetaData } from 'src/app/models/doc-metadata';

@Pipe({
  name: 'partnerDocument',
  pure: false
})
export class PartnerDocumentPipe implements PipeTransform {
  transform(items: Array<DocMetaData | DocumentMetaData>, isPartnerDocument: boolean): Array<DocMetaData | DocumentMetaData> {
    if (!items){
      return [];
    }

    return items.filter(x => x.isPartnerDocument == isPartnerDocument);
  }
}
