import { AllowedMilestoneAction } from '@core/services/milestone/milestone-processor.service';
import { DataSource } from '@enums/data-source.enum';
import { Milestone } from '@models/milestone';
import { ISubmittable } from '@models/processor/submittable.interface';
import { Observable, of } from 'rxjs';

export class MilestoneAction implements ISubmittable<AllowedMilestoneAction> {
  private readonly _milestone: NonNullable<Milestone>;
  private readonly _action: NonNullable<AllowedMilestoneAction>;
  private readonly _dataSource: DataSource;

  constructor(
    milestone: NonNullable<Milestone>,
    action: NonNullable<AllowedMilestoneAction>,
    dataSource: DataSource = null) {
    this._action = action ?? of(null);
    this._milestone = milestone;
    this._dataSource = dataSource;
  }

  public execute(): AllowedMilestoneAction | Observable<any> {
    return this._action;
  }

  public get milestone(): Milestone {
    return this._milestone;
  }

  public get dataSource(): DataSource | null {
    return this._dataSource;
  }
}
