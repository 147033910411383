import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetail } from '@models/order-detail';

@Pipe({
  name: 'orderLoanNumber'
})
export class OrderLoanNumberPipe implements PipeTransform {

  transform(orderDetail: OrderDetail, ...args: unknown[]): unknown {
    return orderDetail.loanNumber;
  }

}
