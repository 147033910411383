import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentDownloadService } from '@core/services/documents/document-download.service';
import { DocumentsService } from '@core/services/documents/documents.service';
import { DocumentMetaData } from '@models/document-meta-data';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { Order } from '@models/order';
import { DocumentDownloadStatus } from '@shared/modules/adobe-analytics/models/custom-events-metadata';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';
import { SignalRService } from 'src/app/modules/order-view/services/signalr.service';
@Component({
  selector: 'app-helpful-resources',
  templateUrl: './helpful-resources.component.html',
})
export class HelpfulResourcesComponent extends OnDestroySubscriptionResolver implements OnDestroy, OnInit {
  @Input() order: Order;
  public downloadInProgress: Map<string, Array<boolean>>;
  public isDocHistoryVisible: Array<boolean>;
  public documents: Array<DocumentMetaData>;
  
  constructor(
    private readonly _orderViewService: OrderViewService,
    private readonly _downloadDocumentService: DocumentDownloadService,
    private readonly _customEventService: CustomEventsService,
    private readonly _signalRService: SignalRService,
    private readonly _documentService: DocumentsService) { super(); }
  ngOnInit() {
    this.isDocHistoryVisible = new Array<boolean>();
    this.downloadInProgress = new Map<string, Array<boolean>>();
    this.getDocuments();
    this.refreshDocs();
  }

  public downloadDocument(documentId: number, documentName: string, index: number): void {
    let downloadArr = [];
    downloadArr[index] = true;
    this.downloadInProgress.set(documentName, downloadArr);
    this._downloadDocumentService.downloadDocumentV2$(this.order.orderId, documentId, documentName, this._unsubscribe$$)
      .subscribe(_ => {
        this.downloadInProgress.set(documentName, []);
        this.sendTodoEventToAdobeAnalytics(DocumentDownloadStatus.Success);
      }, _ => {
        this.downloadInProgress.set(documentName, []);
        this.sendTodoEventToAdobeAnalytics(DocumentDownloadStatus.Failed);
      });
  }

  public toggleHistory(index: number): void {
    this.isDocHistoryVisible[index] = !this.isDocHistoryVisible[index];
  }

  public get documents$(): Observable<DocumentMetaData[]> {
    return this._orderViewService.documents$;
  }

  public isDownloading(docName: string, index: number): boolean {
    let arr = this.downloadInProgress.get(docName);
    if (arr != null && arr != undefined) {
      return arr[index]
    }
    return false;
  }

  public getDocuments(): void{
    this._orderViewService.orderId$.pipe(filter(id => id>0),switchMap(orderId => {
      return this._documentService.getDocuments$(orderId)
    })).subscribe(docs => this.documents = docs);
  }

  private sendTodoEventToAdobeAnalytics(status: DocumentDownloadStatus) {
    this._customEventService.pushDataToDoMilestoneUpdateEvent({
      order_id: this.order?.orderId,
      order_status: this.order?.orderStatusId,
      todo_name: 'helpful-resources',
      todo_status: status,
      agency_id: this.order?.agencyId
    });
  }

  private refreshDocs(){
    this._signalRService.message$$.pipe(filter(event => event.resource=="DocumentsRefresh")).subscribe(message => {
      this.getDocuments();
    })
  }
}