<sprk-input-container>
  <div class="sprk-b-InputContainer__icon-container">
    <label for="search-input"
           isHidden="true"
           sprkLabel>
      Search
    </label>
    <sprk-icon iconName="search"
               additionalClasses="sprk-b-InputContainer__icon sprk-b-InlineSearch__icon sprk-c-Icon--filled-current-color sprk-c-Icon--stroke-current-color"
               sprk-input-icon
               aria-hidden="true"></sprk-icon>
    <input class="sprk-b-TextInput sprk-b-TextInput--has-svg-icon search-input"
           id="search-input"
           name="Search Input"
           data-id="search-input"
           type="search"
           autocomplete="false"
           placeholder="Search"
           aria-describedby="search--error-container"
           (keypress)="keyPressed($event, searchQuery.value)"
           (focus)="openSearch($event)"
           (blur)="blurSearch(400)"
           (input)="search(searchQuery.value)"
           sprkInput
           #searchQuery />
    <sprk-icon iconType="close"
               title="Clear"
               additionalClasses="sprk-b-InputContainer__icon 
                                  sprk-b-InlineSearch__icon 
                                  sprk-c-Icon--filled-current-color 
                                  sprk-c-Icon--stroke-current-color
                                  search-input__clear
                                  cursor-pointer"
               (click)="clearSearch()">
    </sprk-icon>
  </div>
  <div class="sprk-u-Position--absolute sprk-u-BackgroundColor--white order-search-list">
    <app-order-search-list [searchResults]="searchResults"
                           (orderSelected)="orderSelected($event)"
                           (showAll)="showAllResults(searchQuery.value)"></app-order-search-list>
  </div>
</sprk-input-container>