import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { StateNoticeRulesService } from '@core/services/rules/notices/state-notice-rules.service';
import { NullablePersonSortService } from '@core/services/sorters/nullable-person/nullable-person-sort.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { UserService } from '@core/services/user/user.service';
import { RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { AgentsPopupComponent, AgentsPopupData } from '@shared/components/agents-popup/agents-popup.component';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AgencyAddressPipeV2 } from 'src/app/features/shared/pipes/agency-address-v2/agency-address-v2.pipe';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'catch-agents-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    RktStackModule,
    AgencyAddressPipeV2,
  ],
  providers: [OrderViewService],
  templateUrl: './agents-popup-v2.component.html',
})
export class AgentsPopupComponentV2 extends AgentsPopupComponent {

  constructor(
    protected _userService: UserService,
    protected _modalService: BsModalService,
    protected _subscriptionService: OrderSubscriptionService,
    protected readonly _orderViewService: OrderViewService,
    protected readonly _personSort: NullablePersonSortService,
    protected readonly _customEventSvc: CustomEventsService,
    protected readonly _stateNoticesService: StateNoticeRulesService,
    @Inject(MAT_DIALOG_DATA) public data: AgentsPopupData = new AgentsPopupData()
  ) {
    super(_userService, _modalService, _subscriptionService, _orderViewService, _personSort, _customEventSvc, _stateNoticesService);
    this.agencyId = data.agencyId;
    this.orderId = data.orderId;
    this.currentSubscriptions = data.currentSubscriptions;
    this.orderDetail = data.orderDetail;
  }
}

