import { Component, Input } from '@angular/core';
import { DataSource } from '@enums/data-source.enum';
import { Milestone } from '@models/milestone';

@Component({
  selector: 'app-data-milestone-list',
  templateUrl: './data-milestone-list.component.html'
})
export class DataMilestoneListComponent {
  @Input() milestones: Array<Milestone>;
}
