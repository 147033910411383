<p class="line-clamp" [ngClass]="{'line-clamp--expand': canShowFullMessage}">
  <span class="font-weight-bold pr-1" *ngIf="title">{{title}}</span>
  <span class="white-space-pre-line"
        [innerHTML]="message"></span>
</p>
<div class="d-flex justify-content-center pt-1" *ngIf="message?.length > characterLimitation">
    <label class="sprk-b-Link" sprkLink idString="default-link" analyticsString="default-link"
      (click)="canShowFullMessage = !canShowFullMessage">
      {{canShowFullMessage ? "- Hide" : "+ Show"}} All
    </label>
</div>