<main class="sprk-o-Box sprk-o-Box--large">
    <article>
        <header class="sprk-o-CenteredColumn sprk-u-phm">
            <h3>User Guide</h3>
        </header>
        <div class="d-flex justify-content-center"
         *ngIf="!isRendered">
            <div class="
                my-5
                sprk-o-Stack__item
                sprk-c-Spinner
                sprk-c-Spinner--circle
                sprk-c-Spinner--large
                sprk-c-Spinner--primary">
            </div> 
        </div>
        <div class="sprk-o-CenteredColumn sprk-u-phm pdf py-4">  
            <pdf-viewer [src]="userguidesrc"
                        [render-text]="true"
                        [fit-to-page]="true"
                        [original-size]="false"
                        [zoom]="1" 
                        (after-load-complete)="onPdfLoad($event)"></pdf-viewer>   
        </div>
    </article>
</main>
