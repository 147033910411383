import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@core/services/user/user.service';
import { UserProfile } from '@models/user-profile';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseGuard implements CanActivateChild {
  constructor(
    private _userService: UserService,
    private _router: Router) { }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._userService.userProfile$.pipe(
      switchMap(userProfile => of(this.hasAcceptedTerms(userProfile, state)))
    );
  }

  private hasAcceptedTerms(userProfile: UserProfile, state: RouterStateSnapshot): boolean {
    const isTermsAccepted = userProfile.isTermsAccepted == true;

    if (!isTermsAccepted) {
      this._router.navigateByUrl(`/${ApplicationPaths.UsageAgreement}`, { state: { redirect: state.url } })
    }

    return isTermsAccepted;
  }
}
