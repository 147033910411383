export enum ApplicationPaths {
  Account = 'account',
  Callback = 'auth/callback',
  Dashboard = 'dashboard',
  UsageAgreement = 'usage-agreement',
  Orders = 'orders',
  HelpPage = 'help-page',
  Search = 'search',
  Archive = 'archive',
  EmailVerification = "email-verify",
  EClosingReport = "eClosing-report",
  Logout = "logout"
}
