import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OrderService } from '@core/services/order/order.service';
import { Order } from '@models/order';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';

@Injectable({
  providedIn: 'root'
})
export class OrderAuthGuard implements CanActivateChild {

  constructor(private readonly _orderService: OrderService, private readonly _router: Router) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const orderId = +next.paramMap.get('orderId');   
    
    if (orderId) {
      return this._orderService.getOrder$(orderId).pipe(
      map(order => (this.canViewOrder(order))));
    }
    return true;
  }
  
  private canViewOrder(order: Order): boolean {
    if (order == null) {
      this._router.navigateByUrl(ApplicationPaths.Archive);
      return false;
    }
    return true;
  }
}
