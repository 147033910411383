import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { openFallbackBrowser, isNonSupportedBrowser } from './browser-compatibility-checker';
import { registerAdobeAnalytics } from './adobe-analytics-registration';

if (environment.production) {
  enableProdMode();
}

registerAdobeAnalytics();

if (isNonSupportedBrowser) {
  openFallbackBrowser();
}
else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
