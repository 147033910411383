import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentUploaderService } from '@core/services/documents/document-uploader.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { MilestoneHelperService } from '@core/services/milestone/milestone-helper.service';
import { MilestoneDefinitionId } from '@enums/milestone-definition-ids.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { MilestoneType } from '@enums/milestone-type.enum';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { DocumentMetaData } from '@models/document-meta-data';
import { Milestone } from '@models/milestone';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-general-milestone-list',
  templateUrl: './general-milestone-list.component.html'
})
export class GeneralMilestoneListComponent extends OnDestroySubscriptionResolver implements OnInit {
  @ViewChild('popup') popUpTemplate: TemplateRef<any>;
  @Input() canUploadMultipleFiles?= true;
  @Input() canShowUploadResults?= true;
  @Input() milestones: Array<Milestone>;

  public readonly milestoneStatus = MilestoneStatus;
  public readonly milestoneType = MilestoneType;
  public readonly milestoneDefinitionIds = MilestoneDefinitionId;
  public faUpload = faUpload;
  public hasRejectedFiles = false;
  public uploadInput: EventEmitter<UploadInput>;
  public closingPackageDocuments = Array<DocumentMetaData>();
  public selectedDocumentId: number;
  public downloadInProgress: boolean = false;
  public documentFeatureVersion: number = 1;
  public historyMilestone: Milestone = null;

  public get options(): UploaderOptions {
    return this.documentUploaderService.options;
  }

  constructor(
    private readonly _milestoneHelperService: MilestoneHelperService,
    private readonly _orderViewService: OrderViewService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _featureService: FeatureService,
    public readonly documentUploaderService: DocumentUploaderService) {
    super();

    this.uploadInput = new EventEmitter<UploadInput>();
    this.uploadInput = new EventEmitter<UploadInput>();
    this.selectedDocumentId = 0;
  }

  public ngOnInit(): void {
    if (!this.canUploadMultipleFiles) {
      this.documentUploaderService.options.maxUploads = 1;
    }

    this.documentUploadVersion$
      .pipe(
        takeUntil(this._unsubscribe$$),
        tap(version => {
          this.documentFeatureVersion = version;
        })
      ).subscribe();

    const closingPackageMilestone = this.milestones.filter(milestone => milestone.milestoneDefinitionId === 12 &&
      milestone.milestoneStatusId === MilestoneStatus.Complete)[0];
    if (closingPackageMilestone !== null && closingPackageMilestone !== undefined) {
      this.getClosingPackageDocuments(closingPackageMilestone);
    }
  }

  public getClosingPackageDocuments(milestone: Milestone): void {
    this._orderViewService.getDocuments$(milestone.milestoneDefinitionId, milestone.referenceId)
      .subscribe(documents => {
        this.closingPackageDocuments = documents;
      });
  }

  public toggle(milestone: Milestone): void {
    if (milestone.milestoneTypeId == MilestoneType.DocumentUpload)
      this.historyMilestone = milestone;
    else
      milestone.isCollapsed = !milestone.isCollapsed;
  }

  public closeHistoryView(): void {
    this.historyMilestone = null;
  }

  public isDataMilestone(milestoneType: MilestoneType): boolean {
    return this._milestoneHelperService.isDataMilestoneType(milestoneType);
  }

  public isFinalMilestone(milestone: Milestone): boolean {
    return this._milestoneHelperService.isFinalMilestone(milestone);
  }

  public navigateToFinalReview(): void {
    this._router.navigate(['final'], { relativeTo: this._route, skipLocationChange: true });
  }

  public isDocumentMilestone(milestoneType: MilestoneType): boolean {
    return this._milestoneHelperService.isDocumentMilestoneType(milestoneType);
  }

  public downloadDocument(milestoneDefinitionId: number, selectedDocumentId: number, referenceId: string): void {
    this.downloadInProgress = true;
    this._orderViewService.orderId$.pipe(
      switchMap(orderId => {
        return selectedDocumentId > 0 ?
          this._orderViewService.downloadDocumentByDocumentId$(selectedDocumentId, orderId, this._unsubscribe$$) :
          this._orderViewService.downloadDocument$(milestoneDefinitionId, orderId, this._unsubscribe$$, referenceId);
      }
      )).pipe(takeUntil(this._unsubscribe$$)).subscribe(_ => {
        this._unsubscribe$$.next()
        this.downloadInProgress = false;
      }, _ => this.downloadInProgress = false);
  }

  public goToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private get documentUploadVersion$(): Observable<number> {
    return this._featureService.documentVersion$;
  }
}
