import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../../../models/contact';

@Pipe({
  name: 'clientName'
})
export class ClientNamePipe implements PipeTransform {

  public transform(contact: Contact): string {
    let clientName = '';

    if (contact.firstName) {
      clientName += contact.firstName.trim();
    }

    if (contact.middleName) {
      clientName += ` ${contact.middleName.substring(0,1)}`;
    }

    if (contact.lastName) {
      clientName += ` ${contact.lastName.trim()}`;
    }

    return clientName.trim();
  }
}
