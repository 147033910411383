<div class="sprk-o-Stack">
  <header class="sprk-o-Stack__item sprk-c-Modal__header mb-3">
    <h2 class="sprk-c-Modal__heading sprk-b-TypeDisplayFour"
        id="modalChoiceHeading">
      {{title}}
    </h2>
    <button class="sprk-c-Modal__icon"
            (click)="cancel()"
            data-sprk-modal-cancel="exampleChoiceModal"
            type="button"
            aria-label="Close Modal">
      <svg class="sprk-c-Icon"
           viewBox="0 0 64 64"
           aria-hidden="true"
           focusable="false">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </header>
  <div class="sprk-o-Stack__item sprk-c-Modal__body mb-3">
    <ng-container *ngIf="!confirmDeclineActive; else confirmContent">
      <div class="row">
        <div class="col-6">
          <ng-container *ngIf="showDropdown">
            <sprk-icon iconType="vip-filled"
                       additionalClasses="sprk-b-InlineSearch__icon"
                       sprk-input-icon
                       aria-hidden="true">
            </sprk-icon>
            <label class="ml-3 mt-1">
              Add more Agents to the Order
            </label>
            <div *ngIf="selectedAgents.length < 1"
                 class="mb-1">
              <sprk-alert alertType="fail"
                          idString="alert-info-1"
                          analyticsString="test">
                At least One Agent Should be Selected
              </sprk-alert>
            </div>
            <div class="align-self-center d-flex align-items-start flex-column col px-0">
              <angular2-multiselect name="agentSubscriptions"
                                    [data]="agents"
                                    [ngModel]="selectedAgents"
                                    [settings]="dropdownSettings"
                                    (onSelectAll)="selectAll($event)"
                                    (onDeSelectAll)="deselectAll()">
              </angular2-multiselect>
            </div>
          </ng-container>
          <div class="mt-5"
               *ngIf="!showDropdown">
            <h3 class="text-center"
                al
                sprkHeading
                variant="displayFive">Loading Agents...</h3>
          </div>
        </div>
        <div class="col"
             *ngIf="showDropdown">
          <ul class="data-list">
            <li *ngFor="let agent of selectedAgents">{{agent.agentName}}</li>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-template #confirmContent>
      <p>Are you sure you want to Decline?</p>
    </ng-template>
  </div>
  <footer>
    <ng-container *ngIf="!confirmDeclineActive; else confirmActions">
      <div class="sprk-o-Stack__item mb-3 d-flex justify-content-end">
        <button (click)="acceptOrder()"
                [disabled]="selectedAgents.length<=0"
                class="sprk-c-Button sprk-u-mrm sprk-c-Button--compact">
          Accept
        </button>
        <button class="sprk-c-Button sprk-c-Button--compact sprk-c-Button--secondary"
                (click)="declineOrder()">
          Decline
        </button>
        <button (click)="cancel()"
                class="sprk-c-Button sprk-c-Button--compact sprk-c-Button--tertiary">
          Cancel
        </button>
      </div>
      <div class="mt-2">
        <strong>{{getNotice(orderDetail?.propertyInfo?.stateCode)}}</strong>
      </div>
    </ng-container>
    <ng-template #confirmActions>
      <div class="sprk-o-Stack__item mb-3 d-flex justify-content-end">
        <button (click)="confirmDecline()"
                [disabled]="selectedAgents.length<=0"
                class="sprk-c-Button sprk-u-mrm sprk-c-Button--compact">
          Confirm
        </button>
        <button class="sprk-c-Button--compact"
                (click)="cancelDecline()">
          Cancel
        </button>
      </div>
    </ng-template>
  </footer>
</div>