<div class="row">
  <div class="col-lg-6">
    <sprk-skeleton-bone [animation]="settings.animation"
                        [theme]="settings.themes.title"></sprk-skeleton-bone>
  </div>
  <div class="col-lg-3">&nbsp;</div>
  <div class="col-lg-3">
    <sprk-skeleton-bone [animation]="settings.animation"
                        [theme]="settings.themes.largeButton"></sprk-skeleton-bone>
  </div>
</div>
<div class="sprk-c-Card sprk-o-Stack sprk-c-Card--flat">
  <div class="row">
    <div class="col-lg-3">
      <div class="p-3">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.ninety"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5">
      <div class="p-3">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.ninety"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
  <div class="row text-right">
    <div class="col-lg-9">&nbsp;</div>
    <div class="col-lg-3">
      <div class="p-3">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.ninety"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2">
    <sprk-skeleton-bone [animation]="settings.animation"
                        [theme]="settings.themes.largeButton"></sprk-skeleton-bone>
  </div>
</div>