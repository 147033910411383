import { MilestoneType } from '@enums/milestone-type.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';

export class Milestone {
  milestoneId: number;
  milestoneTitle: string;
  milestoneDescription: string;
  milestoneTypeId: MilestoneType;
  milestoneMessage: string;
  metadataJson: string;
  milestoneStatusId: MilestoneStatus;
  milestoneDefinitionId: number;
  milestoneStatusName: string;
  isCollapsed: boolean;
  createdDate: Date;
  updatedDate: Date;
  entityType: string;
  recentlyUpdated: boolean = false;
  referenceId?: string;
  titleOverride?: string;
  descriptionOverride?: string;
  title: string;
  description: string;
}
