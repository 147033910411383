import { Injectable } from '@angular/core';
import { HttpHelperService } from '@core/services/http-helper/http-helper.service';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { Milestone } from '@models/milestone';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FeatureService } from '../feature/feature.service';
import { MilestoneHelperService } from './milestone-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

  constructor(private readonly _httpHelper: HttpHelperService, private readonly _featureService: FeatureService, private readonly _milestoneHelperService: MilestoneHelperService) { }

  public getMilestones$(orderId: number): Observable<Array<Milestone>> {
    return this.apiVersion$
      .pipe(
        switchMap(version =>
          this._httpHelper
            .sendHttpOptionRequestWithRetryStrategy$<Array<Milestone>>('GET', this.createMilestonesBaseUrl(orderId), { headers: this._httpHelper.createVersionHeader(version) })),
        map(milestones => {
          milestones.forEach(x => {
            x.isCollapsed = true;
            this.setTitleAndDescription(x);
          });

          return milestones;
        })
      );
  }

  public triggerMilestoneMessageToAmp(orderId: number): Observable<any> {
    return this.apiVersion$
      .pipe(
        switchMap(version =>
          this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('PUT', this.createMilestonesBaseUrl(orderId), { headers: this._httpHelper.createVersionHeader(version) }))
      );
  }

  public update$(orderId: number, milestone: Milestone, milestoneStatus: MilestoneStatus): Observable<Milestone> {
    milestone.milestoneStatusId = milestoneStatus;
    return this.apiVersion$
      .pipe(
        switchMap(version =>
          this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<Milestone>(
            'PUT',
            `${this.createMilestonesBaseUrl(orderId)}/${milestone.milestoneDefinitionId}`,
            { body: milestone, headers: this._httpHelper.createVersionHeader(version) })),
        map(milestone => {
          this.setTitleAndDescription(milestone);
          return milestone;
        })
      );
  }

  public get apiVersion$(): Observable<number> {
    return this._featureService.milestoneApiVersion$;
  }

  private createMilestonesBaseUrl(orderId: number): string {
    return `${environment.catchApiUrl}/orders/${orderId}/milestones`;
  }

  private setTitleAndDescription(milestone: Milestone): void {
    milestone.title = this._milestoneHelperService.getMilestoneTitle(milestone);
    milestone.description = this._milestoneHelperService.getMilestoneDescription(milestone);
  }
}