import { Injectable } from '@angular/core';
import { OfficeHours, OfficeHoursSettings } from '@models/office-hours.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficeHoursService {
  public get officeHours$(): Observable<string> {
    return this.getSettingsFromApi$()
      .pipe(
        switchMap(settings => {
          const today = new Date();
          let hours: OfficeHours = (settings.new && settings.new.beginning <= today) ? settings.new : settings.current;

          return of(this.formatOfficeHours(hours));
        }));
  }

  private get officeHours(): OfficeHours {
    return {
      days: 'Monday - Friday',
      from: '8:00',
      fromMeridian: 'AM',
      to: '5:00',
      toMeridian: 'PM',
      timeZone: 'ET',
      beginning: new Date(2023, 0, 4)
    };
  };

  private get currentOfficeHours(): OfficeHours {
    return {
      days: 'Monday - Friday',
      from: '8:00',
      fromMeridian: 'AM',
      to: '6:00',
      toMeridian: 'PM',
      timeZone: 'ET',
      beginning: new Date(2022, 1, 1)
    };
  }

  private getSettingsFromApi$(): Observable<OfficeHoursSettings> {
    const settings: OfficeHoursSettings = {
      current: this.currentOfficeHours,
      new: this.officeHours
    };

    return of(settings);
  }

  private formatOfficeHours(hours: OfficeHours): string {
    const formatted: string = `${hours.days} ${hours.from} ${hours.fromMeridian} - ${hours.to} ${hours.toMeridian} ${hours.timeZone}`;

    return formatted;
  }
}
