import { ChangeDetectorRef, Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormManagerService, Classification, FormOptions, ControlMetaData, Control } from '@closing-portal/dynamic-forms';
import { MilestoneAction } from '@core/services/milestone/milestone-action';
import { MilestoneHelperService } from '@core/services/milestone/milestone-helper.service';
import { MilestoneProcessorService } from '@core/services/milestone/milestone-processor.service';
import { MilestoneQueueActionMessage } from '@core/services/milestone/milestone-queue-action-message';
import { MilestoneService } from '@core/services/milestone/milestone.service';
import { AgentStatus } from '@enums/agent-status.enum';
import { DataSource } from '@enums/data-source.enum';
import { MilestoneDefinitionId } from '@enums/milestone-definition-ids.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { MilestoneType } from '@enums/milestone-type.enum';
import { Agent } from '@models/agent';
import { Contact } from '@models/contact';
import { LesAddress } from '@models/les-address';
import { LoanData } from '@models/loan-data';
import { Milestone } from '@models/milestone';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { Property } from '@models/property';
import { clone, find } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { finalize, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';
import { SurveyTypeService } from 'src/app/modules/order-view/services/survey-type.service';
import { AddressVerifyPopupComponent } from './address-verify-popup/address-verify-popup.component';
import { FormMetaData } from '@closing-portal/dynamic-forms/lib/models/form-meta-data';
import { StatesService } from '@core/services/states/states.service';

declare let require: any

@Component({
  selector: 'app-data-milestone',
  templateUrl: './data-milestone.component.html'
})
export class DataMilestoneComponent extends OnDestroySubscriptionResolver implements OnInit, DoCheck {
  @Input() milestone: Milestone;
  @Input() canQueueMilestone = false;
  @Input() canShowUpdateMessage = false;

  public data: any = null;
  public milestoneMetaData: FormMetaData;
  public showTruncMessage = false;
  public truncatedMessage: string = null;
  public isFormatted = false;
  public formOptions: FormOptions = new FormOptions();
  public isProcessingRequest = false;
  public readonly stringLimit = 250;
  public readonly dataSource = DataSource;
  public readonly milestoneType = MilestoneType;
  public readonly milestoneStatus = MilestoneStatus;
  private _dataSourceMap: Map<DataSource, () => Observable<any>>;
  public _formName = Math.floor(Math.random() * Math.floor(10000)).toString();
  public formMeta = {
    hasSavedChanges: false,
    needsToSaveChanges: false,
    pristineValidity: null,
    formName: this._formName
  };

  private bsModalRef: BsModalRef;

  constructor(
    private readonly _orderViewService: OrderViewService,
    private readonly _milestoneService: MilestoneService,
    private readonly _toasterService: ToastrService,
    private readonly _milestoneHelperService: MilestoneHelperService,
    private readonly _milestoneProcessor: MilestoneProcessorService,
    private readonly _formManagerService: FormManagerService,
    private readonly _surveyTypesService: SurveyTypeService,
    private readonly _statesService: StatesService,
    public readonly _modalSvc: BsModalService,
    private readonly _changeDetector: ChangeDetectorRef) { super(); }

  ngOnInit() {
    this._dataSourceMap = new Map<DataSource, () => Observable<any>>([
      [DataSource.Contacts, () => this._orderViewService.contacts$],
      [DataSource.Loan, () => this._orderViewService.loanData$],
      [DataSource.SurveyOptions, () => this._surveyTypesService.surveyOptions$],
      [DataSource.Property, () => this._orderViewService.property$],
      [DataSource.Agency, () => this._orderViewService.agency$],
      [DataSource.Agents, () => this.getActiveAgents$],
      [DataSource.Milestone, () => of(this.milestone)],
      [DataSource.States, () => this._statesService.states$]
    ]);

    if (this.milestone.milestoneDefinitionId === MilestoneDefinitionId.WireInstructionsInformation
      && this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff) {
      this.milestoneMetaData = require('./form-metadata-overrides/final-wire-verification.metadata.json') as FormMetaData;
    }
    else if (this.milestone.milestoneDefinitionId === MilestoneDefinitionId.PowerOfAttorneyInformation
      && this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff) {
      this.milestoneMetaData = require('./form-metadata-overrides/final-power-of-attorney.metadata.json') as FormMetaData;
    }
    else {
      this.milestoneMetaData = this.transform(this.milestone.metadataJson, this.getPreferredFormClassification());
    }

    if (this.milestoneMetaData?.hasAddressValidation && this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff) {
      const control = new ControlMetaData();
      control.name = '';
      control.propertyKey = 'addressVerified';
      control.isRequired = true;
      control.controlType = Control.Boolean;
      control.canHideControl = true;

      this.milestoneMetaData.controls.push(control);
    }

    if (this.milestoneMetaData) {
      this.configureFormOptions();
      this.getData()
        .pipe(takeUntil(this._unsubscribe$$))
        .subscribe();
    }
  }

  ngDoCheck() {
    if (this.milestone.milestoneStatusId === MilestoneStatus.Revision && !this.isFormatted) {
      this.formatMilestoneMessage();
    }
  }

  public get getActiveAgents$(): Observable<Agent[]> {
    return this._orderViewService.agents$.pipe(map(agents => agents?.filter(agent => agent.statusCode !== AgentStatus.Deleted)));
  }

  public getDisplay(formName: string): string {
    let listOfInvalidCards = this._formManagerService.invalidCardNames;
    let isCardInvalid = false;

    if (listOfInvalidCards.includes(formName)) {
      isCardInvalid = true;
    }

    if (isCardInvalid) {
      return 'sprk-c-Card sprk-c-Card--border sprk-o-Stack slide p-4 background-color--white w-100'
    }
    else {
      return 'sprk-c-Card sprk-o-Stack slide p-4 background-color--white w-100'
    }
  }

  private getPreferredFormClassification(): Classification {
    const isInFinalSignOffMilestoneStatus = this._milestoneHelperService.isInFinalSignOffMilestoneStatus(this.milestone.milestoneStatusId);

    return isInFinalSignOffMilestoneStatus ? Classification.Secondary : Classification.Primary;
  }

  public hasLeadControl(): boolean {
    return this.milestone.milestoneTypeId === MilestoneType.DataVerify
      || (this.milestone.milestoneTypeId === MilestoneType.DataProvide
        && this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff);
  }

  public getData(): Observable<any> {
    const dataSourceObservables = new Array<Observable<any>>();

    if (this.milestoneMetaData.dataSource === null) {
      return throwError('Primary data source cannot be null');
    }

    dataSourceObservables.push(this._dataSourceMap.get(this.milestoneMetaData.dataSource)().pipe(takeUntil(this._unsubscribe$$)));

    if (this.milestoneMetaData.additionalDataSources) {
      this.milestoneMetaData.additionalDataSources.map(dataSource => dataSourceObservables.push(this._dataSourceMap.get(dataSource)()));
    }

    return combineLatest(dataSourceObservables).pipe(map((responses: Array<any>) => {
      this.data = responses[0];

      if (this.milestoneMetaData.additionalDataSources && this.data) {
        responses.map((value, index) => {
          this.data[`${this.milestoneMetaData.additionalDataSources[index - 1]}`] = value || null
        });
      }

      this._changeDetector.detectChanges();

    }), takeUntil(this._unsubscribe$$));
  }

  public onFormSubmit(data: any): void {
    const milestone = clone(this.milestone);
    const submitAction = this.getFormSubmitAction(data).pipe(first());

    if (this.canQueueMilestone && (data === true || this.formOptions.addressVerified)) {
      this.setAddressVerifiedTrue();

      this._milestoneProcessor.dequeue(this.milestone.milestoneId);
    }
    else
      if (this.canQueueMilestone) {
        const milestoneAction = new MilestoneQueueActionMessage(milestone, submitAction, this.milestoneMetaData.dataSource);
        if (this.milestoneMetaData.hasAddressValidation && data !== true) {
          this.processAddressVerification(data, milestoneAction, milestone);
        }
        else {
          this._milestoneProcessor.enqueue(milestoneAction);
        }
      } else {
        const milestoneAction = new MilestoneAction(milestone, submitAction, this.milestoneMetaData.dataSource);
        this.isProcessingRequest = true;
        if (this.milestoneMetaData.hasAddressValidation && data !== true) {
          this.processAddressVerification(data, milestoneAction, milestone);
        } else {
          this.processRequest(this._milestoneProcessor.process(milestoneAction)).subscribe();
        }
      }
  }

  public transform(stringifiedMilestoneMetaData: string, classification: Classification = Classification.Primary): FormMetaData {
    if (!stringifiedMilestoneMetaData) {
      return null;
    }

    const parsedMetaData = JSON.parse(stringifiedMilestoneMetaData);

    return !Array.isArray(parsedMetaData) ? parsedMetaData as FormMetaData : this.getFormControl(parsedMetaData, classification);
  }

  public formatMilestoneMessage(): void {
    if (this.milestone.milestoneMessage?.length > 0) {
      this.milestone = this._milestoneHelperService.processRevisionSubtitles(this.milestone);

      this.showTruncMessage = this.milestone.milestoneMessage.length > this.stringLimit;
      this.isFormatted = true;
    }
  }

  private configureFormOptions(): void {
    this.formOptions.preFillTriggerTargetOptions = this.milestoneMetaData.preFillTriggerTargetOptions;
    this.formOptions.hideLeadControl = this.canHideLeadControl();
    this.formOptions.formTitle = this.getFormTitle();
    this.formOptions.hasPrimaryLeadQuestionControl = this.hasLeadControl();
    this.formOptions.primaryLeadQuestionControlPath = this.milestoneMetaData.primaryLeadQuestionControlPath;
    this.formOptions.hasOverrideReadonlyDisplay = this.hasOverrideReadonlyDisplay();
    this.formOptions.canOverridePreFill = this.milestone.milestoneStatusId !== MilestoneStatus.New;
    this.formOptions.canManageForm = this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff;
    this.formOptions.additionalTitleClasses = this.milestoneMetaData.additionalTitleClasses;
    this.formOptions.addressVerified = false;

    if (this.milestone.milestoneDefinitionId === MilestoneDefinitionId.ClientInformation) {
      this.formOptions.formArrayOptions.delimiterOffset = 2;
    }

    if (this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff) {
      this.formOptions.canListenForLeadQuestionValueChange = true;
      this.formOptions.buttonText = 'Save Update';
      this.formOptions.canHideButton = true;
    }
  }

  private processRequest(obs: Observable<any>): Observable<any> {
    return obs.pipe(
      first(),
      finalize(() => this.isProcessingRequest = false)
    );
  }

  private canHideLeadControl(): boolean {
    return this.milestone.milestoneStatusId > MilestoneStatus.New && this.milestone.milestoneStatusId < MilestoneStatus.FinalSignOff;
  }

  private hasOverrideReadonlyDisplay(): boolean {
    return this.milestoneMetaData.dataSource === DataSource.Property
      || this.milestoneMetaData.overRideReadOnlyForWholeForm === true;
  }

  private getFormTitle(): string {
    return this.milestoneMetaData?.showFormTitle &&
      (this.milestone.milestoneStatusId !== MilestoneStatus.FinalSignOff
        && this.milestone.milestoneTypeId !== MilestoneType.DataVerify)
      ? this.milestone.title : null;
  }

  private getFormControl(forms: Array<FormMetaData>, classification: Classification): FormMetaData {
    return find(forms, { classification }) ?? forms[0];
  }

  private getFormSubmitAction(data: any): Observable<any> {
    if (data === true) {
      return this._orderViewService.orderId$.pipe(
        takeUntil(this._unsubscribe$$),
        switchMap(orderId => this._milestoneService.update$(orderId, this.milestone, MilestoneStatus.Complete))
      );
    }

    return this.getDataUpdateAction(data);
  }

  private getDataUpdateAction(data: any): Observable<Contact[]> | Observable<LoanData> | Observable<Property> | Observable<LesAddress> {
    switch (this.milestoneMetaData.dataSource) {
      case DataSource.Contacts:
        return this._orderViewService.updateContacts$(this.milestone.milestoneDefinitionId, data['contacts'] as Array<Contact>, !this.canQueueMilestone);
      case DataSource.Loan:
        return this._orderViewService.updateLoanData$(this.milestone.milestoneDefinitionId, data as LoanData, !this.canQueueMilestone);
      case DataSource.Property:
        return this._orderViewService.updateProperty$(this.milestone.milestoneDefinitionId, data as Property, !this.canQueueMilestone);
    }
  }

  private processAddressVerification(data: any, milestoneAction: MilestoneAction, milestone: Milestone): void {
    this._orderViewService.verifyAddress(this.generateAddressLines(data))
      .subscribe(addr => {
        if (addr.addressValidationStatus == 'Approved') {
          this.setAddressVerified(true);
          this.formOptions.addressVerified = true;
          if (milestone.milestoneStatusId == MilestoneStatus.FinalSignOff) {
            this._milestoneProcessor.enqueue(milestoneAction);
          }
          else {
            this.processRequest(this._milestoneProcessor.process(milestoneAction)).subscribe();
          }
        } else if (addr.addressValidationStatus == 'Corrected') {
          this._modalSvc.onHide.pipe(takeUntil(this._unsubscribe$$)).subscribe(option => {
            this.onModalHideEvent(data, milestoneAction, milestone, option, addr);
          });

          this.openAddressModalPopup(data, addr, false);
        }
      }, err => {
        if (err.status == 404) {
          this._modalSvc.onHide.pipe(takeUntil(this._unsubscribe$$)).subscribe(option => {
            this.onModalHideEvent(data, milestoneAction, milestone, option, null);
          })
          this.openAddressModalPopup(data, null, true);
        }
        else {
          this.isProcessingRequest = false;
          this._toasterService.error(`An error has occurred and we are unable to process their information. Please try again.  
          If the error still occurs plese contact our support team`);
        }
      })
  }

  private setAddressVerifiedTrue(): void {
    if (this.milestoneMetaData.hasAddressValidation && this.milestone.milestoneStatusId === this.milestoneStatus.FinalSignOff) {
      this.setAddressVerified(true);
    }
  }

  private setAddressVerified(value: boolean): void {
    this._formManagerService.updateForm(this.formMeta, (cont) => {
      cont['addressVerified'].setValue(value);
    });
  }

  private onModalHideEvent(data: any, milestoneAction: MilestoneAction, milestone: Milestone, selection: string, addr: LesAddress): void {
    switch (selection.toLowerCase()) {
      case 'ignore':
        if (milestone.milestoneStatusId == MilestoneStatus.FinalSignOff) {
          this.setAddressVerified(true);
          this._milestoneProcessor.enqueue(milestoneAction);
        }
        else {
          this.processRequest(this._milestoneProcessor.process(milestoneAction)).subscribe();
        }
        this.formOptions.addressVerified = true;
        break;
      case 'confirm':
        this.data = this.updateAddressWithLesAddress(data, addr);
        const updatedAddressSubmitAction = this.getFormSubmitAction(this.data).pipe(first());
        const updatedAddressMilestoneAction = new MilestoneAction(milestone, updatedAddressSubmitAction, this.milestoneMetaData.dataSource);

        if (milestone.milestoneStatusId == MilestoneStatus.FinalSignOff) {
          this._milestoneProcessor.enqueue(updatedAddressMilestoneAction);
          this.setAddressVerified(true);

          this.syncFormWithLatest();
        }
        else {
          this.processRequest(this._milestoneProcessor.process(updatedAddressMilestoneAction)).subscribe();
        }

        this.formOptions.addressVerified = true;
        break;
      case 'cancel':
      default:
        if (this.milestone.milestoneStatusId === MilestoneStatus.FinalSignOff) {
          this.setAddressVerified(null);
        }
        this.isProcessingRequest = false;
        this.formOptions.addressVerified = false;
        break;
    }
  }

  private syncFormWithLatest(): void {
    this._formManagerService.updateForm(this.formMeta, (controls) => {
      for (let i in this.milestoneMetaData.addressFields) {
        controls[this.milestoneMetaData.addressFields[i]].setValue(this.data[this.milestoneMetaData.addressFields[i]]);
      }
    });
  }

  private updateAddressWithLesAddress(data: any, addr: LesAddress): any {
    data[this.milestoneMetaData.addressFields[0]] = addr.addressLines[0];
    data[this.milestoneMetaData.addressFields[1]] = addr.addressLines[1] || '';
    data[this.milestoneMetaData.addressFields[2]] = addr.locality;
    data[this.milestoneMetaData.addressFields[3]] = addr.stateCode;
    data[this.milestoneMetaData.addressFields[4]] = addr.postCode;

    return data;
  }

  private generateAddressLines(data: any): Array<string> {
    let addressLines: Array<string> = [];
    addressLines.push(`${data[this.milestoneMetaData.addressFields[0]]} ${data[this.milestoneMetaData.addressFields[1]]}`);
    addressLines.push(`${data[this.milestoneMetaData.addressFields[2]]} ${data[this.milestoneMetaData.addressFields[3]]} ${data[this.milestoneMetaData.addressFields[4]]}`);

    return addressLines;
  }

  private openAddressModalPopup(data: any, address: LesAddress, noAddressFoundFlag: boolean): void {
    const providedAddress: LesAddress = {
      addressLines: [data[this.milestoneMetaData.addressFields[0]], data[this.milestoneMetaData.addressFields[1]]],
      locality: data[this.milestoneMetaData.addressFields[2]],
      stateCode: data[this.milestoneMetaData.addressFields[3]],
      postCode: data[this.milestoneMetaData.addressFields[4]]
    }

    this.bsModalRef = this._modalSvc.show(AddressVerifyPopupComponent, {
      class: 'sprk-c-Modal address-modal',
      ignoreBackdropClick: true,
      initialState: {
        address: address,
        providedAddress: providedAddress,
        noAddressFound: noAddressFoundFlag
      }
    })
  }
}
