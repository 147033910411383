export enum ContactType {
    'Client' = "Client",
    'Closer' = "Closer",
    'LoanAnalyst' = "Loan Analyst",
    'QLMSBroker' = "QLMS Broker",
    'SellersAttorney' = "Seller's Attorney",
    'Realtor' = "Realtor",
    'MortgageBroker'= 'Mortgage Broker',
    'PartnerLoanOfficer' = 'Partner Loan Officer',
    'PartnerLoanProcessor' = 'Partner Loan Processor'
}
