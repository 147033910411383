import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent {
  @Input() tabLabel: string;
  @Input() tabId: string;
  @Input() activeTab = true;
  @Input() isLocked = false;
  
  @Output() clickEvent: EventEmitter<Event> = new EventEmitter();
}
