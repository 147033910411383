<div class="row">
  <div class="col">
    <sprk-skeleton-bone [animation]="settings.animation"
                        [theme]="settings.themes.largeButton">
    </sprk-skeleton-bone>
    <div>
      <div sprkheading
           class="mb-4 text-center sprk-b-TypeDisplayFour">
        <sprk-skeleton-bone [theme]="settings.themes.title"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="primary-contact--content">
      <div sprkHeading>
        <sprk-skeleton-bone appearance="circle"
                            [animation]="settings.animation"
                            [theme]="settings.themes.circle"></sprk-skeleton-bone>
        Primary Contact
      </div>
      <div class="align-self-center d-flex align-items-stretch flex-column col px-0">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.largeButton">
        </sprk-skeleton-bone>
      </div>
    </div>
  </div>
</div>