<div id="general-milestone-list">
  <table id="general-milestone-table"
         class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray"
         aria-label="general-milestone-table">
    <thead sprkTableHead>
      <tr>
        <th id="status"
            width="18%">Status</th>
        <th id="toDoItem">To Do Item</th>
        <th id="action"><span></span></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let milestone of milestones">
        <tr id="milestone-definition-{{milestone.milestoneDefinitionId}}">
          <td>
            <app-milestone-status [milestoneStatusId]="milestone.milestoneStatusId"
                                  [milestoneTitle]="milestone.milestoneStatusName"
                                  [canShowTitle]=true>
            </app-milestone-status>
          </td>
          <td>
            {{ milestone.title }}
            <sprk-icon *ngIf="milestone?.milestoneTitle"
                       iconType="information"
                       data-toggle="tooltip"
                       data-tooltip-hover
                       data-placement="left"
                       [title]="milestone.description">
            </sprk-icon>
        <tr *ngIf="milestone?.milestoneDefinitionId === 12 && milestone?.milestoneStatusId === milestoneStatus.Complete">
          <app-document-dropdown [documents]="closingPackageDocuments"
                                 [(documentId)]="selectedDocumentId">
          </app-document-dropdown>
        </tr>
        </td>
        <td>
          <div class="d-flex justify-content-end align-items-center">
            <ng-template [ngIf]="isFinalMilestone(milestone)">
              <label class="sprk-b-Link"
                     role="button"
                     data-toggle="tooltip"
                     data-placement="left"
                     title="Tooltip on left"
                     placement="auto"
                     (click)="navigateToFinalReview()">
                Edit
              </label>
            </ng-template>
            <ng-template [ngIf]="isDataMilestone(milestone.milestoneTypeId) && !isFinalMilestone(milestone)">
              <label class="sprk-b-Link"
                     routerLink="documents"
                     role="button"
                     data-toggle="tooltip"
                     data-placement="left"
                     [title]="milestone.title"
                     placement="auto"
                     (click)="toggle(milestone)">
                Edit
              </label>
            </ng-template>
            <ng-template [ngIf]="isDocumentMilestone(milestone.milestoneTypeId)">
              <ng-template [ngIf]="documentFeatureVersion === 1">
                <label *ngIf="milestone.milestoneDefinitionId !== 12"
                       for="file-upload-{{milestone.milestoneDefinitionId}}"
                       class="sprk-b-Link mr-3">
                  Upload
                </label>
                <input id="file-upload-{{milestone.milestoneDefinitionId}}"
                       type="file"
                       style="display:none"
                       ngFileSelect
                       [options]="this.documentUploaderService.options"
                       [uploadInput]="uploadInput"
                       [attr.multiple]="canUploadMultipleFiles ? true: null"
                       (uploadOutput)="documentUploaderService.onUploadOutput($event, milestone); goToTop()" />
                <label [ngClass]="{'sprk-b-Link': !downloadInProgress}"
                       *ngIf="(milestone.milestoneDefinitionId === 12 && selectedDocumentId)"
                       idString="default-link"
                       analyticsString="default-link"
                       (click)="!downloadInProgress && downloadDocument(milestone.milestoneDefinitionId, selectedDocumentId, milestone.referenceId)">
                  {{downloadInProgress ? 'Downloading...' : 'Download'}}
                </label>
              </ng-template>
              <label class="sprk-b-Link"
                     *ngIf="milestone?.milestoneStatusId !== milestoneStatus.New && milestone.milestoneDefinitionId != 12"
                     idString="default-link"
                     analyticsString="default-link"
                     (click)="toggle(milestone)">
                View History
              </label>
            </ng-template>
          </div>
        </td>
        </tr>
        <tr id="RowX"
            *ngIf="(milestone.milestoneTypeId == milestoneType.DataVerify || milestone.milestoneTypeId == milestoneType.DataProvide) && milestone.isCollapsed === false"
            colspan="3">
          <td colspan="3"
              class="background-color--off-white overflow-hidden">
            <app-data-milestone [milestone]="milestone"></app-data-milestone>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tbody *ngIf="!!milestones && milestones?.length === 0">
      <tr>
        <td colspan="3">
          <article class="d-flex justify-content-center align-items-center">
            <header>
              <h6>There are currently no To-Do requests</h6>
            </header>
          </article>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-container *ngIf="historyMilestone">
  <app-document-history-modal [milestone]="historyMilestone"
                              [title]="historyMilestone.title"
                              (onClose)="closeHistoryView()"></app-document-history-modal>
</ng-container>
