import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeComponent } from '@shared/components/subscribe/subscribe.component';
import { MatIconModule } from '@angular/material/icon';
import { SubscriptionStatusIconsV2 } from '@enums/subscription-status-icons-enum-v2';
import { SubscriptionStatus } from '@enums/subscription-status.enum';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'catch-subscribe',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './subscribe-v2.component.html',
})
export class SubscribeComponentV2 extends SubscribeComponent{
  public subscriptionStatusIconV2: SubscriptionStatusIconsV2 = SubscriptionStatusIconsV2.unsubscribed;

  protected setBookmarkStyle(): void {
    switch (this._subscriptionStatus) {
      case SubscriptionStatus.primaryAgent:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.primaryAgent;
        break;
      case SubscriptionStatus.subscribed:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.subscribed;
        break;
      default:
        this.subscriptionStatusIconV2 = SubscriptionStatusIconsV2.unsubscribed;
    }
  }
}
