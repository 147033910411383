import { Pipe, PipeTransform } from '@angular/core';
import { SearchResults } from '@models/search-results';

@Pipe({
  name: 'favoriteOrders'
})
export class FavoriteOrdersPipe implements PipeTransform {

  transform(searchResult: SearchResults, agentId: number): SearchResults {
    if (!searchResult)
      return searchResult;

    searchResult.orderDetails = searchResult.orderDetails.sort((x, y) => {
      switch (x.subscriptions.filter(sub => sub.agentId === agentId).length > 0) {
        case true:
          return -1;
        case false:
          return 1;
      }
    });

    return searchResult;
  }

}
