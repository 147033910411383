import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '@core/services/user/user.service';
import { AssociatedAgency, UserProfile } from '@models/user-profile';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { ISprkDropdownChoice } from '@sparkdesignsystem/spark-angular/lib/components/sprk-dropdown/sprk-dropdown.interfaces';

@Component({
  selector: 'app-agency-selection',
  templateUrl: './agency-selection.component.html',
  styleUrls: ['./agency-selection.component.scss']
})
export class AgencySelectionComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @Input() isReadonly = false;
  @Output() agencyChange = new EventEmitter<number>();

  public choices: Array<ISprkDropdownChoice> = [];
  public selectedAgencyId: number;
  public triggerText = 'Select an Agency';
  public readonlyAgencyInformation = null;
  private _agencyMap: Map<string, AgencyDisplayContent> = new Map<string, AgencyDisplayContent>();

  constructor(private readonly _userService: UserService, private readonly _customEventSvc: CustomEventsService) {
    this.selectedAgencyId = this._userService.targetAgencyId;
  }

  ngOnInit(): void {
    this.userProfile?.associatedAgencies
      .filter(agency => !!agency)
      .map(agency => {
        const agencyAddress = this.formatAgencyAddress(agency);
        const triggerText = `${agency.name?.toString()} | ${agencyAddress}`;

        if (this.selectedAgencyId && agency.agencyId === this.selectedAgencyId) {
          this.triggerText = triggerText;
          this.readonlyAgencyInformation = triggerText;
        }

        this.configureAgencyDisplayContent(agency, triggerText, agencyAddress);
      });
  }

  public onAgencySelection(event: any): void {
    let agencyId = +this._agencyMap.get(event)?.key;
    this._customEventSvc.pushAgencyChangeEvent({
      agency_id: agencyId
    })
    this.agencyChange.next(agencyId);
  }

  private configureAgencyDisplayContent(agency: AssociatedAgency, triggerText: string, infoLine1: string): void {
    const agencyDisplayContent = new AgencyDisplayContent(
      agency?.agencyId?.toString(),
      agency?.name,
      infoLine1);

    this._agencyMap.set(triggerText, agencyDisplayContent);

    this.choices.push({
      content: {
        title: agencyDisplayContent.title,
        infoLine1: agencyDisplayContent.infoLine1
      },
      value: triggerText,
      active: agency?.agencyId === this.selectedAgencyId
    });
  }


  private formatAgencyAddress(agency: AssociatedAgency): string {
    const address = `${agency.streetAddress1 || ''} ${agency.streetAddress2 || ''}, ${agency.city || ''}, ${agency.stateCode || ''} ${agency.zip || ''}`;

    return address.replace(new RegExp(' ,', 'g'), ',')
      .replace(new RegExp(',,', 'g'), '');
  }
}

class AgencyDisplayContent {
  key: string
  title: string;
  infoLine1?: string;
  infoLine2?: string;

  constructor(key: string, title: string, infoLine1?: string, infoLine2?: string) {
    this.key = key;
    this.title = title;
    this.infoLine1 = infoLine1;
    this.infoLine2 = infoLine2;
  }
}
