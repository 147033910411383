<ng-container *ngIf="milestones">
  <div id="data-milestone-list">
    <ng-container *ngFor="let milestone of milestones; let i = index; first as isFirst;">
      <div [class]="{ 'my-4': !isFirst }"
           id="milestone-definition-{{milestone.milestoneDefinitionId}}">
        <app-data-milestone [milestone]="milestone">
        </app-data-milestone>
      </div>
    </ng-container>
  </div>
</ng-container>