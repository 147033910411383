import { Injectable } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';
import { OrderStatusListItem } from '@models/order-status-list-item';
import { OrderStatusPipe } from '@shared/pipes/order-status-pipe/order-status-pipe';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {
  private _viewableOrderStatuses: Array<OrderStatus> = [OrderStatus.Completed, OrderStatus.Open, OrderStatus.Closing];
  private _clickableOrderStatuses: Array<OrderStatus> = [OrderStatus.New, OrderStatus.Completed, OrderStatus.Open, OrderStatus.Closing];
  private _orderReloaded$: Subject<boolean> = new Subject<boolean>();
  private _reloadExcludedRoutes: Array<string> = ['/milestones', '/messages', '/final'];
  private _orderStatusPipe = new OrderStatusPipe();

  public get orderReloaded$(): Observable<boolean> {
    return this._orderReloaded$.asObservable();
  }

  public shouldReload(orderId: number, url: string): boolean {
    let shouldReload: boolean = false;
    let orderUrls: Array<string> = [];

    this._reloadExcludedRoutes.forEach(route => {
      const urlExp = `${orderId}${route}`;
      orderUrls.push(urlExp);
    });
    shouldReload = orderUrls.map(u => url.indexOf(u) < 0).filter(f => f === false).length === 0;

    return shouldReload;
  }

  public reloadOrder$(value: boolean): void {
    this._orderReloaded$.next(value);
  }

  public canViewOrder(orderStatusId: number): boolean {
    return this._viewableOrderStatuses.includes(orderStatusId);
  }

  public canClickOrder(orderStatusId: number): boolean {
    return this._clickableOrderStatuses.includes(orderStatusId);
  }

  public get orderStatusList(): Array<OrderStatusListItem> {
    return Object.keys(OrderStatus).filter(v => isNaN(Number(v)))
      .map(key => ({
        statusId: OrderStatus[key],
        statusName: this._orderStatusPipe.transform(OrderStatus[key])
      }));
  }
}
