import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-print-button',
  templateUrl: './print-button.component.html'
})
export class PrintButtonComponent {

  @Input()
  additionalClasses: string;

  @Output()
  print: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    this.print.emit();
  }
}