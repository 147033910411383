import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/Auth0/auth.service';
import * as signalR from "@microsoft/signalr";
import { SignalRMessage } from '@models/signalR-message';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public message$$: ReplaySubject<SignalRMessage> = new ReplaySubject<SignalRMessage>(1);
  private _connection: signalR.HubConnection;
  private _connection$$: ReplaySubject<signalR.HubConnection> = new ReplaySubject<signalR.HubConnection>(1);
  private _inGroup = false;

  constructor(
    private readonly _authService: AuthService
  ) {
    this.buildConnection();
  }

  public get connection$$(): ReplaySubject<signalR.HubConnection> {
    return this._connection$$;
  }

  public get inGroup(): boolean {
    return this._inGroup;
  }

  public buildConnection(): void {
    if (this._connection == null) {
      this._authService.getTokenSilently$().pipe(first()).subscribe(token => {
        this._connection = new signalR.HubConnectionBuilder()
          .withUrl(`${environment.catchApiUrl}/signalr`, {
            accessTokenFactory: () => token,
            transport: signalR.HttpTransportType.WebSockets,
            skipNegotiation: true
          }).build();

          this.startConnection();
      });
    }
    else if (this._connection.state !== signalR.HubConnectionState.Connected) {
      this.startConnection();
    }
  }

  public closeConnection(): void {
    this._connection?.stop();
  }

  public joinGroup(groupId: string): void {
    this._connection.send("joinGroup", groupId);
    this._inGroup = true;
  }

  public leaveGroup(groupId: string): void {
    this._connection.send("leaveGroup", groupId);
    this._inGroup = false;
  }

  private startConnection(): void {
    this._connection.start().then(_ => {
      this._connection$$.next(this._connection);
      
      this._connection.on("ReceiveMessage", (resource: string, data: string) => {
        this.message$$.next(new SignalRMessage(resource, data));
      });
    });
  }
}
