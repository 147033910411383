import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatus } from '@enums/order-status.enum';
import { OrderDetail } from '@models/order-detail';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { ApplicationPaths } from 'src/app/app-paths.enum';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderRouterService {

  constructor(private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _customEventService: CustomEventsService,) { }

  public routeToOrderView(orderDetails: OrderDetail, location: string, skipLocationChange: boolean = false): void {
    if (orderDetails.orderStatusId == OrderStatus.Open || orderDetails.orderStatusId == OrderStatus.Closing || orderDetails.orderStatusId == OrderStatus.Completed) {
      this._userService.targetAgencyId = orderDetails.agencyId;

      if (skipLocationChange)
        this._router
          .navigateByUrl(`${ApplicationPaths.Orders}`, { skipLocationChange: true })
          .then(() => this._router.navigateByUrl(`${ApplicationPaths.Orders}/${orderDetails.orderId}`));
      else
        this._router.navigateByUrl(`${ApplicationPaths.Orders}/${orderDetails.orderId}`);

      this._customEventService.pushOrderAccessedEvent({
        access_location: location,
        order_status: orderDetails.orderStatusId
      });
    }
  }
}
