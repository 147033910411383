import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClosingType } from '@models/closing-type';
import { Contact } from '@models/contact';
import { LesAddress } from '@models/les-address';
import { LoanData } from '@models/loan-data';
import { Order } from '@models/order';
import { Property } from '@models/property';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClosingTypeService } from '../closing-type/closing-type.service';
import { FeatureService } from '../feature/feature.service';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _httpHelper: HttpHelperService,
    private readonly _featureService: FeatureService,
    private readonly _closingTypeService: ClosingTypeService
  ) { }

  private readonly _orderServiceUrl: string = `${environment.catchApiUrl}/orders`;

  public auth(orderId: number): Observable<HttpResponse<any>> {
    return this._httpClient.get<any>(`${environment.catchApiUrl}/orders/${orderId}/auth`);
  }

  public getOrders$(page: number = 1, pageSize: number = 10, orderStatus: number = 0): Observable<Order[]> {
    return this._httpClient.get<Order[]>(`${environment.catchApiUrl}/Orders?page=${page}&pageSize=${pageSize}&orderStatus=${orderStatus}`);
  }

  public getOrder$(orderId: number): Observable<Order> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<Order>('GET', `${this.createOrderRoutePath(orderId)}`);
  }

  public updateOrderStatus$(orderId: number, orderStatusId: number, loanNumber: string): Observable<Order> {
    return this._httpClient.put<Order>(`${this.createOrderRoutePath(orderId)}/status/${orderStatusId}`, { loanNumber });
  }

  public getProperty$(orderId: number): Observable<Property> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<Property>('GET', `${this.createOrderRoutePath(orderId)}/property`)
      .pipe(shareReplay(1));
  }

  public updateProperty$(orderId: number, milestoneDefinitionId: number, property: Property) {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('PUT', `${this.createOrderRoutePath(orderId)}/property?milestoneDefinitionId=${milestoneDefinitionId}`,
      { body: property });
  }

  public getLoanData$(orderId: number): Observable<LoanData> {
    return this.loanApiVersion$
      .pipe(
        switchMap(version => {
          return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<LoanData>('GET', `${this.createOrderRoutePath(orderId)}/loans`, { headers: this._httpHelper.createVersionHeader(version) })
        }));
  }

  public getClosingType$(orderId: number): Observable<ClosingType> {
    return this._closingTypeService.getClosingType$(orderId);
  }

  public updateLoanData$(orderId: number, milestoneDefinitionId: number, loanData: LoanData) {
    return this.loanApiVersion$
      .pipe(
        switchMap(version =>
          this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('PUT', `${this.createOrderRoutePath(orderId)}/loans?milestoneDefinitionId=${milestoneDefinitionId}`,
            { body: loanData, headers: this._httpHelper.createVersionHeader(version) }))
      );
  }

  public getContacts$(orderId: number): Observable<Array<Contact>> {
    return this._httpHelper.sendHttpRequestWithRetryStrategy$<Array<Contact>>('GET', `${this.createOrderRoutePath(orderId)}/contacts`);
  }

  public updateContacts$(orderId: number, milestoneDefinitionId: number, contacts: Array<Contact>) {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('PUT', `${this.createOrderRoutePath(orderId)}/contacts?milestoneDefinitionId=${milestoneDefinitionId}`,
      { body: contacts });
  }

  public updatePrimaryAgent$(orderId: number, primaryAgentId: number, requestBody: any) {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$('PUT', `${this.createOrderRoutePath(orderId)}/agents/${primaryAgentId}`, {
      body: requestBody
    });
  }

  public verifyAddress$(addressLines: Array<string>): Observable<LesAddress> {
    return this._httpHelper.sendHttpOptionRequestWithRetryStrategy$<LesAddress>('POST', `${environment.catchApiUrl}/location/verify`, {
      body: addressLines
    })
  }

  private createOrderRoutePath(orderId: number): string {
    return `${this._orderServiceUrl}/${orderId}`;
  }

  private get loanApiVersion$(): Observable<number> {
    return this._featureService.loanApiVersion$;
  }
}
