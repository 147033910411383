<div *ngIf="data"
     [id]="_formName"
     [class]="getDisplay(_formName)"
     style="max-width: none;">
  <ng-container *ngIf="milestone?.milestoneMessage != null">
    <div class="row">
      <div class="col"
           [class]="{'multi-line-truncate': showTruncMessage}">
        <span class="font-weight-bold pr-2">REVISION REQUESTED:</span>
        <span class="white-space-pre-line"
              [innerHTML]="milestone.milestoneMessage"></span>
      </div>
      <div class="col-auto d-flex justify-content-center pt-1"
           *ngIf="milestone.milestoneMessage.length > stringLimit">
        <p>
          <label class="sprk-b-Link"
                 sprkLink
                 idString="default-link"
                 analyticsString="default-link"
                 (click)="showTruncMessage = !showTruncMessage">
            {{showTruncMessage ? "+" : "-"}} {{showTruncMessage ? "Show" : "Hide"}} All
          </label>
        </p>
      </div>
    </div>
  </ng-container>
  <app-dynamic-form [controlMetaDataCollection]="milestoneMetaData.controls"
                    [data]="data"
                    [formOptions]="formOptions"
                    [isProcessingRequest]="isProcessingRequest"
                    (formSubmit)="onFormSubmit($event)"
                    [formMeta]="formMeta">
    <ng-container *ngIf="milestoneMetaData.dataSource === dataSource.Property">
      <p class="color--sprk-black-tint-50">
        {{ data['streetAddress1'] || '(Street Address 1 Provided)' }}
        <ng-container *ngIf="data['streetAddress2']">{{
          data['streetAddress2']
        }}</ng-container>
        <br />
        {{ data['city'] || '(City Not Provided)' }},
        {{ data['stateCode'] || '(State was Not Provided)' }},
        {{ data['zipCode' || '(Zip was Not Provided)'] }}
        <br />
        <strong>Property Type: </strong>{{ data['propertyType'] || '(Property Type Not Provided' }}
      </p>
    </ng-container>
  </app-dynamic-form>
</div>