import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@core/services/user/user.service';
import { PreferenceCodeEnum } from '@enums/preference-code.enum';
import { AgentPreference } from '@models/agentPreference';
import { Preference } from '@models/preference';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-user-survey',
  templateUrl: './user-survey.component.html'
})
export class UserSurveyComponent implements OnInit {
  @Input() agentPrefs: Array<AgentPreference>;
  public selection: string;
  private _preferences: Array<Preference> = [];
  private _userSurveyPreference: Preference;

  constructor(
    private readonly _userService: UserService,
    private readonly _modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this._userService.preferences$$.subscribe(prefs => {
      this._preferences = prefs;
      this._userSurveyPreference = prefs.find(p => p.preferenceCode == PreferenceCodeEnum.UserSurvey);
    });
  }

  public get preferences(): Array<Preference> {
    return this._preferences;
  }

  public get userSurveyPreference(): Preference {
    return this._userSurveyPreference;
  }

  public submit(): void {
    if(this.selection != null){
      this.prepDataForSubmission().pipe(switchMap(_ =>{
        return this._userService.saveAgentPreferences(this.agentPrefs);
      })).subscribe();
      this._modalService.hide(1);
    }
  }

  private prepDataForSubmission(): Observable<void> {
    return this._userService.userProfile$.pipe(
      take(1),
      map(userProfile => {
        this.agentPrefs.push({
          agentId: userProfile.agentId,
          preferenceId: this._userSurveyPreference.preferenceId,
          preferenceCode: this._userSurveyPreference.preferenceCode,
          preferenceValue: this.selection
        });
      }));
  }
}
