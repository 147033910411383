import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LocalizedString } from '@angular/compiler';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: Date | string | number): string | null {
    const pipe = new DatePipe(this.locale);
    if (value == "0001-01-01T00:00:00") value = null;
    return pipe.transform(value);
  }

}
