<table id="document-upload-milestone-table"
       class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray"
       aria-label="document-upload-milestone-table">
  <thead sprkTableHead>
    <tr class="no-print">
      <th id="toDoRequest">Document</th>
      <th id="action"><span></span></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let milestone of milestones; let i = index">
      <tr>
        <td class="max-width-25rem">
          {{ milestone.title }}
          <sprk-icon *ngIf="milestone?.milestoneTitle"
                     iconType="exclamation"
                     data-toggle="tooltip"
                     data-placement="left"
                     title="{{ milestone.description }}">
          </sprk-icon>
          <div *ngIf="milestone?.milestoneStatusId === milestoneStatus.Revision"
               [ngClass]="{'multi-line-truncate': hideRevision[i]}">
            <span class="font-weight-bold pr-1">REVISION REQUESTED:</span>
            <span class="white-space-pre-line"
                  [innerHTML]="milestone.milestoneMessage"></span>
          </div>
        </td>
        <td class="d-flex justify-content-end">
          <button class="sprk-c-Button sprk-c-Button--secondary sprk-c-Button--compact min-width-120px"
                  routerLink="documents"
                  role="button"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Tooltip on left"
                  placement="auto"
                  (click)="getDocumentMetaData(milestone.milestoneDefinitionId, milestone.referenceId)"
                  [disabled]="downloadInProgress"
                  *ngIf="milestone?.milestoneStatusId !== milestoneStatus.New && milestone?.milestoneStatusId !== milestoneStatus.Revision">
            <ng-container *ngIf="!downloadInProgress">Download</ng-container>
            <div *ngIf="downloadInProgress"
                 class="sprk-c-Spinner sprk-c-Spinner--circle sprk-c-Spinner--secondary"></div>
          </button>
          <label class="cursor-pointer pt-3"
                 sprkLink
                 idString="default-link"
                 analyticsString="default-link"
                 (click)="hideRevision[i] = !hideRevision[i]"
                 *ngIf="showHideButton(milestone)">
            {{hideRevision[i] ? "+" : "-"}} {{hideRevision[i] ? "Show" : "Hide"}} All
          </label>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="!!milestones && milestones?.length === 0">
    <tr>
      <td colspan="3">
        <article class="d-flex justify-content-center align-items-center">
          <header>
            <h6>No documents needed at this time</h6>
          </header>
        </article>
      </td>
    </tr>
  </tbody>
</table>