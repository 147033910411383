import { NgxSkeletonLoaderConfig, NgxSkeletonLoaderConfigTheme } from "ngx-skeleton-loader";
import { Animation, Appearance } from "../enums/skeleton-enums";

export interface ISkeletonSettings {
  appearance: NgxSkeletonLoaderConfig['appearance']
  animation: NgxSkeletonLoaderConfig['animation'];
  themes:
  {
    default: SparkThemes['default'];
    circle: SparkThemes['circle'];
    square_25: SparkThemes['square_25'];
    square_100: SparkThemes['square_100'];
    square_500: SparkThemes['square_500'];
    smallButton: SparkThemes['smallButton'];
    largeButton: SparkThemes['largeButton'];
    uploader: SparkThemes['uploader'];
    title: SparkThemes['title'];
    ninety: SparkThemes['ninety'];
    banner: SparkThemes['banner'];
  }
}

export class SparkThemes {
  private readonly _backgroundColor: string = '#D2D1D1';
  private readonly _defaultHeight: number = 25;
  private readonly _margin: number = 5;

  public default: NgxSkeletonLoaderConfigTheme = {
    'background-color': this._backgroundColor,
    'height.px': this._defaultHeight,
    'margin-bottom.px': this._margin,
    'margin-top.px': this._margin
  };
  public circle: NgxSkeletonLoaderConfigTheme = {
    'background-color': this._backgroundColor,
    'width.px': this._defaultHeight,
    'height.px': this._defaultHeight,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public square_25: NgxSkeletonLoaderConfigTheme = {
    'width.px': this._defaultHeight,
    'height.px': this._defaultHeight,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public square_100: NgxSkeletonLoaderConfigTheme = {
    'width.px': 100,
    'height.px': this._defaultHeight,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public square_500: NgxSkeletonLoaderConfigTheme = {
    'width.px': 500,
    'height.px': this._defaultHeight,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public smallButton: NgxSkeletonLoaderConfigTheme = {
    'border-radius.px': 4,
    'width.px': 93,
    'height.px': 41,
    'background-color': this._backgroundColor,
    'margin.px': this._margin
  };
  public largeButton: NgxSkeletonLoaderConfigTheme = {
    'border-radius.px': 4,
    'height.px': 41,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public uploader: NgxSkeletonLoaderConfigTheme = {
    'height.px': 180,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public title: NgxSkeletonLoaderConfigTheme = {
    'height.px': 40,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public ninety: NgxSkeletonLoaderConfigTheme = {
    'height.px': 90,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
  public banner: NgxSkeletonLoaderConfigTheme = {
    'height.px': 70,
    'background-color': this._backgroundColor,
    'margin-top.px': this._margin,
    'margin-bottom.px': this._margin
  };
}

export class SparkSkeletonSettings implements ISkeletonSettings {
  public appearance: NgxSkeletonLoaderConfig['appearance'] = Appearance.Line;
  public animation: NgxSkeletonLoaderConfig['animation'] = Animation.ProgressDark;
  public themes: SparkThemes = new SparkThemes();
}