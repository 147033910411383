import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/Auth0/auth.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { FeedbackService } from '@core/services/feedback/feedback.service';
import { UserService } from '@core/services/user/user.service';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { SearchResults } from '@models/search-results';
import { UserProfile } from '@models/user-profile';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { PendoService } from '@shared/modules/pendo/services/pendo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApplicationPaths } from 'src/app/app-paths.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent extends OnDestroySubscriptionResolver implements OnInit {
  public searchWidth: string = '';
  public userProfile: UserProfile;
  public readonly logoSrc = `${window.location.origin}/assets/logos/ClosingPortal.v2.svg`;
  public username$: Observable<string>;
  public isLoggedIn$: Observable<boolean>;
  public bsModalRef: BsModalRef;
  public basicAccountSelection = [
    {
      text: 'Sign Out',
      value: 'sign-out'
    }
  ]
  public accountSelection = [
    {
      text: 'My Account',
      value: 'my-account'
    },
    {
      text: 'Contact Us',
      value: 'contact-us'
    },
    {
      text: 'eClosing Report',
      value: 'eClosing-report'
    },
    {
      text: 'Share Feedback',
      value: 'share-feedback'
    },
    {
      text: 'Helpful Documents',
      value: 'helpful-docs'
    },
    {
      text: 'Sign Out',
      value: 'sign-out'
    }
  ];
  public modalIsOpen = false;
  searchResults$: Observable<SearchResults>;

  private readonly _accountSelectionMapper: Map<string, any>;
  @ViewChild('contactUsModal') contactUsModal: TemplateRef<any>;

  public constructor(
    public readonly _authService: AuthService,
    public readonly _router: Router,
    public readonly _userService: UserService,
    private readonly _modalService: BsModalService,
    private _pendoService: PendoService,
    private readonly _customEventSvc: CustomEventsService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _feedbackService: FeedbackService,
    private readonly _featureService: FeatureService
  ) {
    super();
    this._accountSelectionMapper = new Map([
      ['my-account', this.onProfileClick],
      ['sign-out', this.onLogoutClick],
      ['helpful-docs', this.onHelpfulDocsClick],
      ['contact-us', this.openContactUsModal],
      ['share-feedback', this.onFeedbackClick],
      ['eClosing-report', this.openClosingReport]
    ]);
  }

  public ngOnInit(): void {
    this._userService.userProfile$.pipe(takeUntil(this._unsubscribe$$)).subscribe(userProfile => this.userProfile = userProfile);
    this.isLoggedIn$ = this._authService.isAuthenticated$;

    this._activatedRoute.queryParams.subscribe(params => {
      if (params['qls']?.length > 0) {
        this._customEventSvc.pushQlsLogin(params['qls'], window.location.pathname.substring(1).replace('/', '-'));
      }
    });
  }

  public canShowDashboardActions(): boolean {
    return this.userProfile?.isTermsAccepted == true;
  }

  public onAccountSelectionEvent($event: string): void {
    if (this._accountSelectionMapper.has($event)) this._accountSelectionMapper.get($event)(this);
  }

  public onDashboardClick(): void {
    this._router.navigateByUrl(ApplicationPaths.Dashboard);
  }

  private onProfileClick(instance: this): void {
    instance._router.navigateByUrl(ApplicationPaths.Account);
  }

  private onLogoutClick(instance: this): void {
    instance._router.navigate([ApplicationPaths.Logout]);
  }

  private onHelpfulDocsClick(instance: this): void {
    instance._router.navigateByUrl(ApplicationPaths.HelpPage);
  }

  public openContactUsModal(instance: this): void {
    instance.bsModalRef = instance._modalService.show(instance.contactUsModal, {
      class: 'sprk-c-Modal sprk-c-Modal--hidden-scroll',
      ignoreBackdropClick: false
    });
  }

  public onFeedbackClick(instance: this): void {
    instance._feedbackService.openFeedback(instance.userProfile.agentId);
  }

  private openClosingReport(instance: this): void {
    instance._router.navigateByUrl(ApplicationPaths.EClosingReport);
  }

  public changeSearchWidth(value: string): void {
    this.searchWidth = value;
  }
}
