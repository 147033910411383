import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/min';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  public transform(phoneValue: number | string, country: string = 'US'): any {
    try {
      const phoneNumber = parsePhoneNumber(
        phoneValue + '',
        country as CountryCode
      );
      return phoneNumber.formatNational();
    } catch (error) {
      return phoneValue;
    }
  }
}

@Pipe({
  name: 'business_phone'
})
export class BusinessPhonePipe implements PipeTransform {
  public transform(phoneNumber: string | undefined, extension?: string): any {
    const phonePipe: PhonePipe = new PhonePipe();
    let phone: string = 'N/A';

    if (!phoneNumber)
      return phone;

    phone = phonePipe.transform(phoneNumber);

    if (extension)
      phone += ` ext. ${extension}`;

    return phone;
  }
}