import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeatureService } from '@core/services/feature/feature.service';
import { OrderService } from '@core/services/order/order.service';
import { FeatureFlags } from '@enums/feature-flags.enum';
import { OrderStatus } from '@enums/order-status.enum';
import { OrderDetail } from '@models/order-detail';
import { SearchResults } from '@models/search-results';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AgentsPopupComponentV2 } from 'src/app/features/order-dashboard/agents-popup-v2/agents-popup-v2.component';
import { AgentsPopupComponent } from '../agents-popup/agents-popup.component';

@Component({
  template: '',
})
export class NewOrderListComponent implements OnInit, OnDestroy {
  public dashboardVersion$: Observable<number> = this._featureService.versions$.pipe(
    map(versions => versions.get(FeatureFlags.DashboardVersion)),
  );
  public dashboardVersion: number = 1;
  public searchResults: SearchResults;
  protected updateOrder$: BehaviorSubject<OrderDetail> = new BehaviorSubject<OrderDetail>(null);
  protected newOrderBadgeCount: number = 0;
  protected popUpModal: TemplateRef<any>;
  protected acceptModalLevel: number;
  protected _subscriptions: Subscription;
  private _bsModalRef: BsModalRef;
  private _matDialogRef: MatDialogRef<AgentsPopupComponentV2>;
  private _popUpResult: boolean;

  constructor(
    protected readonly _featureService: FeatureService,
    private readonly _modalSvc: BsModalService,
    private readonly _dialog: MatDialog,
    private readonly _orderSvc: OrderService,
  ) {
    this.dashboardVersion$.pipe(take(1)).subscribe(version => (this.dashboardVersion = version));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
  }

  public openModal(orderDetail: OrderDetail): void {
    this._modalSvc.onHide.pipe(take(1)).subscribe(() => {
      if (this._popUpResult) {
        this.updateOrderStatus(orderDetail, OrderStatus.Denied);
      }
    });

    this._bsModalRef = this._modalSvc.show(this.popUpModal, {
      class: 'sprk-c-Modal upload-modal',
      ignoreBackdropClick: true,
    });
  }

  /** Used to accept orders */
  public showSubscriptions(orderDetail: OrderDetail): void {
    this._modalSvc.onHide.pipe(take(1)).subscribe(reason => {
      if (reason === 'true') this.updateOrderStatus(orderDetail, OrderStatus.Open);
      else {
        this._bsModalRef.hide();
        this._matDialogRef?.close();
      }
    });

    if (this.dashboardVersion === 2) {
      this._matDialogRef = this._dialog.open(AgentsPopupComponentV2, {
        panelClass: ['rkt-Dialog'],
        backdropClass: 'rkt-Backdrop',
        width: '55%',
        data: {
          agencyId: orderDetail.agencyId,
          orderId: orderDetail.orderId,
          orderStatusId: orderDetail.orderStatusId,
          loanNumber: orderDetail.loanNumber,
          currentSubscriptions: orderDetail.subscriptions,
          orderDetail: orderDetail,
          level: this.acceptModalLevel,
        },
      });
    } else {
      this._bsModalRef = this._modalSvc.show(AgentsPopupComponent, {
        ignoreBackdropClick: true,
        class: 'sprk-c-Modal accept-modal',
        initialState: {
          agencyId: orderDetail.agencyId,
          orderId: orderDetail.orderId,
          orderStatusId: orderDetail.orderStatusId,
          loanNumber: orderDetail.loanNumber,
          currentSubscriptions: orderDetail.subscriptions,
          orderDetail: orderDetail,
          level: this.acceptModalLevel,
        },
      });
    }
  }

  /** Called from modal */
  public declineOrder(event: boolean): void {
    this._popUpResult = event;
    this._bsModalRef.hide();
  }

  /** Only happens when accepting or declining order */
  protected updateOrderStatus(orderDetail: OrderDetail, orderStatusId: OrderStatus): void {
    orderDetail.orderStatusId = orderStatusId;
    this._subscriptions.add(
      this._orderSvc
        .updateOrderStatus$(orderDetail.orderId, orderDetail.orderStatusId, orderDetail.loanNumber)
        .subscribe(_ => {
          this.updateOrder$.next(orderDetail);
        }),
    );
  }
}
