import { Injectable } from '@angular/core';
import { DocumentMetaData } from '@models/document-meta-data';
import { DocumentResponse } from '@models/doucment-response';
import { saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DocumentsService } from './documents.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentDownloadService {
  public document: DocumentMetaData;

  constructor(
    private readonly _documentsService: DocumentsService
  ) { }

  public downloadDocument$(
    orderId: number,
    documentId: number,
    documentName: string,
    takeUntil$$: Subject<void>,
    s3ObjectKey?: string): Observable<void> {
    const document$ = this._documentsService.getDocument$(orderId, documentId, s3ObjectKey).pipe(takeUntil(takeUntil$$));

    return document$.pipe(map((doc: DocumentResponse) => {
      const byteArray = new Uint8Array(atob(doc.documentBytes).split('').map(char => char.charCodeAt(0)));
      const file = new Blob([byteArray], { type: 'application/pdf' });
      saveAs(file, documentName)
    }));
  }

  public downloadDocumentV2$(
    orderId: number,
    documentId: number,
    documentName: string,
    takeUntil$$: Subject<void>,
    s3ObjectKey?: string): Observable<void> {
    const document$ = this._documentsService.getDocumentV2$(orderId, documentId, s3ObjectKey).pipe(takeUntil(takeUntil$$));

    return document$.pipe(
      map(documentDownloadSrc => {
        window.location.href = documentDownloadSrc.presignedUrl;
      })
    );
  }
}
