import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'modal-popup',
  templateUrl: './modal-popup.component.html'
})
export class ModalPopupComponent {
  @Input('header') header: string;
  @Input('submitBtnTxt') submitBtnTxt: string;
  @Input('cancelBtnTxt') cancelBtnTxt: string;
  @Input('showClose') showClose = true;
  @Input('disableSubmit') disableSubmit = false;
  @Input() showButtons = true;
  @Output('onConfirm') onConfirm: EventEmitter<boolean> = new EventEmitter();

  submit(){
    this.onConfirm.emit(true);
  }

  cancel(){
    this.onConfirm.emit(false);
  }
}
