import { Pipe, PipeTransform } from '@angular/core';
import { Agency } from '@models/agency';
import { AssociatedAgency } from '@models/user-profile';

@Pipe({
  name: 'agencyAddress'
})
export class AgencyAddressPipe implements PipeTransform {

  transform(agency: AssociatedAgency | Agency): string {
    return `${agency.streetAddress1 || ''} ${agency.streetAddress2 || ''}, ${agency.city || ''}, ${agency.stateCode || ''} ${agency.zip || ''}`
      .replace(new RegExp(' ,', 'g'), ',')
      .replace(new RegExp(',,', 'g'), '');
  }

}
