import { Component, OnInit, Input } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { MilestoneType } from '@enums/milestone-type.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';

@Component({
  selector: 'app-milestone-status',
  templateUrl: './milestone-status.component.html'
})
export class MilestoneStatusComponent {

  @Input() milestoneStatusId: number;
  @Input() milestoneTitle: string;
  @Input() canShowTitle = false;

  public readonly milestoneStatus = MilestoneStatus;
  public readonly faCircle = faCircle;
  public readonly milestoneStatusMapper: Map<MilestoneStatus, string>;

  constructor() {
    this.milestoneStatusMapper = new Map([
      [MilestoneStatus.New, 'New'],
      [MilestoneStatus.Pending, 'Under Review'],
      [MilestoneStatus.Complete, 'Completed']
    ])
  }
}
