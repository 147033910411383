export enum MilestoneDefinitionId {
  TitleDocument = 4,
  PropertyInformation = 7,
  VestingInformation = 9,
  ClientInformation = 8,
  RequiredAtSigning = 10,
  PurchaseAgreementInformation = 11,
  DownloadUnsignedClosingPackage = 12,
  SurveyInformation = 15,
  SigningInformation = 16,
  PowerOfAttorneyInformation = 17,
  WireInstructionsInformation = 18,
  SettlementAgentInformation = 19,
  FinalSignOff = 22,
  Subordination = 25,
  ProofOfGiftFunds = 29,
  OutstandingVehicleTitle = 30,
  ClosedInATrust = 31
}
