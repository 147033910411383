<table id="document-download-milestone-table"
       class="sprk-b-Table sprk-b-Table--spacing-medium sprk-b-Table--secondary sprk-b-Table--gray"
       aria-label="document-download-milestone-table">
  <thead sprkTableHead>
    <tr class="no-print">
      <th id="documents">Documents</th>
      <th id="toDoItem">To Do Item</th>
      <th id="action"><span></span></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let milestone of milestones">
      <tr>
        <td class="max-width-35rem">
          {{ milestone.title }}
          <sprk-icon *ngIf="milestone?.milestoneTitle"
                     iconType="information"
                     data-toggle="tooltip"
                     data-placement="left"
                     title="{{ milestone.description }}">
          </sprk-icon>
          <div class="pt-3"
               *ngIf="milestone?.milestoneDefinitionId === milestoneDefinitionIds.DownloadUnsignedClosingPackage && isNewClosingPackage | async">
            <p><span class="font-weight-bold pr-2">ALERT:</span>Revised Closing Package has been provided.</p>
            <p>Please download this latest version.</p>
          </div>
        </td>
        <td *ngIf="milestone?.milestoneDefinitionId === milestoneDefinitionIds.DownloadUnsignedClosingPackage"
            class="max-width-35rem align-text-top">
          <app-document-dropdown [documents]="closingDocuments"
                                 [(documentId)]="selectedDocumentId">
          </app-document-dropdown>
        </td>
        <td class="d-flex justify-content-end">
          <button class="sprk-c-Button sprk-c-Button--secondary sprk-c-Button--compact min-width-120px"
                  routerLink="documents"
                  role="button"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Tooltip on left"
                  placement="auto"
                  (click)="downloadDocument(milestone, selectedDocumentId)"
                  [disabled]="downloadInProgress"
                  *ngIf="closingDocuments?.length > 0">
            <ng-container *ngIf="!downloadInProgress">Download</ng-container>
            <div *ngIf="downloadInProgress"
                 class="sprk-c-Spinner sprk-c-Spinner--circle sprk-c-Spinner--secondary"></div>
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="!!milestones && milestones?.length === 0">
    <tr>
      <td colspan="3">
        <article class="d-flex justify-content-center align-items-center">
          <header>
            <h6>There are currently no To-Do requests</h6>
          </header>
        </article>
      </td>
    </tr>
  </tbody>
</table>