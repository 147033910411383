import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderDetail } from '@models/order-detail';
import { SearchResults } from '@models/search-results';

@Component({
  selector: 'app-order-search-list',
  templateUrl: './order-search-list.component.html'
})
export class OrderSearchListComponent {
  @Input() searchResults: SearchResults = new SearchResults();
  @Output() orderSelected: EventEmitter<OrderDetail> = new EventEmitter<OrderDetail>();
  @Output() showAll: EventEmitter<void> = new EventEmitter<void>();

  public showAllResults(): void {
    this.showAll.emit();
  }

  public selectOrder(order: OrderDetail): void {
    this.orderSelected.emit(order);
  }

}
