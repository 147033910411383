import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatBaseService {
  protected _chatServiceContainerClass: string;
  private _element: HTMLElement | null = null;

  private get chatServiceElement(): HTMLElement | null {
    if (!this._element) {
      const elements = document.getElementsByClassName(this._chatServiceContainerClass);
      if (elements && elements.length > 0)
        this._element = elements[0] as HTMLElement
    }

    return this._element;
  }

  protected changeButtonVisibility(visibility: string, useTimeout: boolean = true): void {
    if (useTimeout) {
      let seconds: number = 0;
      const interval: NodeJS.Timeout = setInterval(() => {
        seconds++;

        if (this.chatServiceElement) {
          this.chatServiceElement.style.visibility = visibility;
          clearInterval(interval);
        }

        if (seconds === 10)
          clearInterval(interval);

      }, 1000);
    }
    else if (this.chatServiceElement) {
      this.chatServiceElement.style.visibility = visibility;
    }
  }
}
