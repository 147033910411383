import { Component, Input, OnInit } from '@angular/core';
import { DocumentDownloadService } from '@core/services/documents/document-download.service';
import { DocumentsService } from '@core/services/documents/documents.service';
import { DocMetaData } from '@models/doc-metadata';
import { DocumentMetaData } from '@models/document-meta-data';
import { Milestone } from '@models/milestone';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { ISkeletonSettings, SparkSkeletonSettings } from '@shared/modules/sprk-skeleton/models/skeleton-settings';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';

@Component({
  selector: 'app-document-history',
  templateUrl: './document-history.component.html'
})
export class DocumentHistoryComponent extends OnDestroySubscriptionResolver implements OnInit {
  @Input() milestone: Milestone;
  public showError: boolean = false;
  downloadInProgress: boolean;
  documentHistory: Array<DocumentMetaData>;
  skeletonSettings: ISkeletonSettings = new SparkSkeletonSettings()

  constructor(
    public readonly documentsService: DocumentsService,
    private readonly _orderViewService: OrderViewService,
    private readonly _documentDownloadService: DocumentDownloadService) {
    super();
  }

  ngOnInit(): void {
    this._orderViewService.orderId$.pipe(
      switchMap(orderId => {
        return this.documentsService.getDocumentsMetaData$(orderId, this.milestone?.milestoneDefinitionId, this.milestone?.referenceId)
      })
    ).pipe(
      takeUntil(this._unsubscribe$$),
      catchError(err => {
        this.showError = (err.status === 404);
        this.documentHistory = [];
        return [];
      }))
      .subscribe(x => {
        this.documentHistory = x;
        this._unsubscribe$$.next();
      });
  }

  public downloadDocument(document: DocMetaData) {
    this.downloadInProgress = true;
    this._orderViewService.orderId$.pipe(
      switchMap(orderId => {
        return this._documentDownloadService.downloadDocumentV2$(orderId, document.documentId, document.documentName, this._unsubscribe$$, document.s3ObjectKey);
      })
    ).pipe(takeUntil(this._unsubscribe$$)).subscribe(_ => {
      this.downloadInProgress = false;
      this._unsubscribe$$.next()
    }, _ => this.downloadInProgress = false);
  }
}
