import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  IFeatureFlagConfigProvider
} from '@closing-portal/feature-flags';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable()
export class FeatureFlagConfigBuilderService implements IFeatureFlagConfigProvider {
  constructor(private readonly _userService: UserService) { }
  public get authorizationKey(): string {
    return environment.splitConfig.clientId;
  }

  public get key$(): Observable<number> {
    return this._userService.userProfile$
      .pipe(map(userProfile => userProfile?.agentId ?? 0));
  }
}
