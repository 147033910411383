import { RuleSet } from "@models/rules/rule-set";

export abstract class RuleService<T, R> {
  protected _rules: Map<T, R[]> = new Map<T, R[]>();
  protected _instantiated: boolean = false;
  public abstract instantiate(): void;

  public apply<T>(object: T, property: string, defaultValue: string = ''): string {
    if (object[property])
      return object[property];

    return defaultValue;
  }

  /** Gets rules for specific key
  *  @param {string} key the key of the rule </param>
  *  @param {array} args args[0] should be a property on the rule which is an array and args[1] should be the value you are looking for.
  */
  public getRules(key: T, ...args: Array<any>): RuleSet<T, R> {
    const ruleSet = new RuleSet<T, R>();

    if (!this._instantiated)
      this.instantiate();

    if (!this._rules.has(key))
      return ruleSet;

    ruleSet.key = key;
    ruleSet.rules = this._rules.get(key);

    if (args && args.length > 1)
      ruleSet.rules = ruleSet.rules.filter(f => f[args[0]].includes(args[1]));

    return ruleSet;
  }

  public getDisplayProperties(value: T, displayProperty: string): string[] {
    if (!this._instantiated)
      this.instantiate();

    if (this._rules.has(value))
      return this._rules.get(value).map(rule => rule[displayProperty]);

    return [];
  }
}