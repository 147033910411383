import { Pipe, PipeTransform } from '@angular/core';

/**
@value Any object
@args The first arg must be the highlight object
      Second arg is the pipe used to format the output
*/
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  private _startTag: string = '<strong>';
  private _endTag: string = '</strong>';

  transform(value: any, ...args: Array<any>): string | object {
    const highlight: Map<string, string[]> = args[0];
    let returnValue = Object.assign({}, value);
    let pipe: PipeTransform = null;

    if (args.length > 1)
      pipe = args[1];

    for (let key in highlight) {
      const property = this.getPropertyName(key);

      this.compare(highlight[key], returnValue, property);
    }

    return pipe ? pipe.transform(returnValue) : returnValue;
  }

  private toCamelCase(input: string): string {
    let first = input.substring(0, 1).toLowerCase();
    return first + input.substring(1);
  }

  private getPropertyName(key: string): string {
    const subProperties = key.split('.');
    if (subProperties.length > 1)
      return this.toCamelCase(subProperties[1]);
    else
      return this.toCamelCase(key);
  }

  private compare(highlights: Array<string>, returnValue: string, property: string): void {
    highlights.forEach(highlight => {
      if (returnValue.hasOwnProperty(property)) {
        const compareValue = highlight.replace(this._startTag, '').replace(this._endTag, '');

        if (returnValue[property] === compareValue)
          returnValue[property] = highlight;
      }
    });
  }
}
