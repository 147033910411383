<ng-container *ngIf="searchResults.orderDetails">
  <ng-container *ngIf="searchResults.orderDetails.length > 0; else noResults">
    <ng-container *ngFor="let orderDetails of searchResults.orderDetails">
      <app-order-search-list-item [orderDetails]="orderDetails"
                                  (orderClicked)="selectOrder($event)"></app-order-search-list-item>
    </ng-container>
    <ng-container *ngIf="searchResults.totalOrderRecords > 5">
      <div sprkDivider
           idString="order-list-divider"></div>
      <div class="sprk-u-mbm">
        <a class="sprk-u-AbsoluteCenter"
           (click)="showAllResults()">See all results</a>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noResults>
    <sprk-card idString="default">
      <sprk-stack sprkCardContent
                  sprkStackItem
                  itemSpacing="medium">
        <p sprkStackItem
           sprkText
           variant="bodyTwo">
          No Results
        </p>
      </sprk-stack>
    </sprk-card>
  </ng-template>
</ng-container>