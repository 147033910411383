import { Component } from '@angular/core';
import { CoreModule } from '@core/core.modules';
import { RktIconModule, RktLinkModule } from '@rocketcentral/rocket-design-system-angular';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
@Component({
  selector: 'catch-footer',
  templateUrl: './footer.v2.component.html',
  styleUrls: ['./footer.v2.component.scss'],
  imports: [RktIconModule, RktLinkModule, CommonModule, SharedModule, CoreModule],
  standalone: true,
})
export class FooterComponentV2 extends FooterComponent {
}