import { Component, Input, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
  @Input() src: string;
  public isRendered = false;
  public totalPages;
  public pagesRead = 0;

  public onPdfLoad(pdf: PDFDocumentProxy): void {
    this.isRendered = true;
    this.totalPages = pdf.numPages;
  }
}
