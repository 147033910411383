import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpPageComponent } from '@core/components/help-page/help-page.component';
import { LogoutComponent } from '@core/components/logout/logout.component';
import { UsageAgreementComponent } from '@core/components/usage-agreement/usage-agreement.component';
import { ApplicationPaths } from './app-paths.enum';
import { AuthGuard } from './guards/auth/auth.guard';
import { TermsOfUseGuard } from './guards/auth/terms.guard';
import { OrderAuthGuard } from './modules/order-view/guards/order-auth/order-auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: ApplicationPaths.UsageAgreement,
    canActivate: [AuthGuard],
    component: UsageAgreementComponent
  },
  {
    path: ApplicationPaths.HelpPage,
    canActivate: [AuthGuard],
    component: HelpPageComponent
  },
  {
    path: ApplicationPaths.Logout,
    component: LogoutComponent
  },
  {
    path: '',
    canActivateChild: [AuthGuard, TermsOfUseGuard],
    children: [
      {
        path: ApplicationPaths.Orders,
        canActivateChild: [OrderAuthGuard],
        loadChildren: () =>
          import('./modules/order-view/order-view.module').then(
            module => module.OrderViewModule
          )
      },
      {
        path: ApplicationPaths.Account,
        loadChildren: () =>
          import('./modules/account/account.module').then(
            module => module.AccountModule
          )
      },
      {
        path: ApplicationPaths.Search,
        loadChildren: () =>
          import('./modules/search/search.module').then(
            module => module.SearchModule
          )
      },
      {
        path: ApplicationPaths.Dashboard,
        loadChildren: () =>
          import('./modules/order-dashboard/order-dashboard.module').then(
            module => module.OrderDashboardModule
          )
      }, {
        path: ApplicationPaths.EmailVerification,
        loadChildren: () =>
          import('./modules/email-verification/email-verification.module').then(
            module => module.EmailVerificationModule
          )
      },
      {
        path: ApplicationPaths.Archive,
        loadChildren: () =>
          import('./modules/archived-order/archived-order.module').then(
            module => module.ArchiveModule
          )
      },
      {
        path: ApplicationPaths.EClosingReport,
        loadChildren: () => import('./modules/closing-report/closing-report.module').then(m => m.ClosingReportModule)
      },
      {
        path: '**',
        redirectTo: ApplicationPaths.Dashboard
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
