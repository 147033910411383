import { Component } from '@angular/core';
import { BaseLoaderComponent } from '../base-loader.component';

@Component({
  selector: 'app-final-signoff-skeleton',
  templateUrl: './final-signoff-skeleton.component.html'
})
export class FinalSignoffSkeletonComponent extends BaseLoaderComponent {
  constructor() { super(); }
}
