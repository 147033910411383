<app-address-highlight *ngIf="highlightSection === highlightSections.Address"
                       [orderDetails]="orderDetails"
                       (orderClicked)="onOrderClicked()"></app-address-highlight>

<app-contact-highlight *ngIf="highlightSection === highlightSections.Client"
                       [orderDetails]="orderDetails"
                       (orderClicked)="onOrderClicked()">
</app-contact-highlight>

<app-loan-number-highlight *ngIf="highlightSection === highlightSections.LoanNumber"
                           [orderDetails]="orderDetails"
                           (orderClicked)="onOrderClicked()">
</app-loan-number-highlight>