import { Injectable } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';
import { NoticeRule } from '@models/rules/notice-rule';
import { RuleSet } from '@models/rules/rule-set';
import { iRuleService } from '../iRuleService';
import { RuleService } from '../rule.service';

@Injectable({
  providedIn: 'root'
})
export class StateNoticeRulesService extends RuleService<string, NoticeRule> implements iRuleService<string, NoticeRule> {

  public instantiate(): void {
    if (!this._instantiated) {
      const rule = this.getIowaTitleGuaranteeRule();
      this._rules.set(rule.stateCode, [rule]);
    }

    this._instantiated = true;
  }

  public getRules(stateCode: string, orderStatus: OrderStatus): RuleSet<string, NoticeRule> {
    return super.getRules(stateCode, 'orderStatuses', orderStatus);
  }

  private getIowaTitleGuaranteeRule(): NoticeRule {
    const rule: NoticeRule = new NoticeRule();
    rule.name = 'Iowa Title Guarentee';
    rule.notice = 'Notice: We require all Iowa Orders to be underwritten using Iowa Title Guarantee, please ensure all title work is underwritten by ITG. If you are unable to underwrite using ITG, please decline this order.';
    rule.orderStatuses = [OrderStatus.New];
    rule.stateCode = 'IA';

    return rule;
  }
}