<div class="sprk-c-Modal sprk-c-Modal--alt sprk-c-Modal--alt-60 sprk-u-ptn"
     role="dialog"
     tabindex="-1"
     aria-labelledby="modalInfoHeading"
     aria-modal="true"
     aria-describedby="modalInfoContent"
     data-sprk-modal="exampleInfoModal"
     data-id="modal-info-1">
  <header class="sprk-o-Stack__item 
                sprk-c-Modal__header 
                mb-3 
                sticky 
                sprk-u-ptm 
                sprk-u-pbm 
                sprk-u-BackgroundColor--white"
          style="top: 0; z-index: 999999; border-bottom: 1px solid black">
    <h2 class="sprk-c-Modal__heading sprk-b-TypeDisplayFour"
        id="modalChoiceHeading">
      {{title}} History
    </h2>
    <button class="sprk-c-Modal__icon"
            (click)="close()"
            data-sprk-modal-cancel="exampleChoiceModal"
            type="button"
            aria-label="Close Modal">
      <svg class="sprk-c-Icon"
           viewBox="0 0 64 64"
           aria-hidden="true"
           focusable="false">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </header>
  <app-document-history-v2 [milestone]="milestone"></app-document-history-v2>
</div>
<div id="modal-mask"
     data-sprk-modal-mask="true"
     class="sprk-c-ModalMask"
     tabindex="-1"></div>