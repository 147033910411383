import { Injectable } from '@angular/core';
import { MilestoneDefinitionId } from '@enums/milestone-definition-ids.enum';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { MilestoneType } from '@enums/milestone-type.enum';
import { Message } from '@models/messages';
import { Milestone } from '@models/milestone';
import { find } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MilestoneHelperService {
  public getDocumentMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.sort(this.getMilestones(milestones, milestone => this.isDocumentMilestoneType(milestone.milestoneTypeId)));
  }

  public getDocumentUploadMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.sort(this.getMilestones(milestones, milestone => this.isDocumentUploadMilestoneType(milestone.milestoneTypeId)));
  }

  public getDocumentDownloadMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.sort(this.getMilestones(milestones, milestone => this.isDocumentDownloadMilestoneType(milestone.milestoneTypeId)));
  }

  public getDataMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.milestoneOrganizer(this.getMilestones(milestones, milestone => this.isDataMilestoneType(milestone.milestoneTypeId)));
  }

  public getMilestones(milestones: Array<Milestone>, func?: (milestone: Milestone) => boolean): Array<Milestone> {
    return func ? this.filter(milestones, func) : milestones;
  }

  public getInProcessMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.filter(milestones, milestone => this.isInProcessMilestoneStatus(milestone));
  }

  public getFinalSignOffMilestones(milestones: Array<Milestone>): Array<Milestone> {
    return this.reOrderMilestones(this.filter(milestones, milestone => this.isInFinalSignOffMilestoneStatus(milestone.milestoneStatusId)));
  }

  public getActionNeededMilestone(milestones: Array<Milestone>): Array<Milestone> {
    return this.filter(milestones, milestone => this.isInActionNeededMilestoneStatus(milestone.milestoneStatusId));
  }

  public filter(milestones: Array<Milestone>, func: (milestone: Milestone) => boolean): Array<Milestone> {
    return milestones.filter(milestone => func(milestone));
  }

  public sort(milestones: Array<Milestone>): Array<Milestone> {
    return milestones.sort(this.comparer);
  }

  public getFinalReviewMilestone(milestones: Array<Milestone>): Milestone {
    if (!this.hasFinalReviewMilestone(milestones)) return null;

    return find(milestones, { milestoneDefinitionId: MilestoneDefinitionId.FinalSignOff } as Milestone);
  }

  public hasFinalReviewMilestone(milestones: Array<Milestone>): boolean {
    const milestone = find(milestones, { milestoneDefinitionId: MilestoneDefinitionId.FinalSignOff } as Milestone);

    return !!milestone;
  }

  public isFinalMilestone(milestone: Milestone): boolean {
    return milestone.milestoneDefinitionId === MilestoneDefinitionId.FinalSignOff;
  }

  public isInProcessMilestoneStatus(milestone: Milestone): boolean {
    return milestone.milestoneStatusId > MilestoneStatus.New
      && milestone.milestoneStatusId < MilestoneStatus.FinalSignOff
      && milestone.milestoneStatusId !== MilestoneStatus.Revision;
  }

  public isInFinalSignOffMilestoneStatus(milestoneStatusId: MilestoneStatus): boolean {
    return milestoneStatusId === MilestoneStatus.FinalSignOff;
  }

  public isInActionNeededMilestoneStatus(milestoneStatusId: MilestoneStatus): boolean {
    return milestoneStatusId === MilestoneStatus.New || milestoneStatusId === MilestoneStatus.Revision
  }

  private isDocumentUploadMilestoneType(milestoneType: MilestoneType): boolean {
    return milestoneType === MilestoneType.DocumentUpload;
  }

  public isDocumentMilestoneType(milestoneType: MilestoneType): boolean {
    return milestoneType === MilestoneType.DocumentDownload || milestoneType === MilestoneType.DocumentUpload;
  }

  public isDataMilestoneType(milestoneType: MilestoneType): boolean {
    return milestoneType === MilestoneType.DataProvide || milestoneType === MilestoneType.DataVerify;
  }

  public isFinalMilestoneType(milestoneType: MilestoneType): boolean {
    return milestoneType === MilestoneType.DataFinalSignOff;
  }

  public findRevisionRequestMessage(milestone: Milestone, messages: Array<Message>): string {
    let target: Message = null;
    if (milestone.referenceId)
      target = messages?.find(m => m.milestoneDefinitionId === milestone.milestoneDefinitionId && m.referenceId === milestone.referenceId);
    else
      target = messages?.find(m => m.milestoneDefinitionId === milestone.milestoneDefinitionId);

    return target?.message;
  }

  public processRevisionMessages(milestones: Array<Milestone>, messages: Array<Message>): Array<Milestone> {
    if (messages) {
      milestones.forEach(milestone => {
        milestone.milestoneMessage = this.findRevisionRequestMessage(milestone, messages);
      });
    }

    return milestones;
  }

  public processRevisionSubtitles(milestone: Milestone): Milestone {
    const regExPattern = /@{3}[A-z\/ -]*:/;
    const subtitleIndex = milestone.milestoneMessage?.indexOf('@@@');
    const firstColonIndex = milestone.milestoneMessage?.indexOf(':');

    if (firstColonIndex > -1) {
      const firstTitle = milestone.milestoneMessage.substring(0, firstColonIndex);
      milestone.milestoneMessage = milestone.milestoneMessage.replace(firstTitle, firstTitle.bold());
    }

    while (subtitleIndex > -1) {
      const subStrMatches = milestone.milestoneMessage.match(regExPattern);
      if (subStrMatches == null) {
        break;
      }
      else {
        const substr = subStrMatches[0]?.replace('@@@', '');
        milestone.milestoneMessage = milestone.milestoneMessage.replace(regExPattern, `\n\n${substr.bold()}`);
      }
    }

    return milestone;
  }

  public get documentUploadActionVerb(): string {
    return 'Provide';
  }

  public getDocumentNameFromMilestoneTitle(milestone: Milestone): string {
    const regExp = new RegExp(this.documentUploadActionVerb);
    return milestone.title.replace(regExp, '');
  }

  public getMilestoneTitle(milestone: Milestone): string {
    if (!milestone) return '';

    return milestone.titleOverride ? milestone.titleOverride.trim().length > 0 ? milestone.titleOverride : milestone.milestoneTitle
      : milestone.milestoneTitle;
  }

  public getMilestoneDescription(milestone: Milestone): string {
    if (!milestone) return '';

    return milestone.descriptionOverride ? milestone.descriptionOverride.trim().length > 0 ? milestone.descriptionOverride : milestone.milestoneDescription
      : milestone.milestoneDescription;
  }

  private reOrderMilestones(milestones: Array<Milestone>): Array<Milestone> {
    const settlementMilestone = milestones.findIndex(m => m.milestoneDefinitionId == MilestoneDefinitionId.SettlementAgentInformation);
    const wireInformationMielstone = milestones.findIndex(m => m.milestoneDefinitionId == MilestoneDefinitionId.WireInstructionsInformation);

    if (settlementMilestone > -1 && wireInformationMielstone > -1) {
      [milestones[settlementMilestone], milestones[wireInformationMielstone]] = [milestones[wireInformationMielstone], milestones[settlementMilestone]];
    }

    return milestones;
  }

  private milestoneOrganizer(milestones: Array<Milestone>): Array<Milestone> {
    const downloadMilestones: Array<Milestone> = new Array<Milestone>();
    const uploadMilestones: Array<Milestone> = new Array<Milestone>();
    const verifyMilestones: Array<Milestone> = new Array<Milestone>();
    const provideMilestones: Array<Milestone> = new Array<Milestone>();
    const signOffMilestones: Array<Milestone> = new Array<Milestone>();

    milestones.forEach(milestone => {
      switch(milestone.milestoneTypeId){
        case(MilestoneType.DocumentDownload):
          downloadMilestones.push(milestone);
          break;
        case(MilestoneType.DocumentUpload):
          uploadMilestones.push(milestone);
          break;
        case(MilestoneType.DataVerify):
          verifyMilestones.push(milestone);
          break;
        case(MilestoneType.DataProvide):
          if(milestone.milestoneDefinitionId == MilestoneDefinitionId.ClosedInATrust)
          {
            verifyMilestones.unshift(milestone);
            break;
          }
          provideMilestones.push(milestone);
          break;
        case(MilestoneType.DataFinalSignOff):
        default:
          signOffMilestones.push(milestone);
          break;
      }
    });

    return downloadMilestones.concat(uploadMilestones.concat(verifyMilestones.concat(provideMilestones.concat(signOffMilestones))));
  }

  private isDocumentDownloadMilestoneType(milestoneType: MilestoneType): boolean {
    return milestoneType === MilestoneType.DocumentDownload;
  }

  private comparer(a: Milestone, b: Milestone): 1 | -1 {
    if (a.milestoneStatusId > b.milestoneStatusId) return 1;
    else return -1;
  }
}
