<div class="row py-4">
  <div class="col-lg-6 col-md-6 order-xs-1 order-sm-1 order-md-1 order-lg-0">
    <div id="title"
         class="row">
      <div class="col col-lg-1">
        <sprk-skeleton-bone appearance="circle"
                            [animation]="settings.animation"
                            [theme]="settings.themes.circle"></sprk-skeleton-bone>
      </div>
      <div class="col col-lg-11">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.title"></sprk-skeleton-bone>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"
                            count="3"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
  <div class="col-lg col-md-6 order-xs-0 order-sm-0 order-lg-1 order-md-0">
    <sprk-skeleton-bone [animation]="settings.animation"
                        [theme]="settings.themes.uploader"></sprk-skeleton-bone>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="row">
      <div class="col col-lg-1">
        <sprk-skeleton-bone appearance="circle"
                            [animation]="settings.animation"
                            [theme]="settings.themes.circle"></sprk-skeleton-bone>
      </div>
      <div class="col col-lg-11">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.title"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
</div>
<div class="row pt-4"
     *ngFor="let row of [1,2];">
  <div style="max-width: none;"
       class="background-color--white p-4 slide sprk-c-Card sprk-o-Stack w-100"
       id="1273">
    <div class="row">
      <div class="col-8">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.default"
                            count="2"></sprk-skeleton-bone>
      </div>
      <div class="col-3 text-center">
        <sprk-skeleton-bone appearance="circle"
                            [animation]="settings.animation"
                            [theme]="settings.themes.circle"
                            count="2"></sprk-skeleton-bone>
      </div>
      <div class="col-1">
        <sprk-skeleton-bone [animation]="settings.animation"
                            [theme]="settings.themes.largeButton"></sprk-skeleton-bone>
      </div>
    </div>
  </div>
</div>