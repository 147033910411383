import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';
import { PartnershipType } from '@enums/partnership-enum';
import { TransactionType } from '@enums/transaction-type.enum';
import { Contact } from '@models/contact';
import { LoanData } from '@models/loan-data';
import { ContactType } from '@shared/enums/contact-type-converter.enum';
import { is } from 'date-fns/locale';

@Pipe({
  name: 'qlContact'
})
export class QlContactPipe implements PipeTransform {

  transform(contacts: Array<Contact>, orderStatus: number, loan: LoanData): Array<Contact> {
    let returnData: Array<Contact> = [];
    if (contacts) {
      returnData = contacts.filter(x => this.filterContacts(x, orderStatus));
    }

    if (returnData.length === 0) {
      switch (loan?.partnership?.toUpperCase()) {
        case (PartnershipType.Title):
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: 8334951251,
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null
          } as Contact);
          break;

        case (PartnershipType.Closing):
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: 8889802789,
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null,
          } as Contact );
          break;

        default:
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: this.getPhoneNumber(orderStatus, loan?.isTPO),
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null,
            businessHours: this.getBusinessHours(orderStatus, loan?.isTPO),
          } as Contact & { businessHours?: string });
          break;
      }
    }

    return returnData;
  }

  private filterContacts(contact: Contact, orderStatus: OrderStatus): boolean {
    if (orderStatus === OrderStatus.Closing)
      return contact.contactType === ContactType.Closer;
    else if (orderStatus === OrderStatus.Open)
      return contact.contactType === ContactType.LoanAnalyst;

    return false;
  }

  private getBusinessHours( orderStatus: OrderStatus,isTPO: boolean): string {
    if (isTPO){
    if (orderStatus === OrderStatus.Closing)
      return '8:00 AM - 9:00 PM ET';
    else if (orderStatus === OrderStatus.Open)
      return '9:00 AM - 8:00 PM ET';
  }
    else{
      if (orderStatus === OrderStatus.Closing)
      return '8:00 AM - 9:00 PM ET mon-fri\n9:00 AM - 4:00 PM ET sat';
    else if (orderStatus === OrderStatus.Open)
      return '8:00 AM - 9:00 PM ET mon-fri';
    }

  }

  private getContactType(orderStatus: OrderStatus): string {
    return orderStatus == OrderStatus.Closing ? 'Closing Hotline' : 'Title Coordinator Hotline';
  }

  private getPhoneNumber(orderStatus: OrderStatus, isTPO: boolean | undefined): number {
    if(orderStatus === OrderStatus.Closing) {
      return isTPO ? 8885894055 : 8888485309;
    }
    else
      return isTPO ? 8663126192 : 8889122272;
  }

}
