import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DocumentDownloadService } from '@core/services/documents/document-download.service';
import { DocumentsService } from '@core/services/documents/documents.service';
import { MilestoneHelperService } from '@core/services/milestone/milestone-helper.service';
import { MilestoneService } from '@core/services/milestone/milestone.service';
import { OrderService } from '@core/services/order/order.service';
import { UserService } from '@core/services/user/user.service';
import { MilestoneType } from '@enums/milestone-type.enum';
import { SessionStorageKey } from '@enums/session-storage-key.enum';
import { SignalRResource } from '@enums/signalr-resource.enum';
import { Agency } from '@models/agency';
import { Agent } from '@models/agent';
import { Contact } from '@models/contact';
import { DocumentMetaData } from '@models/document-meta-data';
import { LesAddress } from '@models/les-address';
import { LoanData } from '@models/loan-data';
import { Milestone } from '@models/milestone';
import { Order } from '@models/order';
import { Property } from '@models/property';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { first, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import * as SmoothScroll from 'smooth-scroll';
import * as _ from 'lodash';
import { MilestoneStatus } from '@enums/milestone-status.enum';
import { SignalRService } from './signalr.service';
import { SignalRMessage } from '@models/signalR-message';
import { ClosingType } from '@models/closing-type';

@Injectable()
export class OrderViewService {
  private _smoothScroll = new SmoothScroll('a[href*="#"]',
    {
      updateURL: false,
      offset: function (anchor, toggle) {
        return document.getElementById('catch-masthead').offsetHeight + 10 || 0;
      }
    });

  public readonly _milestones$$: BehaviorSubject<Array<Milestone>> = new BehaviorSubject<Array<Milestone>>([]);
  private _contacts$: Observable<Array<Contact>>;
  private _agents$: Observable<Array<Agent>>;
  private _agency$: Observable<Agency>;
  private _property$: Observable<Property>;
  private _loanData$: Observable<LoanData>;
  private readonly _contactsReloader$$ = new Subject<void>();
  private readonly _agentsReloader$$ = new Subject<void>();
  private readonly _agencyReloader$$ = new Subject<void>();
  private readonly _propertyReloader$$ = new Subject<void>();
  private readonly _loanDataReloader$$ = new Subject<void>();
  private readonly _orderReloader$$ = new Subject<void>();
  private readonly _milestonesReloader$$ = new Subject<void>();
  private readonly _documentsReloader$$ = new Subject<void>();
  private readonly _closingTypeReloader$$ = new Subject<void>();
  private readonly _cacheControl$$ = new Subject<void>();
  private readonly _orderIdParamKey: string;
  private readonly _order$$: ReplaySubject<Order>;
  private readonly _router: Router;
  private readonly _unSubscribe = new Subject<void>();
  private _orderId: number;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _documentDownloadService: DocumentDownloadService,
    private readonly _milestoneService: MilestoneService,
    private readonly _orderService: OrderService,
    private readonly _milestoneHelperService: MilestoneHelperService,
    private readonly _userService: UserService,
    private readonly _documentsService: DocumentsService,
    private readonly _signalRService: SignalRService) {
    this._orderIdParamKey = 'orderId';
    this._order$$ = new ReplaySubject<Order>(1);
    this._signalRService.message$$.subscribe(msg => this.dataUpdates(msg));

    this.initialize();
  }

  public initialize(): void {
    this.orderId$
      .pipe(
        first(),
        switchMap(orderId => {
          if (orderId > 0)
            return this._milestoneService.getMilestones$(orderId);

          return of(null);
        }),
        take(1))
      .subscribe(m => { this._milestones$$.next(m); });
  }

  public get contacts$(): Observable<Contact[]> {
    return this.configureCache('_contacts$', orderId => this._orderService.getContacts$(orderId), this._contactsReloader$$);
  }

  public get agents$(): Observable<Array<Agent>> {
    return this.configureCache('_agents$', _ => this.getAgents(), this._agentsReloader$$);
  }

  public get agency$(): Observable<Agency> {
    return this.configureCache('_agency$', _ => this.getAgency(), this._agencyReloader$$);
  }

  public get property$(): Observable<Property> {
    return this.configureCache('_property$', orderId => this._orderService.getProperty$(orderId), this._propertyReloader$$);
  }

  public get order$(): Observable<Order> {
    const orderFunc = (orderId: number) => {
      if(orderId == 0){
        orderId = this._orderId;
      }
      return this._orderService
        .getOrder$(orderId)
        .pipe(
          map(order => {
            if (order?.agencyId) {
              sessionStorage.setItem(SessionStorageKey.TargetAgency, order.agencyId.toString());
            }
            return order;
          })
        )
    };

    return this.configureCache('_order$', orderFunc, this._orderReloader$$, this._order$$);
  }

  public get loanData$(): Observable<LoanData> {
    return this.configureCache('_loanData$', orderId => this._orderService.getLoanData$(orderId), this._loanDataReloader$$);
  }

  public get closingType$(): Observable<ClosingType> {
    return this.configureCache('_closingType$', orderId => this._orderService.getClosingType$(orderId), this._closingTypeReloader$$)
  }

  public get milestones$(): BehaviorSubject<Array<Milestone>> {
    return this._milestones$$;
  }

  public get isInFinalReview$(): Observable<boolean> {
    return this.milestones$.pipe(switchMap(milestones => of(this._milestoneHelperService.hasFinalReviewMilestone(milestones))));
  }

  public get finalReviewMilestone$(): Observable<Milestone> {
    return this.milestones$.pipe(switchMap(milestones => of(this._milestoneHelperService.getFinalReviewMilestone(milestones))));
  }

  public get documents$(): Observable<DocumentMetaData[]> {
    return this.configureCache('_documents$', orderId => this._documentsService.getDocuments$(orderId), this._documentsReloader$$);
  }

  public get isNewClosingPackage$(): Observable<boolean> {
    return this.documents$.pipe(map(docs => {
      return docs.filter(doc => doc.documentTypeId == 1799 || doc.documentTypeId == 2158 || doc.documentTypeId == 2480).length > 0;
    }));
  }

  public refresh(target: 'contacts' | 'agents' | 'agency' | 'property' | 'milestones' | 'loanData' | 'order' | 'documents'): void {
    this[`_${target}Reloader\$\$`].next();
    this[`_${target}\$`] = null;
  }

  public updateOrder(order: Order): void {
    this._order$$.next(order);
  }

  public updateMilestones(milestones: Array<Milestone>): void {
    this._milestones$$.next(milestones);
  }

  public get orderId$(): Observable<number> {
    const paramMap$ = this._activatedRoute.paramMap != null ? this._activatedRoute.paramMap : this._activatedRoute.parent.paramMap;

    return this.orderIdByParamMap$(paramMap$).pipe(shareReplay(1));
  }

  public getDocuments$(milestoneDefinitionId?: number, referenceId?: string): Observable<Array<DocumentMetaData>> {
    return this.orderId$.pipe(
      switchMap(orderId => {
        if (milestoneDefinitionId)
          return this._documentsService.getDocumentsMetaData$(orderId, milestoneDefinitionId, referenceId);

        return this._documentsService.getDocuments$(orderId);
      }));
  }

  public downloadDocument$(milestoneDefinitionId: number, orderId: number, takeUntil$$: Subject<void>, referenceId: string): Observable<void> {
    return this._documentsService.getDocumentsMetaData$(orderId, milestoneDefinitionId, referenceId)
      .pipe(switchMap(documentMetadata => this._documentDownloadService.downloadDocumentV2$(
        orderId,
        documentMetadata[0].documentId,
        documentMetadata[0].documentName,
        takeUntil$$,
        documentMetadata[0].s3ObjectKey)),
        takeUntil(takeUntil$$));
  }

  public downloadDocumentByDocumentId$(documentId: number, orderId: number, takeUntil$$: Subject<void>): Observable<void> {
    return this._documentDownloadService.downloadDocumentV2$(orderId, documentId, null, takeUntil$$);
  }

  public updateLoanData$(milestoneDefinitionId: number, loanData: LoanData, canRefreshCache = true): Observable<LoanData> {
    return this.orderId$.pipe(
      switchMap(orderId => {
        return this._orderService.updateLoanData$(orderId, milestoneDefinitionId, loanData);
      }),
      switchMap(_ => {
        if (canRefreshCache) this.refresh('loanData');
        return this.loanData$;
      }))
  }

  public updateProperty$(milestoneDefinitionId: number, property: Property, canRefreshCache = true): Observable<Property> {
    return this.orderId$.pipe(
      switchMap(orderId => this._orderService.updateProperty$(orderId, milestoneDefinitionId, property)),
      switchMap(_ => {
        if (canRefreshCache) this.refresh('property');
        return this.property$;
      }))
  }

  public updateContacts$(milestoneDefinitionId: number, contacts: Array<Contact>, canRefreshCache = true): Observable<Array<Contact>> {
    return this.orderId$.pipe(
      switchMap(orderId => this._orderService.updateContacts$(orderId, milestoneDefinitionId, contacts)),
      switchMap(_ => {
        if (canRefreshCache) this.refresh('contacts');
        return this.contacts$;
      }));
  }

  public toggleMilestone(milestoneDefinitionId: number, isCollapsed: boolean): void {
    const milestones = this._milestones$$.value;
    const index = milestones.findIndex(milestone => milestone.milestoneDefinitionId === (milestoneDefinitionId));
    if (index > -1) {
      milestones[index].isCollapsed = isCollapsed;
    }
  }

  public scrollToMilestone(milestoneDefinitionId: number): void {
    const anchorer = document.querySelector(`#milestone-definition-${milestoneDefinitionId}`);

    if (anchorer) {
      this.toggleMilestone(milestoneDefinitionId, false);
      this._smoothScroll.animateScroll(anchorer);
    }
  }

  public addMilestone(milestone: Milestone): void {
    const milestones = this._milestones$$.value;
    let index: number = 0;

    if (milestone.referenceId)
      index = milestones.findIndex(m => m.milestoneDefinitionId === milestone.milestoneDefinitionId && m.referenceId === milestone.referenceId);
    else
      index = milestones.findIndex(m => m.milestoneDefinitionId === milestone.milestoneDefinitionId);

    milestone.title = this._milestoneHelperService.getMilestoneTitle(milestone);
    milestone.description = this._milestoneHelperService.getMilestoneDescription(milestone);

    if (index > -1) {
      milestones[index] = milestone;
    }
    else {
      milestones.unshift(milestone);
    }

    this._milestones$$.next(milestones);
  }

  public orderIdByParamMap$(paramMap$: Observable<ParamMap>): Observable<number> {
    return paramMap$.pipe(switchMap(paramMap => of(this.getOrderId(paramMap))), first());
  }

  public updatePrimaryAgent(orderId: number, primaryAgent: number, loanNumber: string) {
    this._orderId = orderId;
    this.order$.pipe(first()).subscribe(o => {
      const order = o;
      order.agentId = primaryAgent;
      this.updateOrder(order);
    });
    return this._orderService.updatePrimaryAgent$(orderId, primaryAgent, { loanNumber });
  }

  public verifyAddress(addressLines: Array<string>): Observable<LesAddress> {
    return this._orderService.verifyAddress$(addressLines);
  }

  public dispose(): void {
    this._cacheControl$$.next();
    this._cacheControl$$.complete();
    this._cacheControl$$?.unsubscribe();
  }

  private configureCache<T>(
    data: string,
    func: (orderId: number) => Observable<T>,
    reloader$$: Subject<void>,
    listener?: Subject<T>,
    canPoll: boolean = false): Observable<T> {
    if (!this[data]) {
      this[data] = canPoll ? this.getDataByTimer(func, reloader$$, listener) : this.getData(func, reloader$$, listener);

      if (listener) {
        this[data].subscribe();
        return listener;
      }
    }

    return listener || this[data];
  }

  private getData<T>(func: (orderId: number) => Observable<T>, reloader$$: Subject<void>, listener?: Subject<T>): Observable<T> {
    return this.orderId$.pipe(
      switchMap(orderId => func(orderId).pipe(map(x => {
        if (listener) {
          listener.next(x);
        }
        return x;
      }), takeUntil(this._cacheControl$$))),
      takeUntil(reloader$$),
      takeUntil(this._cacheControl$$),
      shareReplay(1));
  }

  private getDataByTimer<T>(func: (orderId: number) => Observable<T>, reloader$$: Subject<void>, listener?: Subject<T>): Observable<T> {
    const timer$ = timer(0, 2000);

    return timer$.pipe(
      take(2),
      switchMap(_ => this.getData(func, reloader$$, listener)),
      takeUntil(this._cacheControl$$),
      shareReplay(1)
    );
  }

  private getAgency(): Observable<Agency> {
    return this.order$.pipe(
      switchMap(order => this._userService.getAgency$(order?.agencyId)),
      map(agency => { agency.zip = `${agency.zip}`; return agency }));
  }

  private getAgents(): Observable<Agent[]> {
    return this.order$.pipe(
      switchMap(order => {
        if (!order?.agencyId) return of(null);
        return this._userService.getAgents$(order.agencyId);
      }));
  }

  private getOrderId(paramMap: ParamMap): number {
    return +paramMap.get(this._orderIdParamKey);
  }

  private dataUpdates(message: SignalRMessage): void {
    if (message.resource != SignalRResource.Milestone) {
      return;
    }

    const parsedData = _.mapKeys(JSON.parse(message.data), (v, k) => _.camelCase(k));

    parsedData.metadataJson = parsedData.milestoneMetadataJson;
    const milestone = parsedData as Milestone;
    if (milestone.milestoneTypeId == MilestoneType.DocumentUpload) {
      if (milestone.milestoneStatusId == MilestoneStatus.Pending) {
        milestone.recentlyUpdated = true;
        timer(3200).pipe(take(1)).subscribe(_ => {
          milestone.recentlyUpdated = false;
        });
      }
      this.addMilestone(milestone);
    }
  }
}
