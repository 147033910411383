import { Component } from '@angular/core';
import { OrderStatusService } from '@core/services/order-status/order-status.service';
import { CommonHighlightComponent } from '../common/common-highlight.component';

@Component({
  selector: 'app-contact-highlight',
  templateUrl: './contact-highlight.component.html'
})
export class ContactHighlightComponent extends CommonHighlightComponent {

  constructor(_orderStatusService: OrderStatusService) { super(_orderStatusService); }
}