import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-truncation-toggle',
  templateUrl: './message-truncation-toggle.component.html'
})
export class MessageTruncationToggleComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() canShowFullMessage = false;
  @Input() characterLimitation = 150;
}
