<div class="to-top__container"
     [class]="additionalClasses"
     *ngIf="showButton">
  <div class="to-top__button"
       (click)="scrollToTop()">
    <div class="to-top__button__image">
      <sprk-icon iconType="arrow-up"
                 additionalClasses="sprk-c-Icon--sm sprk-c-Icon--stroke-current-color">
      </sprk-icon>
    </div>
    <div>Top</div>
  </div>
</div>