<ng-container *ngIf="loaded">
  <mat-icon
    class="rkt-Icon cursor-pointer"
    aria-hidden="false"
    aria-label="Subscribers"
    [svgIcon]="subscriptionStatusIconV2"
    color="accent"
    (click)="changeSubscription($event)"
  ></mat-icon>
  <ng-container *ngIf="showCount">
    <span (click)="togglePopup($event)" class="popover__wrapper popover__title"
      >+{{ subscriptions.length }}
      <div *ngIf="showPopOver" (click)="$event.stopPropagation()" class="popover__content">
        <h4 al class="rkt-Heading-20" variant="displayFive">
          Subscribers on this Order
          <button type="button" class="close" aria-label="Close" (click)="togglePopup($event)">
            <span aria-hidden="true">&times;</span>
          </button>
        </h4>
        <hr />
        <ul class="popover__list">
          <li *ngFor="let sub of subscriptions">{{ sub.firstName }} {{ sub.lastName }}</li>
        </ul>
        <button
          appDebouncClick
          mat-flat-button
          class="rkt-Button rkt-Button--large mt-3"
          [disabled]="
            (isSubscribedToOrder && subscriptions.length == 1) || isSsoUser || isPrimaryAgentOnOrder
          "
          color="primary"
          (click)="changeSubscription($event)"
        >
          {{ isSubscribedToOrder ? 'Unsubscribe' : 'Subscribe' }}
        </button>
      </div>
    </span>
  </ng-container>
</ng-container>
