<sprk-alert alertType="fail"
            [dismissible]="false"
            idString="alert-info-1"
            additionalClasses="mb-3 slide"
            *ngIf="canShowUploadResults && !this.showInValidBytesBanner && this.documentUploaderService.hasRejectedFiles$ | async"
            analyticsString="test">
  <span *ngIf="this.documentUploaderService.options?.maxFileSize">Files must be
    <strong>{{this.documentUploaderService.options?.maxFileSize | filesize}}</strong> or less.</span>
  <span *ngIf="this.documentUploaderService.options?.allowedContentTypes?.length > 0"> Accepted file types include
    <strong><span *ngFor="let fileType of this.documentUploaderService.options?.allowedContentTypes"> {{fileType}}. </span></strong>
  </span>
  <span>Filename must not exceed <strong>{{this.documentUploaderService.documentConfig?.maximumFileNameLength}} characters </strong>in length.</span>
</sprk-alert>

<sprk-alert alertType="fail"
            [dismissible]="false"
            idString="alert-info-1"
            additionalClasses="mb-3 slide"
            *ngIf="canShowUploadResults && this.showInValidBytesBanner == true && this.documentUploaderService.hasRejectedFiles$ | async"
            analyticsString="test">
  <span>There is a problem with this file. Please ensure the file is not corrupt and try again.</span>
</sprk-alert>

<figure class="drop-zone d-flex flex-column justify-content-center align-items-center"
        ngFileDrop
        [ngClass]="{ 'drop-zone--drag-over': documentUploaderService.dragOver }"
        [options]="this.documentUploaderService.options"
        [uploadInput]="uploadInput"
        (uploadOutput)="documentUploaderService.onUploadOutput($event)">
  <svg class="sprk-c-Icon sprk-c-Icon--xl fill-sprk-black"
       style="width: 110px; height: auto;"
       viewBox="0 0 300 200">
    <use xlink:href="#cloud-upload" />
  </svg>
  <h3 class="drop-zone__content">
    Drag and drop your files here or
    <label class="drop-zone__label">
      <input type="file"
             ngFileSelect
             [options]="this.documentUploaderService.options"
             (uploadOutput)="documentUploaderService.onUploadOutput($event)"
             [uploadInput]="uploadInput"
             [attr.multiple]="canUploadMultipleFiles ? true: null" />
      browse
    </label>
    to choose files.
  </h3>
</figure>