import { Injectable } from '@angular/core';
import { INullablePerson } from '@models/inullable-person';
import { ISorter } from '../isorter';

@Injectable({
  providedIn: 'root'
})
export class NullablePersonSortService implements ISorter<INullablePerson> {
  private _nullSub: string = '';

  sortAscending(input: INullablePerson[]): void {
    if (input) {
      input.sort((a, b) => {
        return ((a.firstName ?? this._nullSub) + (a.lastName ?? this._nullSub))
          .localeCompare((b.firstName ?? this._nullSub) + (b.lastName ?? this._nullSub));
      });
    }
  }

  sortDescending(input: INullablePerson[]): void {
    if (input) {
      input.sort((a, b) => {
        return ((b.firstName ?? this._nullSub) + (b.lastName ?? this._nullSub))
          .localeCompare((a.firstName ?? this._nullSub) + (a.lastName ?? this._nullSub));
      });
    }
  }
}