import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@models/order';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

  // Compare
  static comparator(a: any, b: any): number {
    if (a === null || typeof a === 'undefined') return 0;
    if (b === null || typeof b === 'undefined') return 0;

    if (a < b) return -1;
    if (a > b) return 1;

    return 0;
  }

  //Transform
  transform(value: Array<any>, property: string = '', order: string = ''): any {
    let sortedArray = value.sort(function (a: any, b: any) {
      let aValue = a[property];
      let bValue = b[property];
      let comparison = OrderByPipe.comparator(aValue, bValue);
      return comparison;
    });
    if (order === 'desc'){
      return sortedArray.reverse();
    }
    return sortedArray;
  }
}